// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InviteEmployee_title__15pbi {
    color: #1E293B;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}
.InviteEmployee_message__kPYol {
    color: #94A3B8;
    font-weight: 550;
    font-size: 14px;
}
.InviteEmployee_field__2nMB0 {
    margin-bottom: 20px;
}
.InviteEmployee_btns__1jqmk {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 5px;
}
.InviteEmployee_btns__1jqmk.InviteEmployee_confirm__4kX3V {
    grid-template-columns: 100px 100px;
}

.InviteEmployee_mailInputContainer__VOMLa {
    margin-top: -2px;
}
@media (max-width: 1000px) {
    .InviteEmployee_title__15pbi {
        font-size: 18px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/InviteEmployee/InviteEmployee.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,eAAe;AACnB;AACA;IACI,kCAAkC;AACtC;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".title {\n    color: #1E293B;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 20px;\n}\n.message {\n    color: #94A3B8;\n    font-weight: 550;\n    font-size: 14px;\n}\n.field {\n    margin-bottom: 20px;\n}\n.btns {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 10px;\n    margin-top: 5px;\n}\n.btns.confirm {\n    grid-template-columns: 100px 100px;\n}\n\n.mailInputContainer {\n    margin-top: -2px;\n}\n@media (max-width: 1000px) {\n    .title {\n        font-size: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `InviteEmployee_title__15pbi`,
	"message": `InviteEmployee_message__kPYol`,
	"field": `InviteEmployee_field__2nMB0`,
	"btns": `InviteEmployee_btns__1jqmk`,
	"confirm": `InviteEmployee_confirm__4kX3V`,
	"mailInputContainer": `InviteEmployee_mailInputContainer__VOMLa`
};
export default ___CSS_LOADER_EXPORT___;
