import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useScrollFromWindow } from '../../../hooks/useScrollFromWindow';
import styles from './modal.module.css';
import NewOrderModal from '../Modals/NewOrderModal/NewOrderModal';
import { MODAL_TYPES } from '../../../constants/modals';
import { VisitorCall } from '../Modals/VisitorCall/VisitorCall';
import {
  setPushNotification,
  setPushNotificationType,
} from '../../../../redux/actions/notifications';

const PushNotificationModal = () => {
  const setScrollEl = useScrollFromWindow();
  const dispatch = useDispatch();
  const { pushNotificationType } = useSelector((state) => state.notifications);
  const closeModal = () => {
    dispatch(setPushNotification(''));
    dispatch(setPushNotificationType(''));
  };
  return (
    <Modal
      portalClassName="push_notifications_modal"
      contentRef={setScrollEl}
      overlayClassName={styles.overlay}
      className={styles.content}
      isOpen={!!pushNotificationType}
      onRequestClose={closeModal}
    >
      <div style={{ padding: 20 }}>
        {pushNotificationType === MODAL_TYPES.newOrderModal && (
          <NewOrderModal closeModal={closeModal} />
        )}
        {pushNotificationType === MODAL_TYPES.VisitorCall && (
          <VisitorCall closeModal={closeModal} />
        )}
      </div>
    </Modal>
  );
};

export default memo(PushNotificationModal);
