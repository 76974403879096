// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsConfirmEmail_container__Njaew {
  display: flex;  
  flex-direction: column;
  gap: 15px;
}

.SettingsConfirmEmail_resendBtn__a\\+bSC {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/SettingsConfirmEmail/SettingsConfirmEmail.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,UAAU;EACV,SAAS;AACX","sourcesContent":[".container {\n  display: flex;  \n  flex-direction: column;\n  gap: 15px;\n}\n\n.resendBtn {\n  border: none;\n  background: transparent;\n  padding: 0;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SettingsConfirmEmail_container__Njaew`,
	"resendBtn": `SettingsConfirmEmail_resendBtn__a+bSC`
};
export default ___CSS_LOADER_EXPORT___;
