import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal, setModalType } from '../../../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../../constants/modals';
import Button from '../../../atoms/Button';

const OutputMoneyError = () => {
  const dispatch = useDispatch();

  return (
    <Button
      title="Попробовать снова"
      onClick={() => {
        dispatch(setModalType(MODAL_TYPES.outputMoney));
        dispatch(setModal(true));
      }}
    />
  );
};

export default React.memo(OutputMoneyError);
