import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './tableSectionTableIcon.module.css';

const TableSectionTableIcon = ({ provided, snapshot, current, isBordered, onCLick }) => {
  const handleClick = useCallback(() => {
    if (onCLick) {
      onCLick(current);
    }
  }, [onCLick, current]);

  return (
    <div
      onClick={handleClick}
      className={styles.numberCol}
      style={{
        cursor: snapshot?.isDragging ? 'grab' : 'pointer',
      }}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      <div
        className={`${styles.main} ${isBordered || snapshot?.isDragging ? styles.isBordered : ''} ${
          styles.canHover
        }`}
      >
        {current.num}
      </div>
    </div>
  );
};

TableSectionTableIcon.propTypes = {
  current: PropTypes.object.isRequired,
  snapshot: PropTypes.object,
  onCLick: PropTypes.func,
  isBordered: PropTypes.bool,
};
export default memo(TableSectionTableIcon);
