import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAuthHeaders } from '../../api/auth';
import {
  buttanDataCreator,
  constructorDataParser,
  emulatorDataCreator,
} from '../../utils/constructor';
import { addAlert } from './alerts';
import { setOrganisationInfo } from './organisation';
import { setIsLoad } from './preloader';
import { getOrganisation } from './setting';
import { menuAPI } from '../../api/setting';

export const constructoSlice = createSlice({
  name: 'constructor',
  initialState: {
    constructoLoading: false,
    emulatorLoading: false,
    applicationSettings: {},
    mainData: {
      fon: '',
      logo: '',
      name: '',
      description: '',
      theme: '',
      buttons: [],
      socilaItems: [],
      dragVersion: '',
      dragVersionButtons: '',
      use_app_link: false,
      organisation_id: '',
      rkeeper_station_active: false,
    },
    constructorDefaultImages: [],
    customBlockEditData: null,
    buttonData: {},
    emulatorItems: [],
    currentMode: 'in',
    selectedCustomOption: '',
    breakPeriod: null,
  },
  reducers: {
    setSelectedCustomOption: (state, action) => {
      return {
        ...state,
        selectedCustomOption: action.payload,
      };
    },
    setCurrentMode: (state, action) => {
      state.currentMode = action.payload;
    },
    setBreakPeriod: (state, action) => {
      state.breakPeriod = action.payload;
    },
    setCustomBlockEditData: (state, action) => {
      state.customBlockEditData = action.payload;
    },
    setConstructoLoading: (state, action) => {
      return {
        ...state,
        constructoLoading: action.payload,
      };
    },
    setEmulatorLoading: (state, action) => {
      return {
        ...state,
        emulatorLoading: action.payload,
      };
    },
    setMaindata: (state, action) => {
      return {
        ...state,
        mainData: action.payload,
      };
    },
    setApplicationSettings: (state, action) => {
      return {
        ...state,
        applicationSettings: action.payload,
      };
    },
    setButtonData: (state, action) => {
      return {
        ...state,
        buttonData: action.payload,
      };
    },
    setLoyalitydata: (state, action) => {
      return {
        ...state,
        buttonData: {
          ...state.buttonData,
          loyalityAll: action.payload,
        },
      };
    },
    setEmulatorItems: (state, action) => {
      const data = action.payload;

      let filteredData = [...data];

      const menuIdex =
        Array.isArray(data) &&
        action.payload.findIndex((element) => element.attribute === 'is_enabled_btn_menu');
      if (menuIdex !== -1) {
        const filtered = data.filter((element) => element.attribute !== 'is_enabled_btn_menu');
        filteredData = [action.payload[menuIdex], ...filtered];
      }
      return {
        ...state,
        emulatorItems: filteredData,
      };
    },
    setConstructorDefaultImages: (state, action) => {
      return {
        ...state,
        constructorDefaultImages: action.payload,
      };
    },
  },
});

export const getLoyaltyNames = () => (dispatch) => {
  Promise.resolve(
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/form/getNames`,
      getAuthHeaders()
    )
  ).then((resp) => {
    dispatch(setLoyalitydata(resp?.data?.data?.result));
  });
};

export const getDefaultConstructorImages = () => (dispatch) => {
  Promise.resolve(
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/getDefaultImages`,
      getAuthHeaders()
    )
  ).then((resp) => {
    dispatch(setConstructorDefaultImages(resp.data?.data || []));
  });
};

export const getLoyaltyCardNames = () => (dispatch) => {
  Promise.resolve(
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/getNames`,
      getAuthHeaders()
    )
  ).then((resp) => {
    dispatch(setLoyalitydata(resp?.data?.data?.result.filter((el) => el.has_loyal_form)));
  });
};

export const getPaySettings = (withLoader) => (dispatch, getState) => {
  dispatch(setEmulatorLoading(true));
  if (withLoader) {
    dispatch(setIsLoad(true));
  }
  const currentMode = getState((state) => state)?.constructorCreator?.currentMode;
  const promises = [
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/getPaySettings?type_setting=${currentMode}`,
      getAuthHeaders()
    ),
  ];
  Promise.all(promises)
    .then((resp) => {
      const btnData = getState((state) => state)?.constructorCreator?.buttonData;
      const parsedData = constructorDataParser(resp[0].data.data);
      const buttonData = buttanDataCreator(resp[0].data.data);
      const emulatorData = emulatorDataCreator(resp[0].data.data);
      dispatch(setEmulatorItems(emulatorData));
      dispatch(setButtonData({ ...buttonData, loyalityAll: btnData?.loyalityAll }));
      dispatch(setMaindata(parsedData));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setIsLoad(false));
      dispatch(setEmulatorLoading(false));
    });
};
export const sendImage = (data) => (dispatch, getState) => {
  dispatch(setIsLoad(true));
  const dataInfo = getState().organisation.data.info;
  axios
    .post(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/organisation`, data, {
      ...getAuthHeaders(),
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryyqGPFxqwYnG4Fyar',
    })
    .then((resp) => {
      dispatch(setOrganisationInfo({ ...dataInfo, ...resp.data.data }));
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(setIsLoad(false));
    })
    .catch((err) => {
      if (err?.response?.status !== 500) {
        dispatch(addAlert('Возникла ошибка', 'error'));
      }
      dispatch(setIsLoad(false));
    });
};

export const changeBillSettings = (currentMode, settings) => (dispatch) => {
  dispatch(setIsLoad(true));
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/updatePaySettings?type_setting=${currentMode}`,
      settings,
      getAuthHeaders()
    )
    .then(() => {
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(getPaySettings());
    })
    .catch((err) => {
      console.log(err);
    });
};

export const changeButtonsActivity = (field, value, currentMode) => (dispatch) => {
  dispatch(setEmulatorLoading(true));

  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/switchButtonActivity`,
      { type_setting: currentMode, field, value },
      getAuthHeaders()
    )
    .then(() => {
      dispatch(getPaySettings(currentMode));
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};
export const changeWiFi = (login, pass) => (dispatch) => {
  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/wifi`,
      { type_setting: 'in', wifi_login: login, wifi_password: pass },
      getAuthHeaders()
    )
    .then(() => {
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(getPaySettings());
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};
export const chnageAllowedFeedBacks = (properties, currentMode) => (dispatch) => {
  const promises = properties.map((current) => {
    return axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/${current.apiPart}`,
      { type_setting: currentMode, [current.property]: current.value },
      getAuthHeaders()
    );
  });
  Promise.all(promises)
    .then(() => {
      dispatch(getPaySettings());
      dispatch(addAlert('Изменения сохранены', 'success'));
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};
export const changeSelectedLoyality = (id) => (dispatch) => {
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/selected/${id}`,
      {},
      getAuthHeaders()
    )
    .then(() => {
      dispatch(getPaySettings());
      dispatch(getLoyaltyCardNames());
      dispatch(addAlert('Изменения сохранены', 'success'));
    })
    .catch((err) => {
      console.log(err);
      dispatch(addAlert('Возникла ошибка', 'error'));
    });
};

export const editVisitorCanApplyDiscount = (data) => async (dispatch) => {
  try {
    const response = await menuAPI.editTakeAwayMenu(data);
    if (response?.status === 200) {
      dispatch(getOrganisation());
    }
  } catch (err) {
    console.log(err);
  }
};

export const changeAdminWaitingTime = (time) => (dispatch) => {
  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/adminWaitingTime`,
      { type_setting: 'in', admin_waiting_time: time },
      getAuthHeaders()
    )
    .then(() => {
      dispatch(getPaySettings());
      dispatch(addAlert('Изменения сохранены', 'success'));
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};
export const changeWeiterWaitingTime = (time) => (dispatch) => {
  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/waiterWaitingTime`,
      { type_setting: 'in', waiter_waiting_time: time },
      getAuthHeaders()
    )
    .then(() => {
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(getPaySettings());
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};
export const chnageTipGettingMethod = (enabled) => (dispatch, getState) => {
  const organisationId = getState().profile.data.organisation.id;

  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/organisation/${organisationId}/settings/changeTransferSettings?setting=${enabled}`,
      {},
      getAuthHeaders()
    )
    .then(() => {
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(getPaySettings());
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};
export const changeMenu = (value) => (dispatch) => {
  axios
    .post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/update`,
      { use_app_link_menu: value },
      getAuthHeaders()
    )
    .then(() => {
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(getPaySettings());
      dispatch(getOrganisation());
    })
    .catch((err) => {
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
    });
};

export const updateWorkTime = (data) => async (dispatch, getState) => {
  try {
    axios
      .post(
        `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/update`,
        data,
        getAuthHeaders()
      )
      .then((res) => {
        const mainData = getState().constructorCreator.mainData;
        dispatch(
          setMaindata({
            ...mainData,
            org_work_period: res.data.data.org_work_period,
          })
        );
        dispatch(addAlert('Изменения сохранены', 'success'));
      });
  } catch (err) {
    dispatch(getPaySettings());
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  }
};

export const getBreakPeriod = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/breakPeriod`,
      getAuthHeaders()
    );
    dispatch(setBreakPeriod(res?.data?.data?.break_period));
    return res?.data?.data?.break_period;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  }
};

export const updateBreakPeriod = (data) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/organisation/breakPeriod/update`,
      data,
      getAuthHeaders()
    );
    dispatch(setBreakPeriod(res?.data?.data?.break_period));
    dispatch(addAlert('Изменения сохранены', 'success'));
    return res?.data?.data?.break_period;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  }
};

// custom
export const addButton = (data, callback) => async (dispatch) => {
  try {
    await axios
      .post(
        `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/addCustomCallEmployeeButton`,
        data,
        {
          ...getAuthHeaders(),
          'Content-Type': 'multipart/form-data',
        }
      )
      .then(() => {
        dispatch(getPaySettings());
        dispatch(addAlert('Изменения сохранены', 'success'));
        callback && callback();
      });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
  }
};

export const updateButton = (id, data) => async (dispatch) => {
  try {
    await axios
      .post(
        `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/updateCustomCallEmployeeButton/${id}`,
        data,
        {
          ...getAuthHeaders(),
          'Content-Type': 'multipart/form-data',
        }
      )
      .then(() => {
        dispatch(getPaySettings());
        dispatch(addAlert('Изменения сохранены', 'success'));
      });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
  }
};

export const removeButton = (id, callback) => async (dispatch) => {
  try {
    await axios
      .delete(
        `${process.env.REACT_APP_TENQ_API}/api/v0/owner/paysettings/deleteCustomCallEmployeeButton/${id}`,
        {
          ...getAuthHeaders(),
          'Content-Type': 'multipart/form-data',
        }
      )
      .then(() => {
        dispatch(getPaySettings());
        callback && callback();
        dispatch(addAlert('Изменения сохранены', 'success'));
      });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
  }
};

export const {
  setConstructoLoading,
  setEmulatorLoading,
  setMaindata,
  setConstructorDefaultImages,
  setApplicationSettings,
  setButtonData,
  setLoyalitydata,
  setEmulatorItems,
  setCurrentMode,
  setCustomBlockEditData,
  setSelectedCustomOption,
  setBreakPeriod,
} = constructoSlice.actions;

export default constructoSlice.reducer;
