import { useEffect } from 'react';

export const actionKeyDown = (action, keyCode = 'Enter') => {
  const handleAction = (e) => {
    if (e.code === keyCode) {
      action && action();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleAction);
    return () => document.removeEventListener('keydown', handleAction);
  }, []);
};
