import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Confirm from '../../../atoms/Confirm';
import { deleteClient } from '../../../../../redux/actions/groupsSettings';

const ConfirmRemoveClient = ({ closeModal }) => {
  const dispatch = useDispatch();
  const usedGroup = useSelector((state) => state?.clubcard?.usedGroup);
  const clientId = useSelector((state) => state?.clubcard?.selectedClientId);
  const onConfirm = () => {
    dispatch(
      deleteClient(usedGroup.loyal_card_id, {
        group_id: usedGroup.id,
        client_id: clientId,
      })
    );
    closeModal(null, true);
  };

  return (
    <Confirm
      title="Подтверждение"
      paragraphs={['Вы уверены, что хотите удалить клиента?']}
      onConfirm={onConfirm}
      onConfirmTitle="Удалить"
      onCancel={(e) => closeModal(e, true)}
    />
  );
};

ConfirmRemoveClient.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmRemoveClient);
