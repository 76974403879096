import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getDeletingPositionSelector } from '../../../../../redux/selectors/management';
import { deletePosition } from '../../../../../redux/actions/management';
import Confirm from '../../../atoms/Confirm';

const ConfirmDeletingPosition = ({ closeModal }) => {
  const dispatch = useDispatch();
  const deletingPositionId = useSelector(getDeletingPositionSelector);

  const onConfirm = () => {
    dispatch(deletePosition(deletingPositionId));
  };

  return (
    <Confirm
      title="Подтверждение"
      paragraphs={['Вы уверены, что хотите удалить должность?']}
      onConfirm={onConfirm}
      onConfirmTitle="Удалить"
      onCancel={closeModal}
    />
  );
};

ConfirmDeletingPosition.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmDeletingPosition);
