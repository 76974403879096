import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    open: false,
    orderDetailsOpen: false,
    type: '',
    constructoRedirectionModals: {},
    showFilterValue: true,
  },
  reducers: {
    setModal: (state, action) => {
      state.open = action.payload;
    },
    setOrderDetailsModal: (state, action) => {
      state.orderDetailsOpen = action.payload;
    },
    setModalType: (state, action) => {
      state.type = action.payload;
    },
    setConstructoRedirectionModals: (state, action) => {
      return {
        ...state,
        constructoRedirectionModals: action.payload,
      };
    },
    setShowFilterValue: (state, action) => {
      return {
        ...state,
        showFilterValue: action.payload,
      };
    },
  },
});

export const {
  setModal,
  setModalType,
  setConstructoRedirectionModals,
  setShowFilterValue,
  setOrderDetailsModal,
} = modalSlice.actions;

export default modalSlice.reducer;
