import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from '../../../atoms/Confirm/Confirm.module.css';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import { removeButton } from '../../../../../redux/actions/constructor';

const ConfirmDeletingConstructorOption = ({ closeModal }) => {
  const { selectedCustomOption, mainData } = useSelector((state) => state.constructorCreator);
  const dispatch = useDispatch();
  const onConfirm = () => {
    const buttonId = mainData?.buttons?.find(
      (el) => el.attribute === selectedCustomOption.attribute
    )?.id;

    dispatch(removeButton(buttonId, closeModal));
  };
  return (
    <>
      <div className={`${s.message} ${s.field}`}>Вы уверены, что хотите удалить блок?</div>

      <div className={`${s.btns} ${s.confirm} `}>
        <Button className={s.button} isDanger title="Удалить" onClick={onConfirm} />
        <ButtonCancel onClick={closeModal} className={s.button} />
      </div>
    </>
  );
};

ConfirmDeletingConstructorOption.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmDeletingConstructorOption);
