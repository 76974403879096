// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddSumClientGroup_btns__dVoy6 {
  display: flex;
  justify-content: space-between;
}

.AddSumClientGroup_btn__y1I7H {
  width: 48.6%;
}

.AddSumClientGroup_description__MIYY5 {
  font-size: 14px;
  color: #94A3B8;
}

.AddSumClientGroup_percentDiscount__I2gs- {
  width: 30%;
  color: #94A3B8;
  border: 2px solid #f2f5fa;
  padding: 10px 14px;
  font-size: 14px; 
  border-radius: 10px;
  margin-right: 10px;
}

.AddSumClientGroup_percentInput__kUhR9 {
  width: 100%;
}

.AddSumClientGroup_discount__VBrWc {
  margin: 15px 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.AddSumClientGroup_input__ygFhK {
  margin: 15px 0;
  line-height: 1;
  padding: 13px 14px 10px 14px;
}

.AddSumClientGroup_input__ygFhK::placeholder {
  display: flex;
  transform:translate3d(0,1px,0)

}

.AddSumClientGroup_noneTitle__9MGml {
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/AddSumClientGroup/AddSumClientGroup.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb;;AAEF;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".btns {\n  display: flex;\n  justify-content: space-between;\n}\n\n.btn {\n  width: 48.6%;\n}\n\n.description {\n  font-size: 14px;\n  color: #94A3B8;\n}\n\n.percentDiscount {\n  width: 30%;\n  color: #94A3B8;\n  border: 2px solid #f2f5fa;\n  padding: 10px 14px;\n  font-size: 14px; \n  border-radius: 10px;\n  margin-right: 10px;\n}\n\n.percentInput {\n  width: 100%;\n}\n\n.discount {\n  margin: 15px 0;\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n\n.input {\n  margin: 15px 0;\n  line-height: 1;\n  padding: 13px 14px 10px 14px;\n}\n\n.input::placeholder {\n  display: flex;\n  transform:translate3d(0,1px,0)\n\n}\n\n.noneTitle {\n  margin: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btns": `AddSumClientGroup_btns__dVoy6`,
	"btn": `AddSumClientGroup_btn__y1I7H`,
	"description": `AddSumClientGroup_description__MIYY5`,
	"percentDiscount": `AddSumClientGroup_percentDiscount__I2gs-`,
	"percentInput": `AddSumClientGroup_percentInput__kUhR9`,
	"discount": `AddSumClientGroup_discount__VBrWc`,
	"input": `AddSumClientGroup_input__ygFhK`,
	"noneTitle": `AddSumClientGroup_noneTitle__9MGml`
};
export default ___CSS_LOADER_EXPORT___;
