import { createSelector } from 'reselect';

const organisationSelector = (state) => state.organisation;

export const getOrganisationDataSelector = createSelector(
  organisationSelector,
  (state) => state.data
);
export const getOrganisationDataUsersSelector = createSelector(
  getOrganisationDataSelector,
  (state) => state.users
);
export const getOrganisationInfoSelector = createSelector(
  getOrganisationDataSelector,
  (state) => state.info
);
export const getOrganisationIdSelector = createSelector(
  getOrganisationDataSelector,
  (state) => state.info.id
);
