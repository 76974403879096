import { createSlice } from '@reduxjs/toolkit';
import { managementAPI } from '../../api/management';
import { setIsLoad } from './preloader';
import { getUsers } from '../../api/organisation';
import { setModal, setModalType } from './modal';
import { MODAL_TYPES } from '../../App/constants/modals';
import { addAlert } from './alerts';
import { setOrganisationUsers } from './organisation';
import { getProfile } from '../../api/profile';

export const managementSlice = createSlice({
  name: 'management',

  initialState: {
    currentEmployee: null,
    currentEmployeeBalance: 0,
    showControlModal: false,
    userInfo: {},
    inviteData: {
      selectedPositionId: null,
      name: '',
      surname: '',
      phone: {
        code: '+7',
        number: '',
      },
    },
    positions: [],
    editablePosition: {},
    deletingPosition: {},
  },
  reducers: {
    setCurrentEmployee: (state, data) => {
      state.currentEmployee = data.payload;
    },
    setCurrentEmployeeBalance: (state, data) => {
      state.currentEmployeeBalance = data.payload;
    },
    setShowControlModal: (state, data) => {
      state.showControlModal = data.payload;
    },
    setUserInfo: (state, data) => {
      state.userInfo = data.payload;
    },
    setInviteData: (state, data) => {
      state.inviteData = data.payload;
    },
    setPositions: (state, data) => {
      state.positions = data.payload;
    },
    setEditablePosition: (state, data) => {
      state.editablePosition = data.payload;
    },
    setDeletingPosition: (state, data) => {
      state.deletingPosition = data.payload;
    },
  },
});

export const {
  setCurrentEmployeeBalance,
  setCurrentEmployee,
  setUserInfo,
  setInviteData,
  setShowControlModal,
  setPositions,
  setEditablePosition,
  setDeletingPosition,
} = managementSlice.actions;

export default managementSlice.reducer;

export const getUserInfo = (employeeId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await managementAPI.getUserInfo(employeeId);
    dispatch(setUserInfo(res.data.data));
    dispatch(setCurrentEmployee(employeeId));
    dispatch(setCurrentEmployeeBalance(res?.data?.data?.balance || 0));
    dispatch(setModal(true));
    dispatch(setModalType(MODAL_TYPES.controlEmployee));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const updateUserInfo = (employeeId, data) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const res = await managementAPI.updateUserInfo(employeeId, data);
    if (employeeId === getState()?.profile?.data?.id) {
      await getProfile();
    } else {
      getUsers();
    }
    const orgUsers = getState()?.organisation.data.users;
    dispatch(
      setOrganisationUsers(
        orgUsers.map((user) => (user.id === employeeId ? res.data?.data?.result : user))
      )
    );
    dispatch(addAlert('Изменения сохранены', 'success'));
    return res;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const fireUser = (employeeId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await managementAPI.fireUser(employeeId);
    getUsers();
    return res;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getPositions = (available_custom_buttons) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await managementAPI.getPositions(Boolean(available_custom_buttons));
    dispatch(setPositions(res.data.data));
  } catch (err) {
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const createPosition = (data) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await managementAPI.createPositions(data).then((res) => {
      const { positions } = getState().management;

      dispatch(setPositions([...positions, res.data.data]));
      dispatch(setModal(false));
      dispatch(setModalType(''));
      dispatch(addAlert('Должность добавлена', 'success'));
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const editPosition = (id, data) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await managementAPI.editPositions(id, data).then(({ data }) => {
      const positions = getState().management.positions;
      dispatch(setEditablePosition({}));
      dispatch(
        setPositions(
          positions.map((el) => (el.id === data?.data?.result?.id ? data?.data?.result : el))
        )
      );
      dispatch(setModal(false));
      dispatch(setModalType(''));
      dispatch(addAlert('Должность изменена', 'success'));
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const deletePosition = (id) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const res = await managementAPI.deletePositions(id);
    const { positions } = getState().management;
    dispatch(setPositions(positions.filter((p) => p.id !== id)));
    dispatch(setModalType(''));
    dispatch(setModal(false));
    if (res?.data?.errors) {
      dispatch(addAlert('Невозможно удалить позицию, так как к ней привязаны сотрудники'));
    } else {
      dispatch(addAlert('Должность удалена', 'success'));
    }
  } catch (err) {
    dispatch(addAlert(err?.response?.data?.error?.message || 'Возникла ошибка'));
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};
