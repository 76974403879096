import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { clearPositionOfFilter, updatePositionsFilter } from '../../../../../redux/actions/shifts';
import { getPositions } from '../../../../../redux/actions/management';
import { getPositionsSelector } from '../../../../../redux/selectors/management';
import PersonalFilterInner from '../../../molecules/PersonalFilerInner';
import { setIsLoad } from '../../../../../redux/actions/preloader';

const PersonalFilter = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { positionsFilter } = useSelector((state) => state.shifts);
  const allPositions = useSelector(getPositionsSelector);

  const positionsFilterData = useMemo(
    () => [
      {
        position: 'Все',
        checked: !positionsFilter.length,
      },
      ...allPositions.map((item) => {
        return {
          position: item.name,
          checked: positionsFilter.some((p) => p?.title === item.name) || !positionsFilter.length,
        };
      }),
    ],
    [allPositions, positionsFilter]
  );

  const getCheckedPositions = useCallback((positions) => {
    const checkedPositions = positions.filter((position) => position.checked);

    return checkedPositions.map((position, i) => {
      const key = Object.keys(position).find((key) => key !== 'checked');
      const value = key && position[key];

      return {
        type: key,
        title: value,
        action: () => {
          dispatch(clearPositionOfFilter(i));
        },
      };
    });
  }, []);

  const onSave = (positions) => {
    dispatch(setIsLoad(true));
    if (positions.find((item) => item.position === 'Все' && item.checked)) {
      dispatch(updatePositionsFilter([]));
    } else {
      const newPos = getCheckedPositions(positions);
      dispatch(updatePositionsFilter(newPos));
    }
    closeModal();
  };

  useEffect(() => {
    dispatch(getPositions());
  }, []);
  return (
    <PersonalFilterInner
      onCancel={closeModal}
      onSave={onSave}
      defaultPositionsFilterData={positionsFilterData}
    />
  );
};

PersonalFilter.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default memo(PersonalFilter);
