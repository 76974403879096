import { createSelector } from 'reselect';

const constrtuctorSelector = (state) => state.constructorCreator;
export const getPageLoading = createSelector(
  constrtuctorSelector,
  (state) => state.constructoLoading
);
export const getEmulatorLoading = createSelector(
  constrtuctorSelector,
  (state) => state.emulatorLoading
);
export const getConstructorMaindata = createSelector(
  constrtuctorSelector,
  (state) => state.mainData
);

export const getDefaultImages = createSelector(
  constrtuctorSelector,
  (state) => state.constructorDefaultImages
);

export const getButtonData = createSelector(constrtuctorSelector, (state) => state.buttonData);
export const getCustomBlockEditData = createSelector(
  constrtuctorSelector,
  (state) => state?.customBlockEditData
);

export const getEmulatorData = createSelector(constrtuctorSelector, (state) => state.emulatorItems);

export const getCurrentMode = createSelector(constrtuctorSelector, (state) => state.currentMode);
