import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import { useScrollFromWindow } from '../../../hooks/useScrollFromWindow';
import styles from './modal.module.css';
import OrderDetail from '../Modals/OrderDetails/OrderDetail';
import { setOrderDetailsModal } from '../../../../redux/actions/modal';
import { setOrderDetail } from '../../../../redux/actions/orders';
import { getOrderDetail } from '../../../../redux/selectors/orders';

const OrderDetailModal = () => {
  const setScrollEl = useScrollFromWindow();
  const dispatch = useDispatch();
  const { orderDetailsOpen } = useSelector((state) => state.modal);
  const orderDetail = useSelector(getOrderDetail);
  const closeModal = () => {
    dispatch(setOrderDetail(''));
    dispatch(setOrderDetailsModal(false));
  };
  return (
    <Modal
      portalClassName="order_detail_modal"
      contentRef={setScrollEl}
      overlayClassName={styles.overlay}
      className={`${styles.content} ${styles.orderDetailContent}`}
      isOpen={!!orderDetailsOpen && !!orderDetail}
      onRequestClose={closeModal}
    >
      <h2 className={`headerTitle ${styles.orderDetailTitle}`}>Информация о заказе</h2>
      <OrderDetail />
    </Modal>
  );
};

export default memo(OrderDetailModal);
