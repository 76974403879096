import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

export const historyAPI = {
  getTransactions: (type, dateParams, userId, user_ids) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/history/transactions${
        userId ? `/${userId}` : ''
      }?type=${type}&${dateParams}${(user_ids && `&user_ids=${user_ids}`) || ''}`,
      getAuthHeaders()
    ),
  uploadCsv: (type, dateParams, employee, user_ids) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/history/transactions${
        employee ? `/${employee}` : ''
      }/csv?type=${type}&${dateParams}${(user_ids && `&user_ids=${user_ids}`) || ''}`,
      {
        headers: {
          Authorization: `Bearer ${store.getState().session.token}`,
        },
        responseType: 'blob',
      }
    ),
  getFeedbacks: (type, dateParams, userId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/history/feedbacks${
        userId ? `/${userId}` : ''
      }?${type !== 'all' ? `type=${type}&` : ''}${dateParams}`,
      getAuthHeaders()
    ),
};

export const notificationsAPI = {
  getNotifications: (page) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/notifications?page=${page}`,
      getAuthHeaders()
    ),
  getUnread: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/notifications/unread`,
      getAuthHeaders()
    ),
  readedNotifications: (readedNotifications) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/notifications/read?${readedNotifications}`,
      {},
      getAuthHeaders()
    ),
};
