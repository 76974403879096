import { useEffect, useState } from 'react';

export const useScrollFromWindow = () => {
  const [scrollEl, setScrollEl] = useState(null);

  const onScrollFromWindow = (e) => {
    if (scrollEl && !scrollEl.contains(e.target)) {
      scrollEl.scrollBy(0, e.deltaY);
    }
  };

  useEffect(() => {
    if (scrollEl) {
      document.body.style.overflow = 'hidden';
      scrollEl.style.scrollBehavior = 'smooth';
      window.addEventListener('wheel', onScrollFromWindow);
    }
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('wheel', onScrollFromWindow);
    };
  }, [scrollEl]);

  return setScrollEl;
};
