export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear().toString();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  return `${day}.${month}.${year}`;
};

export const getCurrentDayOfWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysOfWeek = [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
  ];
  return daysOfWeek[dayOfWeek];
};

export const getFormatChars = (startTime, endTime) => {
  const startsWithTwo = { startTime: startTime[0] === '2', endTime: endTime[0] === '2' };
  return {
    0: '[0-2]',
    1: startsWithTwo.startTime ? /[0-3]/ : /[0-9]/,
    2: '[0-5]',
    3: '[0-9]',
    4: startsWithTwo.endTime ? /[0-3]/ : /[0-9]/,
  };
};

export const convertTimeRangeToArr = (timeRange) => {
  if (!timeRange) {
    return null;
  }

  const { start, end } = timeRange;
  const [startHour, startMinute] = start.split(':').map(Number);
  const [endHour, endMinute] = end.split(':').map(Number);

  let adjustedEndHour = endHour;
  if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
    adjustedEndHour += 24;
  }

  const startTime = new Date();
  startTime.setHours(startHour, '00', 0, 0);

  const endTime = new Date();
  endTime.setHours(adjustedEndHour, '00', 0, 0);

  const timeSlotsCount = Math.ceil((endTime - startTime) / (60 * 60 * 1000)); // Calculate number of hours

  return Array.from({ length: timeSlotsCount }, (_, index) => {
    const currentHour = new Date(startTime);
    currentHour.setHours(startHour + index);
    return currentHour.toTimeString().slice(0, 5);
  });
};

export const findNextWorkdayDate = (schedule) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay() - 1;
  let daysToAdd = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const nextDayIndex = (currentDay + daysToAdd) % 7;
    if (schedule[nextDayIndex]?.is_holiday === false) {
      break;
    }
    daysToAdd = (daysToAdd + 1) % 7;
  }
  const nextWorkdayDate = new Date();
  nextWorkdayDate.setDate(currentDate.getDate() + daysToAdd);
  const day = String(nextWorkdayDate.getDate()).padStart(2, '0');
  const month = String(nextWorkdayDate.getMonth() + 1).padStart(2, '0');
  const year = nextWorkdayDate.getFullYear();

  return `${day}.${month}.${year}`;
};
