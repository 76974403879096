import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../atoms/Input';
import s from './SettingsConfirmEmail.module.css';
import Button from '../../../atoms/Button';
import { getProfileDataSelector } from '../../../../../redux/selectors/profile';
import {
  resendEmailCode,
  setConfirmEmailSeconds,
  verifyEmailCode,
} from '../../../../../redux/actions/setting';
import { addAlert } from '../../../../../redux/actions/alerts';

const SettingsConfirmEmail = ({ closeModal }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getProfileDataSelector);
  const [code, setCode] = useState('');
  const seconds = useSelector((state) => state.setting.confirmEmailSeconds);

  const onResendCode = () => {
    if (!seconds) {
      dispatch(
        resendEmailCode(() => {
          dispatch(setConfirmEmailSeconds(120));
        })
      );
    }
  };

  const onSendCode = () => {
    if (code.length === 4) {
      dispatch(
        verifyEmailCode(code, () => {
          closeModal();
        })
      );
    } else {
      dispatch(addAlert('Введите код'));
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      dispatch(setConfirmEmailSeconds(seconds > 1 ? seconds - 1 : 0));
    }, 1000);
    return () => clearInterval(id);
  }, [seconds]);

  return (
    <div className={s.container}>
      <p className="plainGrayText">На почту {profile?.email} отправлен код подтверждения</p>
      <Input
        clearIconTop={30}
        onClear={() => setCode('')}
        maxLength={4}
        label="Код"
        value={code}
        isBorder
        onChange={(e) => setCode(e.target.value)}
      />

      <div className="d-flex">
        {seconds > 0 && (
          <div className="d-flex">
            <p className="plainText">{`0${(seconds - (seconds % 60)) / 60}`}</p>
            <p className="plainText">:</p>
            <p className="plainText">
              {seconds % 60 >= 10 ? seconds % 60 : `0${seconds % 60}`}&nbsp;
            </p>
          </div>
        )}
        <button
          type="button"
          disabled={seconds}
          onClick={onResendCode}
          className={`${seconds ? 'plainGrayText' : 'plainPrimaryText'} ${s.resendBtn}`}
        >
          Отправить код повторно
        </button>
      </div>

      <Button onClick={onSendCode} title="Продолжить" />
    </div>
  );
};

export default SettingsConfirmEmail;
