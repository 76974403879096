// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CircleProgressBar_circle__\\+8Hiw {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(134, 114, 232, 0.1);
    border-radius: 50%;
    padding: 4px;
}

.CircleProgressBar_circle__svg__4VZ-h {
    width: 25px;
    height: 25px;
    transform: rotate(-90deg);
}

.CircleProgressBar_circle__circleBg__BFs8E {
    fill: transparent;
    stroke: rgba(208, 216, 235, 1);
    stroke-width: 2px;
    r: 10.5;
    cx: 12.5;
    cy: 12.5;
}

.CircleProgressBar_circle__circle__FJqe\\+ {
    fill: transparent;
    stroke: rgba(134, 114, 232, 1);
    stroke-width: 2px;
    r: 10.5;
    cx: 12.5;
    cy: 12.5;
    stroke-dasharray: 66;
    transition: stroke-dashoffset 0.3s linear;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/CircleProgressBar/CircleProgressBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,iBAAiB;IACjB,OAAO;IACP,QAAQ;IACR,QAAQ;AACZ;;AAEA;IACI,iBAAiB;IACjB,8BAA8B;IAC9B,iBAAiB;IACjB,OAAO;IACP,QAAQ;IACR,QAAQ;IACR,oBAAoB;IACpB,yCAAyC;AAC7C","sourcesContent":[".circle {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: rgba(134, 114, 232, 0.1);\n    border-radius: 50%;\n    padding: 4px;\n}\n\n.circle__svg {\n    width: 25px;\n    height: 25px;\n    transform: rotate(-90deg);\n}\n\n.circle__circleBg {\n    fill: transparent;\n    stroke: rgba(208, 216, 235, 1);\n    stroke-width: 2px;\n    r: 10.5;\n    cx: 12.5;\n    cy: 12.5;\n}\n\n.circle__circle {\n    fill: transparent;\n    stroke: rgba(134, 114, 232, 1);\n    stroke-width: 2px;\n    r: 10.5;\n    cx: 12.5;\n    cy: 12.5;\n    stroke-dasharray: 66;\n    transition: stroke-dashoffset 0.3s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circle": `CircleProgressBar_circle__+8Hiw`,
	"circle__svg": `CircleProgressBar_circle__svg__4VZ-h`,
	"circle__circleBg": `CircleProgressBar_circle__circleBg__BFs8E`,
	"circle__circle": `CircleProgressBar_circle__circle__FJqe+`
};
export default ___CSS_LOADER_EXPORT___;
