import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPositions } from '../../../../../redux/actions/management';
import { InputSelect } from '../../../../components/InputCustom/InputSelect/InputSelect';
import styles from './AddNewConstructorOption.module.css';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import { addAlert } from '../../../../../redux/actions/alerts';
import {
  addButton,
  getDefaultConstructorImages,
  setCustomBlockEditData,
  updateButton,
} from '../../../../../redux/actions/constructor';
import {
  getCustomBlockEditData,
  getDefaultImages,
} from '../../../../../redux/selectors/constructor';
import TimePickerWidget from '../../../molecules/ConstructoTabs/ConstructoButtonWidgets/TimePickerWidget';

// будут доработки, массив поставил временно, ждем бек
export const callCards = [
  {
    bgColor: '#F0EBFF',
    iconBgColor: '#8869E6',
  },
  {
    bgColor: '#FFEFDD',
    iconBgColor: '#FFC27A',
  },
  {
    bgColor: '#EBEDFF',
    iconBgColor: '#4053FF',
  },
  {
    bgColor: '#D9FFDF',
    iconBgColor: '#9AFF57',
  },
  {
    bgColor: '#FFEBEB',
    iconBgColor: '#FF9A9A',
  },
  {
    bgColor: '#FFFFEB',
    iconBgColor: '#FFFF85',
  },
  {
    bgColor: '#E5E0FF',
    iconBgColor: '#9A8AFF',
  },
  {
    bgColor: '#DFF7FF',
    iconBgColor: '#83E0FF',
  },
  {
    bgColor: '#E0F0FD',
    iconBgColor: '#69B1E6',
  },
];
const AddNewConstructorOption = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { positions } = useSelector((state) => state.management);
  const constructorDefaultImages = useSelector(getDefaultImages);
  const customBlockEditData = useSelector(getCustomBlockEditData);
  const [selectedDefaultImage, setSelectedDefaultImage] = useState('');
  const [dataForTimer, setDataForTimer] = useState({ waiter_waiting_time: 600 });
  // const [image] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [position, setPosition] = useState('');
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    dispatch(getPositions(true));
    dispatch(getDefaultConstructorImages());
  }, []);

  const onChangeTime = (type) => {
    if (
      (dataForTimer.waiter_waiting_time < 3600 && type === 'increment') ||
      (dataForTimer.waiter_waiting_time > 60 && type === 'decrement')
    ) {
      setDataForTimer(({ waiter_waiting_time }) => ({
        waiter_waiting_time:
          type === 'increment' ? waiter_waiting_time + 30 : waiter_waiting_time - 30,
      }));
    }
  };

  const onAdd = () => {
    if ((selectedDefaultImage || imageUrl) && position) {
      const data = new FormData();
      data.append('image', selectedDefaultImage?.id);
      data.append('custom_button_time', dataForTimer.waiter_waiting_time);
      data.append('position_id', position.id);
      if (selectedDefaultImage.bgColor) {
        data.append('background_color', selectedDefaultImage.bgColor);
      }
      if (selectedDefaultImage.iconBgColor) {
        data.append('circle_color', selectedDefaultImage.iconBgColor);
      }
      if (customBlockEditData) {
        dispatch(updateButton(customBlockEditData?.id, data));
        closeModal();
      } else {
        dispatch(addButton(data, closeModal));
      }
    } else {
      dispatch(addAlert('Выберите должность и загрузите изображение'));
    }
  };

  const onSelectDefaultImage = (item) => {
    setSelectedDefaultImage(item);
    setImageUrl(`${process.env.REACT_APP_TENQ_API}${item.url}`);
  };

  useEffect(() => {
    if (enabled) {
      setEnabled(false);
    }
  }, [enabled]);
  useEffect(() => {
    return () => {
      setPosition('');
      dispatch(setCustomBlockEditData(null));
      setImageUrl('');
      setSelectedDefaultImage('');
    };
  }, []);

  const filteredPositions = useMemo(() => {
    const customPositions = customBlockEditData?.organisation_custom_position || {};
    if (customPositions.hasOwnProperty('id')) {
      const selectedImg = constructorDefaultImages.find(
        (item) => item?.url === customBlockEditData?.image
      );
      setSelectedDefaultImage(selectedImg);
      setPosition(customPositions);
      setImageUrl(`${process.env.REACT_APP_TENQ_API}${customBlockEditData?.image}`);
      setDataForTimer({
        ...dataForTimer,
        waiter_waiting_time: customBlockEditData?.custom_button_time,
      });
    }
    return positions
      .filter((position) => position.name !== 'Администратор' && position.name !== 'Официант')
      .concat(Object.keys(customPositions).length > 0 ? customPositions : []);
  }, [positions, customBlockEditData?.organisation_custom_position, constructorDefaultImages]);

  const mergedArray = useMemo(() => {
    return constructorDefaultImages?.map((image, index) => {
      const callCard = callCards[index % callCards.length];
      return {
        ...image,
        ...callCard,
      };
    });
  }, [constructorDefaultImages, callCards]);
  return (
    <div>
      <div>
        <h3>{customBlockEditData ? 'Редактирование блока' : 'Добавление блока'}</h3>
      </div>
      <p className={styles.text}>Укажите должность для создания блока</p>

      <InputSelect
        className={styles.select}
        top="45px"
        value={position.name}
        selecter={(i) => setPosition(filteredPositions[i])}
        defaultValue="Должность"
        options={filteredPositions.map((el) => el?.name)}
        emptyText="Нет должности для выбора"
      />

      <p className={styles.text}>Изображение</p>
      {Array.isArray(mergedArray) && !!mergedArray?.length && (
        <div className={styles.libraryImages}>
          {mergedArray.map((item) => (
            <button
              key={item?.id}
              onClick={() => onSelectDefaultImage(item)}
              type="button"
              className={`${styles.libraryImageWrapper} ${
                (selectedDefaultImage?.id === item?.id && styles.libraryImageSelected) || ''
              }`}
              style={{ backgroundColor: item?.bgColor }}
            >
              <div className={styles.libraryImgBox} style={{ backgroundColor: item?.iconBgColor }}>
                <img
                  className={styles.libraryImg}
                  src={`${process.env.REACT_APP_TENQ_API}${item.url}`}
                  alt={`default-img-${item.id}`}
                />
              </div>
            </button>
          ))}
        </div>
      )}

      <p className={styles.text}>Настройка</p>

      <TimePickerWidget
        customValueChange={onChangeTime}
        setSeconds={() => {}}
        setMinute={() => {}}
        seconds={dataForTimer.waiter_waiting_time % 60}
        minutes={Math.floor(dataForTimer.waiter_waiting_time / 60)}
        title="Укажите время, за которое cотрудник подойдет к клиенту"
      />

      <div className={styles.buttons}>
        <Button title="Сохранить" onClick={onAdd} className={styles.button} />
        <ButtonCancel onClick={closeModal} className={styles.button} />
      </div>
    </div>
  );
};

export default React.memo(AddNewConstructorOption);
