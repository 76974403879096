import React from 'react';
import PropTypes from 'prop-types';
import s from './ButtonCancel.module.css';

const ButtonCancel = ({ title, onClick, className, widthByContent, changeStyleMove = false }) => {
  let style = `${s.button} ${className}`;
  if (widthByContent) {
    style = `${style} ${s.widthByContent}`;
  }
  if (changeStyleMove) {
    style = `${style} ${s.moveBtn}`;
  }

  return (
    <div onClick={onClick} className={style}>
      {title}
    </div>
  );
};

export default React.memo(ButtonCancel);

ButtonCancel.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonCancel.defaultProps = {
  title: 'Отмена',
};
