import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './Input.module.css';
import CloseIcon from '../../../../assets/Icons/close';

const Input = ({
  value,
  onChange,
  onKeyDown,
  withoutClearBtn,
  onBlur,
  inputRef,
  placeholder,
  label,
  className,
  isWhiteBackground,
  isBorder,
  onClear,
  disabled,
  containerClassName,
  clearIconTop,
  maxLength,
  type,
  name,
}) => {
  return (
    <div className={`${s.inputBox} ${containerClassName || ''}`}>
      {!!label && <div className={`plainGrayText ${s.label}`}>{label}</div>}
      <div className="input_with_icon">
        <input
          name={name}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          className={`${s.input} ${isWhiteBackground ? s.white : ''} ${
            isBorder ? s.border : ''
          } ${className}`}
          value={value}
          onChange={onChange}
          ref={inputRef}
          placeholder={placeholder}
          type={type || 'text'}
          disabled={disabled}
          maxLength={maxLength}
        />
        {(value && !withoutClearBtn && (
          <div
            onClick={onClear}
            className={s.clear}
            style={{
              top: clearIconTop || '45%',
            }}
          >
            <CloseIcon width={9} height={9} color="#94a3b8" />
          </div>
        )) ||
          null}
      </div>
    </div>
  );
};

export default memo(Input);

Input.propTypes = {
  withoutClearBtn: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.any,
  placeholder: PropTypes.string,
  label: PropTypes.any,
  className: PropTypes.string,
  isWhiteBackground: PropTypes.bool,
  isBorder: PropTypes.bool,
  type: PropTypes.string,
  clearIconTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  isWhiteBackground: false,
  isBorder: false,
};
