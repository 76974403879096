import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ManagementFilter from '../../../molecules/ManagementFilter';
import { setDateFilter, setPositionsOfFilter } from '../../../../../redux/actions/shifts';
import { getPositions } from '../../../../../redux/actions/management';
import { getPositionsSelector } from '../../../../../redux/selectors/management';
import { formatDate } from '../../../../../utils/date';

const ShiftsFilter = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { dateFilter, positionsFilter } = useSelector((state) => state.shifts);
  const { period, from, to } = dateFilter;
  const allPositions = useSelector(getPositionsSelector);
  const selectedPositions = positionsFilter;
  const positionsFilterData = useMemo(
    () => [
      {
        position: 'Все',
        checked: !selectedPositions.length,
      },
      ...allPositions.map((item) => {
        return {
          position: item.name,
          checked: selectedPositions.includes(item.name) || !selectedPositions.length,
        };
      }),
    ],
    [allPositions, selectedPositions]
  );
  const dateFilterData = {
    periods: dateFilter.translatePeriods,
    activePeriod: dateFilter.period,
    from: dateFilter.from,
    to: dateFilter.to,
  };

  const onSave = (positions) => {
    if (positions.find((item) => item.position === 'Все' && item.checked)) {
      dispatch(setPositionsOfFilter([]));
    } else {
      dispatch(
        setPositionsOfFilter(positions.filter((item) => item.checked).map((item) => item.position))
      );
    }
    dispatch(
      setDateFilter(
        from && to
          ? {
              ...dateFilter,
              period,
              from: from ? formatDate(from, 'yyyy-mm-dd') : '',
              to: to ? formatDate(to, 'yyyy-mm-dd') : '',
            }
          : {
              ...dateFilter,
              period,
            }
      )
    );
    closeModal();
  };

  useEffect(() => {
    dispatch(getPositions());
  }, []);

  return allPositions.length ? (
    <ManagementFilter
      onCancel={closeModal}
      onSave={onSave}
      defaultDateFilterData={dateFilterData}
      defaultPositionsFilterData={positionsFilterData}
    />
  ) : null;
};

ShiftsFilter.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default memo(ShiftsFilter);
