import { createSelector } from 'reselect';

const sessionSelector = (state) => state.session;
export const getSessionAutorizedSelector = createSelector(
  sessionSelector,
  (state) => state.autorized
);
export const getSessionTokenSelector = createSelector(sessionSelector, (state) => state.token);
export const getSessionIsTooManyRequestSelector = createSelector(
  sessionSelector,
  (state) => state.isTooManyRequest
);
export const getSessionCompletedFieldsSelector = createSelector(
  sessionSelector,
  (state) => state.completedFields
);
export const getSessionInviteHashSelector = createSelector(
  sessionSelector,
  (state) => state.inviteHash
);
