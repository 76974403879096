import { createSelector } from 'reselect';

const managementSelector = (state) => state.management;
export const getCurrentEmployeeSelector = createSelector(
  managementSelector,
  (state) => state.currentEmployee
);
export const getCurrentEmployeeBalanceSelector = createSelector(
  managementSelector,
  (state) => state.currentEmployeeBalance
);
export const getShowControlModalSelector = createSelector(
  managementSelector,
  (state) => state.showControlModal
);
export const getInviteDataSelector = createSelector(
  managementSelector,
  (state) => state.inviteData
);
export const getUserInfoSelector = createSelector(managementSelector, (state) => state.userInfo);
export const getPositionsSelector = createSelector(managementSelector, (state) => state.positions);
export const getEditablePositionSelector = createSelector(
  managementSelector,
  (state) => state.editablePosition
);
export const getDeletingPositionSelector = createSelector(
  managementSelector,
  (state) => state.deletingPosition
);
