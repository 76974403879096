import * as React from 'react';

const AlertErrorIcon = (props) => (
  <svg width={30} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y={0.5} width={30} height={30} rx={15} fill="#E55858" />
    <path
      d="m11.97 12.47 6.06 6.06M11.97 18.53l6.06-6.06"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default React.memo(AlertErrorIcon);
