import { createSlice } from '@reduxjs/toolkit';
import { getUsers, organisationAPI } from '../../api/organisation';
import { setModal, setModalType } from './modal';
import { setInviteData } from './management';
import { getNotifications } from './notifications';
import { addAlert } from './alerts';

export const organisationSlice = createSlice({
  name: 'organisation',
  initialState: {
    data: {
      info: {
        id: '',
        name: '',
        address: '',
        logo: '',
        fon: '',
        description: '',
      },
      balance: null,
      cards: [],
      dashboard: null,
      users: [],
      types: [],
    },
  },
  reducers: {
    setOrganisationInfo: (state, data) => {
      state.data.info = data.payload;
    },
    setOrganisationBalance: (state, data) => {
      state.data.balance = data.payload;
    },
    setCards: (state, data) => {
      state.data.cards = data.payload;
    },
    setOrganisationDashboard: (state, data) => {
      state.data.dashboard = data.payload;
    },
    setOrganisationUsers: (state, data) => {
      state.data.users = data.payload;
    },
    setOrganisationTypes: (state, data) => {
      state.data.types = data.payload;
    },
  },
});

export const {
  setOrganisationInfo,
  setOrganisationBalance,
  setCards,
  setOrganisationDashboard,
  setOrganisationUsers,
  setOrganisationTypes,
} = organisationSlice.actions;

export default organisationSlice.reducer;

export const initOutputMoney = (amount) => async (dispatch) => {
  try {
    await organisationAPI.initOutputMoney(amount).then((res) => {
      Object.assign(document.createElement('a'), {
        href: res.data.link,
      }).click();
    });
  } catch (err) {
    dispatch(
      addAlert(
        err.response?.data?.errors['order.amount'] ||
          err.response?.data?.errors ||
          'Возникла ошибка',
        'error'
      )
    );
    console.log(err);
    console.log(err.response);
  }
};

export const addPersonalWithQr = (data) => async (dispatch) => {
  try {
    const response = await organisationAPI.addPersonalWithQr(data);
    getUsers();
    if (!response.data.error) {
      dispatch(setModalType(''));
      dispatch(setModal(false));
      dispatch(addAlert('Приглашение отправлено', 'success'));
      dispatch(
        setInviteData({
          selectedPositionId: null,
          name: '',
          surname: '',
          phone: {
            code: '+7',
            number: '',
          },
        })
      );
    } else {
      dispatch(addAlert(response.data.error?.message || 'Возникла ошибка'));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response, 'err.response)');
    if (err.response.status === 409) {
      dispatch(addAlert('Сотрудник с такими контактными данными уже добавлен в Сервис'));
    } else {
      dispatch(addAlert('Возникла ошибка'));
    }
  }
};

export const acceptCall = (visitor_call_id, hash, callback) => async (dispatch, getState) => {
  try {
    const user_id = getState().profile?.data?.id;
    await organisationAPI.acceptCall(visitor_call_id, hash, user_id).then(async () => {
      await dispatch(getNotifications(1));
      callback && callback();
    });
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
