import * as React from 'react';

const CloseIcon = ({ color, width, height }) => (
  <svg
    width={width || 11}
    height={height || 11}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width={2.113}
      height={13.444}
      rx={1.056}
      transform="rotate(44.999 4.753 11.476)"
      fill={color || '#1E293B'}
    />
    <rect
      width={2.113}
      height={13.444}
      rx={1.056}
      transform="rotate(135.001 3.531 7.031)"
      fill={color || '#1E293B'}
    />
  </svg>
);

export default React.memo(CloseIcon);
