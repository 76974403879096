// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Alerts_container__K4n5B {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 9999;
  color: #f9f9f9;
  transition: height cubic-bezier(0.075, 0.82, 0.165, 1) .4s;
}

.Alerts_positionRight__Lth5l {
  right: 15px;
  top: 20px;
}

.Alerts_closeIcon__UDL9r {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Alerts_item__dlhdy {
  cursor: pointer;
  margin-bottom: 15px;
  box-shadow: 0px 4px 8px rgba(21, 32, 48, 0.22);
  border-radius: 16px;
  padding: 10px 16px;
  min-width: 280px;
  max-width: 280px;
  min-height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition-property: all;
  transition-duration: .5s;
}

.Alerts_itemHidden__kYxn2 {
  opacity: 0;
  transform: translate(300px, -10px);
}

.Alerts_itemActive__\\+a-sa {
  opacity: 1;
  transform: translateX(0px);

}

.Alerts_itemInfoBlock__BGx4q {
  display: flex;
  align-items: center;
  gap: 15px;
}

.Alerts_text__mi\\+T7 {
  max-width: 80%;
}

.Alerts_itemSuccess__c6ilI {
  background: #A3DD34;
}

.Alerts_itemError__p3SUc {
  background: #FF6262;
}`, "",{"version":3,"sources":["webpack://./src/App/features/molecules/Alerts/Alerts.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,cAAc;EACd,0DAA0D;AAC5D;;AAEA;EACE,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,8CAA8C;EAC9C,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,wBAAwB;EACxB,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,UAAU;EACV,0BAA0B;;AAE5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  font-size: 14px;\n  box-sizing: border-box;\n  position: fixed;\n  z-index: 9999;\n  color: #f9f9f9;\n  transition: height cubic-bezier(0.075, 0.82, 0.165, 1) .4s;\n}\n\n.positionRight {\n  right: 15px;\n  top: 20px;\n}\n\n.closeIcon {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.item {\n  cursor: pointer;\n  margin-bottom: 15px;\n  box-shadow: 0px 4px 8px rgba(21, 32, 48, 0.22);\n  border-radius: 16px;\n  padding: 10px 16px;\n  min-width: 280px;\n  max-width: 280px;\n  min-height: 55px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  transition-property: all;\n  transition-duration: .5s;\n}\n\n.itemHidden {\n  opacity: 0;\n  transform: translate(300px, -10px);\n}\n\n.itemActive {\n  opacity: 1;\n  transform: translateX(0px);\n\n}\n\n.itemInfoBlock {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n}\n\n.text {\n  max-width: 80%;\n}\n\n.itemSuccess {\n  background: #A3DD34;\n}\n\n.itemError {\n  background: #FF6262;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Alerts_container__K4n5B`,
	"positionRight": `Alerts_positionRight__Lth5l`,
	"closeIcon": `Alerts_closeIcon__UDL9r`,
	"item": `Alerts_item__dlhdy`,
	"itemHidden": `Alerts_itemHidden__kYxn2`,
	"itemActive": `Alerts_itemActive__+a-sa`,
	"itemInfoBlock": `Alerts_itemInfoBlock__BGx4q`,
	"text": `Alerts_text__mi+T7`,
	"itemSuccess": `Alerts_itemSuccess__c6ilI`,
	"itemError": `Alerts_itemError__p3SUc`
};
export default ___CSS_LOADER_EXPORT___;
