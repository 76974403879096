import React, { useState, useEffect } from 'react';
import AlertErrorIcon from '../../../../assets/Icons/alertError';
import AlertSuccessIcon from '../../../../assets/Icons/alertSuccess';
import s from './Alerts.module.css';

export const Alert = ({ item, deleteItem }) => {
  const [isItemHidden, setIsItemHidden] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsItemHidden(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (!isItemHidden) {
      setTimeout(() => {
        hideMessage();
      }, item?.seconds * 1000);
    }
  }, [isItemHidden]);

  function hideMessage() {
    setIsItemHidden(!isItemHidden);
    setTimeout(() => {
      deleteItem(item.id);
    }, 500);
  }

  return (
    <div
      onClick={hideMessage}
      className={`${s.item} ${item.type === 'success' ? s.itemSuccess : s.itemError} ${
        isItemHidden ? s.itemHidden : s.itemActive
      }`}
    >
      <div className={s.itemInfoBlock}>
        {item.type === 'error' ? <AlertErrorIcon /> : <AlertSuccessIcon />}
        <span className={s.text}>{item?.message}</span>
      </div>
    </div>
  );
};
