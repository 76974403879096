import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import stl from './TableBookingEditor.module.css';
import CloseIcon from '../../../../../../assets/Icons/close';
import closeIcon from '../../../../../../images/icons/plus.svg';
import Button from '../../../../atoms/Button/Button';
import {
  bookingTable,
  setEditingData,
  setSelectedTimes,
  updateReservation,
} from '../../../../../../redux/actions/booking';
import { findNextWorkdayDate, getFormatChars } from '../../../../../../utils/bookingUtilits';
import { addAlert } from '../../../../../../redux/actions/alerts';
import Input from '../../../../atoms/Input';
import { getConstructorMaindata } from '../../../../../../redux/selectors/constructor';
import shiftStyles from '../../../Shifts/Shifts.module.css';

const TableBookingEditor = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedZone, selectedTable, editingData, selectedTimes, dateFilter } = useSelector(
    (state) => state.booking
  );
  const { org_work_period } = useSelector(getConstructorMaindata);
  const [startTime, setStartTime] = useState(editingData?.time_from || '');
  const [endTime, setEndTime] = useState(editingData?.time_to || '');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState(editingData?.name || '');

  const currentDate = useMemo(
    () => org_work_period && findNextWorkdayDate(org_work_period),
    [org_work_period]
  );

  const formatChars = useMemo(() => getFormatChars(startTime, endTime), [startTime, endTime]);

  const isValidTimeFormat = useMemo(() => {
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    return timeRegex.test(startTime) && timeRegex.test(endTime);
  }, [startTime, endTime]);
  function isStartTimeBeforeEndTime(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    return startHour < endHour || (startHour === endHour && startMinute < endMinute);
  }

  function isTimeDifferenceGreaterThan30Minutes(startHour, endHour) {
    const [startHourStr, startMinuteStr] = startHour.split(':');
    const [endHourStr, endMinuteStr] = endHour.split(':');

    const startTime = new Date();
    startTime.setHours(parseInt(startHourStr, 10));
    startTime.setMinutes(parseInt(startMinuteStr, 10));

    const endTime = new Date();
    endTime.setHours(parseInt(endHourStr, 10));
    endTime.setMinutes(parseInt(endMinuteStr, 10));

    const timeDifference = endTime - startTime;
    const minutesDifference = timeDifference / (1000 * 60);

    return minutesDifference >= 30;
  }

  const oncCreateBooking = () => {
    const phoneWithoutPlus = phoneNumber.replace('+', '');
    const phoneWithoutSpaces = phoneWithoutPlus.replace(/\s/g, '');
    const body = {
      desk_unit_id: editingData ? editingData?.desk_unit?.id : selectedTable?.id,
      date: editingData ? editingData?.date : dateFilter || currentDate,
      time_from: startTime,
      time_to: endTime,
      phone: phoneWithoutSpaces,
      name,
    };
    if (isValidTimeFormat) {
      if (isStartTimeBeforeEndTime(startTime, endTime)) {
        if (isTimeDifferenceGreaterThan30Minutes(startTime, endTime)) {
          if (editingData) {
            dispatch(updateReservation(body, editingData?.id));
          } else {
            dispatch(bookingTable(body));
          }
          closeModal(null, true);
          dispatch(setSelectedTimes([]));
        } else {
          dispatch(addAlert('Минимальное время бронирования составляет 30 минут'));
        }
      } else {
        dispatch(addAlert('Время начала брони должно быть меньше окончания'));
      }
    } else {
      dispatch(addAlert('Укажите время начала и окончания брони'));
    }
  };
  const onClose = () => {
    dispatch(setEditingData(null));
    dispatch(setSelectedTimes([]));
    closeModal(null, true);
  };
  function addOneHour(time) {
    const [hours, minutes] = time.split(':');
    let newHours = parseInt(hours, 10) + 1;
    if (newHours > 23) {
      newHours = 0;
    }
    const formattedHours = String(newHours).padStart(2, '0');
    return `${formattedHours}:${minutes}`;
  }
  function getStartAndEnd(times) {
    const sortedTimes = [...times].sort();

    const startTime = sortedTimes[0];
    const newEndTimes = addOneHour(sortedTimes[sortedTimes.length - 1]);

    if (selectedTable?.bookingData) {
      let minStartTime = startTime;
      let maxEndTime = newEndTimes;

      const [d, m, y] = currentDate.split('.');
      const dateString = `${y}-${m}-${d}`;
      const timestamp_from = new Date(`${dateString}T${startTime}`).getTime();
      const timestamp_to = new Date(`${dateString}T${newEndTimes}`).getTime();
      for (const booking of selectedTable.bookingData) {
        const { time_from, time_to } = booking;

        const [bookingFrom, bookingTo] = [
          new Date(`${dateString}T${time_from}`).getTime(),
          new Date(`${dateString}T${time_to}`).getTime(),
        ];
        if (
          ((bookingFrom > timestamp_from && bookingFrom < timestamp_to) ||
            (bookingTo > timestamp_from && bookingTo < timestamp_to)) &&
          timestamp_to < bookingTo
        ) {
          maxEndTime = time_from;
        }

        if (bookingTo > timestamp_from && timestamp_to > bookingTo) {
          minStartTime = time_to;
        }
      }

      setStartTime(minStartTime);
      setEndTime(maxEndTime);
    } else {
      setStartTime(startTime);
      setEndTime(newEndTimes);
    }
  }
  useEffect(() => {
    if (!editingData) {
      getStartAndEnd(selectedTimes);
    } else {
      const formattedPhoneNumber = `+7 ${editingData?.phone.slice(1, 4)} ${editingData?.phone.slice(
        4,
        7
      )} ${editingData?.phone.slice(7, 9)} ${editingData?.phone.slice(9, 11)}`;
      setPhoneNumber(formattedPhoneNumber);
    }
    return () => {
      setStartTime('');
      setEndTime('');
      setPhoneNumber('');
      setName('');
      dispatch(setEditingData(null));
      dispatch(setSelectedTimes([]));
    };
  }, []);

  const canCreate = useMemo(() => {
    return !!(
      name.length >= 2 &&
      phoneNumber.length === 16 &&
      startTime.length === 5 &&
      endTime.length === 5
    );
  }, [name, phoneNumber, startTime, endTime]);

  return (
    <div className={stl.bookingTable}>
      <div className={stl.bookingTableHead}>
        <span>{editingData ? 'Редактирование брони' : 'Бронирование стола'}</span>
        <div onClick={onClose} className={stl.bookingTableClose}>
          <CloseIcon color="#94A3B8" />
        </div>
      </div>
      <div className={stl.bookingTableDateBox}>
        <span className={stl.bookingTableDateTitle}>Дата</span>
        <span className={`${stl.bookingTableDate} ${stl.bookingTableDateTitle}`}>
          {editingData ? editingData?.date : dateFilter || currentDate}
        </span>
      </div>
      <div className={shiftStyles['popup-shift__form-inner']}>
        <div className={shiftStyles['popup-shift__form']}>
          <div className="plainGrayText">Имя</div>
          <div className={stl['popup-shift__form__name']}>
            <Input
              value={name}
              onChange={(e) => {
                if (e?.target?.value?.length <= 20) setName(e.target.value);
              }}
              isBorder
              onClear={() => setName('')}
              className={`${name.length > 0 && name.length < 2 ? stl.bookingInputError : ''}`}
              clearIconTop="43%"
              placeholder="Имя"
            />
          </div>
        </div>
        <div className={shiftStyles['popup-shift__form']}>
          <div className="plainGrayText">Телефон</div>
          <div className={shiftStyles['popup-shift__form__end_date']}>
            <InputMask
              placeholder="+7"
              name="username"
              mask="+7 999 999 99 99"
              maskChar={null}
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className={`${
                phoneNumber.length > 0 && phoneNumber.length < 16 ? stl.bookingInputError : ''
              }`}
            />
            <span onClick={() => setPhoneNumber('')}>
              <img src={closeIcon} alt="close" />
            </span>
          </div>
        </div>
      </div>
      <div className={shiftStyles['popup-shift__form-inner']}>
        <div className={shiftStyles['popup-shift__form']}>
          <div className="plainGrayText">Начало брони</div>
          <div className={shiftStyles['popup-shift__form__start_date']}>
            <InputMask
              mask="01:23"
              placeholder="__:__"
              onChange={(e) => {
                setStartTime(e.target.value);
              }}
              maskChar={null}
              formatChars={formatChars}
              value={startTime}
              className={`${
                startTime.length > 0 && startTime.length < 5 ? stl.bookingInputError : ''
              }`}
            />
            <span onClick={() => setStartTime('')}>
              <img src={closeIcon} alt="close" />
            </span>
          </div>
        </div>
        <div className={shiftStyles['popup-shift__form']}>
          <div className="plainGrayText">Конец брони</div>
          <div className={shiftStyles['popup-shift__form__end_date']}>
            <InputMask
              mask="04:23"
              placeholder="__:__"
              formatChars={formatChars}
              maskChar={null}
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className={`${endTime.length > 0 && endTime.length < 5 ? stl.bookingInputError : ''}`}
            />
            <span onClick={() => setEndTime('')}>
              <img src={closeIcon} alt="close" />
            </span>
          </div>
        </div>
      </div>
      <div className={stl.bookingZoneInfo}>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateTitle}>Рабочая область</span>
          <span
            className={`${stl.bookingTableDate} ${stl.bookingTableDateTitle} ${stl.bookingTableName}`}
          >
            {selectedZone?.name}
          </span>
        </div>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateTitle}>Стол</span>
          <span
            className={`${stl.bookingTableDate} ${stl.bookingTableDateTitle} ${stl.bookingTableSection}`}
          >
            {selectedTable?.num}
          </span>
        </div>
      </div>
      <Button
        isDisabled={!canCreate}
        onClick={oncCreateBooking}
        title="Назначить"
        className={stl.bookingTableBtn}
      />
    </div>
  );
};

TableBookingEditor.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(TableBookingEditor);
