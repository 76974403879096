export const createDateIntervals = (dateIntervals) => {
  const dateIntervalsObj = {};

  let interval_key = 0;
  let start_date = dateIntervals[0];
  dateIntervalsObj[interval_key] = start_date;

  for (let i = 0; i + 1 < dateIntervals.length; i++) {
    if (+dateIntervals[i + 1].slice(0, 2) - +dateIntervals[i].slice(0, 2) === 1) {
      dateIntervalsObj[interval_key] = `${start_date}-${dateIntervals[i + 1]}`;
    } else {
      interval_key += 1;
      start_date = dateIntervals[i + 1];
      dateIntervalsObj[interval_key] = start_date;
    }
  }

  return dateIntervalsObj;
};
