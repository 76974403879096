import * as React from 'react';

const SvgFilterIcon = (props) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.273 1.45508H1.72754L7.54572 8.33508V13.0914L10.4548 14.546V8.33508L16.273 1.45508Z"
      stroke="#1E293B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default React.memo(SvgFilterIcon);
