import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './OutputMoney.module.css';
import { ClearIcon } from '../../../../../assets/Icons/clearIcon';
import ArrowToRight from '../../../../../assets/Icons/arrowToRight';
import { initOutputMoney } from '../../../../../redux/actions/organisation';
import { addAlert } from '../../../../../redux/actions/alerts';
import { SvgRubleIcon } from '../../../../../assets/Icons/SvgRubleIcon';

const OutputMoney = () => {
  const dispatch = useDispatch();
  const organisation = useSelector((state) => state.profile);
  const balance = String((organisation?.data?.balance && organisation?.data?.balance / 100) || 0);
  const [amount, setAmount] = useState('');
  const onChangeAmount = (e) => {
    const value = e.target.value.replace(/\s/g, '');
    const valueAfterDot = value.split('.')[1]?.length;

    if (
      Number(value) <= Number(balance) &&
      !value.startsWith('00') &&
      ((valueAfterDot && valueAfterDot <= 2) || !valueAfterDot)
    ) {
      setAmount(value);
    }
  };

  const withdrawComission = useMemo(() => {
    const sum = Number(amount.replace(/\s/g, '')) * 0.01;
    return sum < 30 ? 30 : Number(sum.toFixed(2));
  }, [amount]);

  const outputMoney = () => {
    const amountWithoutSpaces = amount.replace(/\s/g, '');

    const isZeroDot = /^\d+\.\d+$/.test(amountWithoutSpaces); // Проверка на "0."
    const isInteger = /^-?\d+$/.test(amountWithoutSpaces); // Проверка на целое число
    const isNonOctal = !/^0\d+$/.test(amountWithoutSpaces); // Проверка на восьмеричное число

    if (isZeroDot || (isInteger && isNonOctal)) {
      const parsedAmount = parseFloat(amountWithoutSpaces);
      if (parsedAmount < 31) {
        dispatch(addAlert('Сумма вывода должна быть больше 30 рублей'));
      } else {
        dispatch(initOutputMoney(Math.floor(parsedAmount * 100)));
      }
    } else {
      dispatch(addAlert('Сумма вывода средств некорректна'));
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData('text/plain');
    const digitsOnly = clipboardData.replace(/\D/g, ''); // Оставить только цифры
    onChangeAmount({ target: { value: digitsOnly } });
  };

  return (
    <div className={s.modalWinMoney}>
      <div className={s.TabletInfo}>
        <div className={s.tabletInfoTitle}>
          <span className={s.sectionsOst}>Доступно</span>
          <span className={s.sectionsOst}>Останется</span>
        </div>
        <div className={s.TabletInfoMoney}>
          <div className={s.sectionsMoneyLeft}>
            <span className={s.sectionsMoney}>
              {Number(balance).toLocaleString('fr').replace(',', '.')}
            </span>
            <SvgRubleIcon />
          </div>
          <span className={s.sectionsArrow}>
            <ArrowToRight />
          </span>
          <div className={s.sectionsMoneyRight}>
            <span className={`${s.sectionsMoney} ${s.sectionsMoneyRight}`}>
              {Number(balance - String(amount).replace(/ /g, ''))
                .toLocaleString('fr')
                .replace(',', '.')}
            </span>
            <SvgRubleIcon />
          </div>
        </div>
      </div>
      <div className={s.workWithAvatar}>
        <span className={s.postPer}>Сумма вывода</span>
      </div>
      <div className={s.FamAndNameFields}>
        <div className={s.rubleSymbol}>₽</div>
        <input
          maxLength={14}
          onChange={onChangeAmount}
          onPaste={handlePaste}
          className={s.InputSumm}
          value={amount}
        />
        {amount ? (
          <div className={s.clearInput} onClick={() => setAmount('')}>
            <ClearIcon />
          </div>
        ) : null}
      </div>
      <p className={s.commissionHint}>Комиссия Сервиса 10Q составляет {withdrawComission} руб.</p>
      <button type="button" className={s.gradMoneyBack} onClick={outputMoney}>
        <span className={s.textInGrad}>Далее</span>
      </button>
    </div>
  );
};

export default React.memo(OutputMoney);
