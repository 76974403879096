import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: [],
  },
  reducers: {
    setAlerts: (state, data) => {
      state.alerts = data.payload;
    },
  },
});

export const { setAlerts } = alertsSlice.actions;

export default alertsSlice.reducer;

export const addAlert = (message, type, seconds) => async (dispatch, getState) => {
  const alerts = getState().alerts.alerts;

  dispatch(
    setAlerts([
      ...alerts,
      {
        message,
        type: type || 'error',
        seconds: seconds || 3,
        id: uuidv4(),
      },
    ])
  );
};

export const removeAlert = (id) => async (dispatch, getState) => {
  const alerts = getState().alerts.alerts;
  dispatch(setAlerts(alerts.filter((el) => el.id !== id)));
};
