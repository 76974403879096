import { createSlice } from '@reduxjs/toolkit';
import { clubcardApi, groupsSettingsApi } from '../../api/clubcard';
import { addAlert } from './alerts';
import { getGroups, setCurrentProgram } from './groupsSettings';
import { setModal, setModalType } from './modal';
import { setIsLoad } from './preloader';

export const clubcardSlice = createSlice({
  name: 'clubcard',

  initialState: {
    clubcards: [],
    clubcardTemplates: [],
    freeClubcards: [],
    selectedClubcard: '',
    discounts: [],
    selectedClubcardId: '',
    usedGroup: null,
    selectedClientId: '',
    selectedProgramId: '',
  },

  reducers: {
    setSelectedClientId: (state, data) => {
      state.selectedClientId = data.payload;
    },
    setUsedGroup: (state, data) => {
      state.usedGroup = data.payload;
    },
    setSelectedClubcardId: (state, data) => {
      state.selectedClubcardId = data.payload;
    },
    setClubcards: (state, data) => {
      state.clubcards = data.payload;
    },
    setClubcardTemplates: (state, data) => {
      state.clubcardTemplates = data.payload;
    },
    setFreeClubcards: (state, data) => {
      state.freeClubcards = data.payload;
    },
    setSelectedClubcard: (state, data) => {
      state.selectedClubcard = data.payload;
    },
    setDiscounts: (state, data) => {
      state.discounts = data.payload;
    },
    setSelectedProgramId: (state, data) => {
      state.selectedProgramId = data.payload;
    },
  },
});

export const {
  setClubcards,
  setFreeClubcards,
  setSelectedClubcard,
  setClubcardTemplates,
  setDiscounts,
  setSelectedClubcardId,
  setSelectedClientId,
  setUsedGroup,
  setSelectedProgramId,
} = clubcardSlice.actions;

export default clubcardSlice.reducer;

export const getClubcards = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await clubcardApi.getClubcards();
    dispatch(setClubcards(response.data.data));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getDiscountsForNew = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await clubcardApi.getDiscountsForNew();
    response.data.data.sort((a, b) => a.discount - b.discount);
    dispatch(setDiscounts(response?.data?.data || []));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getClubcardTemplates = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await clubcardApi.getClubcardTemplates();
    dispatch(setClubcardTemplates(response.data.data));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getFreeClubcards = () => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await clubcardApi.getFreeClubcards();
    dispatch(setFreeClubcards(response?.data?.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const createClubcard = (data, cardId, callback, type) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await clubcardApi.createClubcard(data, cardId, type);
    dispatch(getClubcards());
    dispatch(
      setCurrentProgram({
        id: response.data.data,
        selected: false,
        ...data,
      })
    );
    dispatch(
      addAlert(type === 'add' ? 'Клубная карта создана' : 'Клубная карта изменена', 'success')
    );
    callback(response.data.data);
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Возникла ошибка', 'error'));
  }
};

export const applyDiscountForClubcard = (array, callback) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await clubcardApi.applyDiscountForClubcard(array).then(() => {
      callback && callback();
    });
  } catch (err) {
    console.log(err);
    dispatch(addAlert('Что-то пошло не так'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const addSumClientClubcardGroup = (data) => async (dispatch, getState) => {
  try {
    await groupsSettingsApi.addSumClientClubcardGroup(data).then(() => {
      const programId = getState((state) => state)?.clubcard?.selectedProgramId;
      dispatch(setModal(false));
      dispatch(setModalType(''));
      dispatch(getGroups(programId));
      dispatch(setSelectedClientId(''));
      dispatch(setSelectedProgramId(''));
      dispatch(addAlert('Изменения сохранены', 'success'));
    });
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const getEditClubcardInfo = (id) => async (dispatch) => {
  try {
    const response = await clubcardApi.getEditClubcardInfo(id).then((res) => {
      dispatch(setSelectedClubcard(res.data.data));
      return res;
    });
    return response.data;
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const selectClubcard = (id) => async (dispatch) => {
  try {
    const response = await clubcardApi.selectClubcard(id);
    console.log(response);
    dispatch(addAlert('Клубная карта выбрана', 'success'));
    dispatch(getClubcards());
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  }
};

export const deleteClubcard = (id) => async (dispatch) => {
  try {
    await clubcardApi.deleteClubcard(id).then(() => {
      dispatch(getClubcards());
      dispatch(addAlert('Клубная карта удалена', 'success'));
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  }
};
