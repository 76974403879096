export const toIsoString = (date) => {
  const pad = (num) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? '0' : '') + norm;
  };

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(
    date.getHours()
  )}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
};

export function formatDate(date, type) {
  const newDate = new Date(date);
  let dd = newDate.getDate();
  if (dd < 10) dd = `0${dd}`;
  let mm = newDate.getMonth() + 1;
  if (mm < 10) mm = `0${mm}`;
  const yyyy = newDate.getFullYear();
  const yy = newDate.getFullYear().toString().slice(-2);

  if (type === 'yyyy-mm-dd') return `${yyyy}-${mm}-${dd}`;
  if (type === 'dd.mm.yyyy') return `${dd}.${mm}.${yyyy}`;
  if (type === 'dd.mm.yy') return `${dd}.${mm}.${yy}`;
}

export function getNameMonth(date) {
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];
  return months[date.getMonth()];
}

export function secondsToMinutes(seconds, labelType) {
  let sec = seconds % 60;
  const min = (seconds - sec) / 60;
  sec = sec < 10 ? `0${sec}` : sec;
  return labelType === 'letters' ? `${min} мин ${sec} сек` : `${min}:${sec}`;
}

export function dateParse(date) {
  return {
    year: date.slice(6, 10),
    month: date.slice(3, 5) - 1,
    day: date.slice(0, 2),
    hour: date.slice(13, 15),
    minute: date.slice(16, 18),
    second: 0,
    millisecond: 0,
  };
}

export function calculatedDateFromPeriod(period) {
  const oneDay = 86400000;
  let today = formatDate(new Date(), 'yyyy-mm-dd');
  const someDate = new Date();
  const result = someDate.setDate(someDate.getDate());
  today = formatDate(new Date(result), 'yyyy-mm-dd');
  let calculatedFrom;
  switch (period) {
    case 'day':
      calculatedFrom = today;
      break;
    case 'week':
      calculatedFrom = formatDate(new Date() - oneDay * 6, 'yyyy-mm-dd');
      break;
    case 'month':
      calculatedFrom = formatDate(new Date(), 'yyyy-mm-dd');
      break;
    default:
      return [];
  }
  return [calculatedFrom, today];
}

export const weekDays = [
  {
    weekday: 'Понедельник',
    weekdayShort: 'Пн',
    checked: false,
  },
  {
    weekday: 'Вторник',
    weekdayShort: 'Вт',
    checked: false,
  },
  {
    weekday: 'Среда',
    weekdayShort: 'Ср',
    checked: false,
  },
  {
    weekday: 'Четверг',
    weekdayShort: 'Чт',
    checked: false,
  },
  {
    weekday: 'Пятница',
    weekdayShort: 'Пт',
    checked: false,
  },
  {
    weekday: 'Суббота',
    weekdayShort: 'Сб',
    checked: false,
  },
  {
    weekday: 'Воскресенье',
    weekdayShort: 'Вс',
    checked: false,
  },
];
