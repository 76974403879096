import { createSlice } from '@reduxjs/toolkit';
import { historyAPI } from '../../api/history';
import { formatDate } from '../../utils/date';
import { setIsLoad } from './preloader';

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState: {
    feedbacks: {},
    filterDate: {
      isCurrentPeriod: false,
      from: '',
      to: '',
    },
    isFetching: false,
  },
  reducers: {
    setFeedbacks: (state, data) => {
      state.feedbacks = data.payload;
    },
    setNewPageFeedbacks: (state, data) => {
      for (const key in data.payload.data) {
        if (state.feedbacks.data.hasOwnProperty(key)) {
          state.feedbacks.data[key] = [...state.feedbacks.data[key], ...data.payload.data[key]];
        } else {
          state.feedbacks.data[key] = data.payload.data[key];
        }
      }
      state.feedbacks.pagination = data.payload.pagination;
    },
    setIsCurrentPeriod: (state, data) => {
      state.filterDate.isCurrentPeriod = data.payload;
    },
    setFrom: (state, data) => {
      state.filterDate.from = data.payload;
    },
    setTo: (state, data) => {
      state.filterDate.to = data.payload;
    },
    setIsFetching: (state, data) => {
      state.isFetching = data.payload;
    },
  },
});

export const {
  setFeedbacks,
  setIsCurrentPeriod,
  setFrom,
  setTo,
  setIsFetching,
  setNewPageFeedbacks,
} = feedbacksSlice.actions;

export default feedbacksSlice.reducer;

export const getFeedbacks = (type, period, from, to, userId) => async (dispatch) => {
  dispatch(setIsLoad(true));
  let dateParams;
  if (period) {
    dateParams = `date=${period}`;
  } else if (from && to) {
    dateParams = `date[from]=${formatDate(from, 'dd.mm.yyyy')}&date[to]=${formatDate(
      to,
      'dd.mm.yyyy'
    )}`;
  }

  try {
    const response = await historyAPI.getFeedbacks(type, dateParams, userId);
    dispatch(setFeedbacks(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getNewPageFeedbacks = (type, period, from, to, id) => async (dispatch, getState) => {
  const { feedbacks, isFetching } = getState().feedbacks;
  const { pagination } = feedbacks;
  let dateParams;
  if (period) {
    dateParams = `date=${period}&page=${pagination?.current_page + 1}`;
  } else if (from && to) {
    dateParams = `date[from]=${formatDate(from, 'dd.mm.yyyy')}&date[to]=${formatDate(
      to,
      'dd.mm.yyyy'
    )}&page=${pagination?.current_page + 1}`;
  }

  try {
    if (!isFetching && pagination != null && pagination?.current_page < pagination?.total_pages) {
      dispatch(setIsFetching(true));
      const response = await historyAPI.getFeedbacks(type, dateParams, id);
      dispatch(setNewPageFeedbacks(response.data.data));
      dispatch(setIsFetching(false));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
