import { lazy } from 'react';
import AddNewSection from '../features/organisms/Modals/AddNewSection';
import OutputMoney from '../features/organisms/Modals/OutputMoney';
import InviteEmployee from '../features/organisms/Modals/InviteEmployee';
import ConfirmFire from '../features/organisms/Modals/ConfirmFire';
import PositionCreator from '../features/organisms/Modals/PositionCreator';
import ConfirmDeletingPosition from '../features/organisms/Modals/ConfirmDeletingPosition';
import ShiftsFilter from '../features/organisms/Modals/ShiftsFilter';
import PersonalFilter from '../features/organisms/Modals/PersonalFilter';
import OrderDetail from '../features/organisms/Modals/OrderDetails/OrderDetail';
import { VisitorCall } from '../features/organisms/Modals/VisitorCall/VisitorCall';
import { ConfirmDeletingClubcardForm } from '../features/organisms/Modals/ConfimDeletingClubcardForm/ConfimDeletingClubcardForm';
import { ConfirmDeletingClubcard } from '../features/organisms/Modals/ConfimDeletingClubcard/ConfimDeletingClubcard';
import SelectTakeawayMenuTable from '../features/organisms/Modals/SelectTakeawayMenuTable/SelectTakeawayMenuTable';
import NewOrderModal from '../features/organisms/Modals/NewOrderModal/NewOrderModal';
import EditImportedEmployee from '../features/organisms/Modals/EditImportedEmployee/EditImportedEmployee';
import AddNewConstructorOption from '../features/organisms/Modals/AddNewConstructorOption/AddNewConstructorOption';
import ConfirmDeletingConstructorOption from '../features/organisms/Modals/ConfirmDeletingConstructorOption/ConfirmDeletingConstructorOption';
import TransactionsEmployeeFilter from '../features/organisms/Modals/TransactionsEmployeeFilter/TransactionsEmployeeFilter';
import AddSumClientGroup from '../features/organisms/Modals/AddSumClientGroup/AddSumClientGroup';
import ClubcardGroupTransferSettings from '../features/organisms/Modals/ClubcardGroupTransferSettings/ClubcardGroupTransferSettings';
import LoginHint from '../features/organisms/Modals/LoginHint/LoginHint';
import OutputMoneyError from '../features/organisms/Modals/OutputMoneyError/OutputMoneyError';
import CaptchaModal from '../features/organisms/Modals/CaptchaModal/CaptchaModal';
import ConfirmRemoveClient from '../features/organisms/Modals/ConfirmRemoveClient/ConfirmRemoveClient';
import TableBookingEditor from '../features/organisms/Modals/BookingModals/TableBookingEditor/TableBookingEditor';
import BookedTableInfo from '../features/organisms/Modals/BookingModals/BookedTableInfo/BookedTableInfo';
import BookingFilters from '../features/organisms/Modals/BookingModals/BookingFilters/BookingFilters';
import ConfirmRemoveReservation from '../features/organisms/Modals/BookingModals/ConfirmRemoveReservation/ConfirmRemoveReservation';
import BookingDateFilter from '../features/organisms/Modals/BookingModals/BookingDateFilter/BookingDateFilter';
import ConstructorEmulatorModal from '../components/ConstructorEmulatorModal/ConstructorEmulatorModal';
import SettingsConfirmEmail from '../features/organisms/Modals/SettingsConfirmEmail/SettingsConfirmEmail';
import EditBreakPeriod from '../features/organisms/Modals/EditBreakPeriod/EditBreakPeriod';
import CreateShift from '../features/molecules/ShitsMolecules/CreateShift/CreateShift';
import SettingMenuView from '../features/organisms/Modals/SettingMenuView';

const ControlEmployee = lazy(() => import('../features/organisms/Modals/ControlEmployee'));

export const MODAL_TYPES = {
  editSection: 'edit-section',
  outputMoney: 'output-money',
  outputMoneyError: 'output-money-error',
  controlEmployee: 'control-employee',
  inviteEmployee: 'invite-employee',
  confirmFire: 'confirm-fire',
  createShiftModal: 'create-shift-modal',
  confirmRemoveClient: 'confirm-remove-client',
  TableBookingEditor: 'table-booking-editor',
  BookedTableInfo: 'booked-table-info',
  BookingFilters: 'booking-filters',
  BookingDateFilter: 'booking-date-filter',
  ConfirmRemoveReservation: 'confirm-remove-reservation',
  positionCreator: 'position-creator',
  deletingPosition: 'deleting-position',
  shiftsFilter: 'shifts-filter',
  personalFilter: 'personal-filter',
  OrderDetail: 'order-detail',
  VisitorCall: 'visitor-call',
  newOrderModal: 'new-order-modal',
  confirmDeletingClubcard: 'confirm-deleting-clubcard',
  confirmDeletingClubcardForm: 'confirm-deleting-clubcard-form',
  selectTakeawayMenuTable: 'select-takeaway-menu-table',
  editImportedEmployee: 'edit-imported-employee',
  editBreakPeriod: 'edit-break-period',
  addNewConstructorOption: 'add-new-constructor-option',
  constructorEmulatorModal: 'constructor-emulator-modal',
  confirmDeletingConstructorOption: 'confirm-deleting-constructor-option',
  transactionsEmployeeFilter: 'transactions-employee-filter',
  addSumClientGroup: 'add-sum-client-group',
  clubcardGroupTransferSettings: 'clubcard-group-transfer-settings',
  loginHint: 'login-hint',
  captchaModal: 'captcha-modal',
  settingsConfirmEmail: 'settings-confirm-email',
  settingMenuView: 'settings-menu-view',
};

export const MODALS = {
  'edit-section': {
    title: 'Редактирование секции',
    component: AddNewSection,
    closeAction: true,
  },
  'output-money': {
    title: 'Вывод',
    component: OutputMoney,
    closeAction: true,
  },
  'login-hint': {
    component: LoginHint,
    closeAction: true,
  },
  'control-employee': {
    component: ControlEmployee,
    closeAction: true,
  },
  'invite-employee': {
    component: InviteEmployee,
    closeAction: true,
    closeWithConfirmation: true,
  },
  'confirm-fire': {
    component: ConfirmFire,
    closeAction: true,
    closeWithConfirmation: true,
  },
  'confirm-remove-client': {
    component: ConfirmRemoveClient,
    closeAction: true,
    closeWithConfirmation: true,
  },
  'table-booking-editor': {
    component: TableBookingEditor,
    closeAction: true,
  },
  'booked-table-info': {
    component: BookedTableInfo,
    closeAction: true,
  },
  'booking-filters': {
    component: BookingFilters,
    closeAction: true,
  },
  'booking-date-filter': {
    component: BookingDateFilter,
    closeAction: true,
  },
  'confirm-remove-reservation': {
    component: ConfirmRemoveReservation,
    closeAction: true,
    closeWithConfirmation: true,
  },
  'position-creator': {
    component: PositionCreator,
    closeAction: true,
  },
  'deleting-position': {
    component: ConfirmDeletingPosition,
    closeAction: true,
  },
  'shifts-filter': {
    component: ShiftsFilter,
    closeAction: true,
  },
  'personal-filter': {
    component: PersonalFilter,
    closeAction: true,
  },
  'order-detail': {
    component: OrderDetail,
    closeAction: true,
    title: 'Информация о заказе',
  },
  'visitor-call': {
    component: VisitorCall,
    closeAction: true,
  },
  'confirm-deleting-clubcard': {
    component: ConfirmDeletingClubcard,
    closeAction: true,
  },
  'confirm-deleting-clubcard-form': {
    component: ConfirmDeletingClubcardForm,
    closeAction: true,
  },
  'select-takeaway-menu-table': {
    component: SelectTakeawayMenuTable,
    closeAction: true,
  },
  'new-order-modal': {
    component: NewOrderModal,
    closeAction: true,
  },
  'create-shift-modal': {
    component: CreateShift,
    closeAction: true,
  },
  'edit-imported-employee': {
    component: EditImportedEmployee,
    closeAction: true,
  },
  'edit-break-period': {
    component: EditBreakPeriod,
    closeAction: true,
  },
  'constructor-emulator-modal': {
    component: ConstructorEmulatorModal,
    title: 'Предпросмотр',
    closeAction: true,
  },
  'add-new-constructor-option': {
    component: AddNewConstructorOption,
    // title: 'Добавление блока',
    closeAction: true,
  },
  'confirm-deleting-constructor-option': {
    component: ConfirmDeletingConstructorOption,
    closeAction: true,
    title: 'Подтверждение',
  },
  'transactions-employee-filter': {
    title: 'Фильтр по сотрудникам',
    closeAction: true,
    component: TransactionsEmployeeFilter,
  },
  'add-sum-client-group': {
    title: 'Добавление заказа',
    closeAction: true,
    component: AddSumClientGroup,
  },
  'clubcard-group-transfer-settings': {
    title: 'Настройка автоматического переноса клиентов',
    closeAction: true,
    component: ClubcardGroupTransferSettings,
  },
  'output-money-error': {
    title: 'Операция не выполнена :(',
    closeAction: true,
    component: OutputMoneyError,
  },
  'captcha-modal': {
    title: 'Введите код с картинки',
    closeAction: true,
    component: CaptchaModal,
  },
  'settings-confirm-email': {
    title: 'Требуется подтверждение электронной почты',
    closeAction: true,
    component: SettingsConfirmEmail,
  },
  'settings-menu-view': {
    title: 'Настройка вида меню',
    closeAction: true,
    component: SettingMenuView,
  },
};
