export const RemoveItem = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 0.53125L8.5 0.03125L8.5 0.53125ZM16.4687 8.5L16.9687 8.5L16.4687 8.5ZM0.53125 8.5L1.03125 8.5L0.53125 8.5ZM8.5 16.4687L8.5 15.9687L8.5 16.4687ZM8.5 1.03125C12.6249 1.03125 15.9687 4.37512 15.9687 8.5L16.9687 8.5C16.9687 3.82284 13.1772 0.0312504 8.5 0.03125L8.5 1.03125ZM1.03125 8.5C1.03125 4.37512 4.37512 1.03125 8.5 1.03125L8.5 0.03125C3.82284 0.0312496 0.0312504 3.82284 0.03125 8.5L1.03125 8.5ZM8.5 15.9687C4.37512 15.9687 1.03125 12.6249 1.03125 8.5L0.03125 8.5C0.0312496 13.1772 3.82284 16.9687 8.5 16.9687L8.5 15.9687ZM8.5 16.9687C13.1772 16.9687 16.9687 13.1772 16.9687 8.5L15.9687 8.5C15.9687 12.6249 12.6249 15.9687 8.5 15.9687L8.5 16.9687Z"
        fill="#94A3B8"
      />
      <path d="M11.6875 5.31238L5.3125 11.6874" stroke="#94A3B8" />
      <path d="M11.6875 11.6875L5.3125 5.3125" stroke="#94A3B8" />
    </svg>
  );
};
