import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CustomCalendar } from '../../../../../components/InputCustom/CustomCalendar/CustomCalendar';
import { formatDate } from '../../../../../../utils/date';
import { getBookingList, setDateFilter } from '../../../../../../redux/actions/booking';
import { getConstructorMaindata } from '../../../../../../redux/selectors/constructor';
import { addAlert } from '../../../../../../redux/actions/alerts';

const BookingDateFilter = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { org_work_period } = useSelector(getConstructorMaindata);

  const onClose = useCallback(() => {
    closeModal(null, true);
  }, [closeModal]);

  const onSetFilter = useCallback(
    (dates) => {
      if (!dates) {
        return dispatch(addAlert('Выберите дату'));
      }
      const date = formatDate(dates, 'dd.mm.yyyy');
      dispatch(setDateFilter(date));
      dispatch(getBookingList(date));
      onClose();
    },
    [dispatch, onClose]
  );

  const convertDaysToNumbers = useCallback((daysArray) => {
    const daysOfWeek = [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ];
    return daysArray?.map((day) => daysOfWeek.indexOf(day)).filter((index) => index !== -1) || [];
  }, []);

  const getOffDays = useCallback(() => {
    const inputDays =
      org_work_period?.filter((el) => el?.is_holiday)?.map((el) => el?.day_of_week) || [];
    return convertDaysToNumbers(inputDays);
  }, [convertDaysToNumbers, org_work_period]);
  const isDayDisabled = useCallback(
    (date, disabledDays) => disabledDays.includes(date.getDay()),
    []
  );

  return (
    <div style={{ margin: -20 }}>
      <CustomCalendar
        tileDisabled={({ date }) => isDayDisabled(date, getOffDays())}
        selectRange={false}
        onCloseCalendar={onClose}
        onSelectRange={onSetFilter}
      />
    </div>
  );
};

BookingDateFilter.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(BookingDateFilter);
