import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from '../InviteEmployee/InviteEmployee.module.css';
import Input from '../../../atoms/Input';
import InputTel from '../../../atoms/InputTel';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import {
  getImportedEmployees,
  getSelectedImportedEmployee,
} from '../../../../../redux/selectors/IntegrationRkeeper';
import { setImportedEmployees } from '../../../../../redux/actions/integrationRkeeper';
import { addAlert } from '../../../../../redux/actions/alerts';
import { getOrganisationDataUsersSelector } from '../../../../../redux/selectors/organisation';

const EditImportedEmployee = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState({ code: '+7', number: '' });
  const selectedImportedEmployee = useSelector(getSelectedImportedEmployee);
  const importedEmployees = useSelector(getImportedEmployees);
  const users = useSelector(getOrganisationDataUsersSelector);

  const onSave = useCallback(() => {
    const isTheSamePhone = users.filter(
      (el) => el.phone === `7${phone?.number.replace(/\s/g, '')}`
    );
    const isTheSameEmail = users.filter((el) => el.email === email.trim());
    if (!name.trim() || !email.trim() || !phone.number.trim()) {
      dispatch(addAlert('Введите все данные'));
    } else if (isTheSamePhone.length) {
      dispatch(addAlert('Данный номер телефона привязан к другой учетной записи'));
    } else if (isTheSameEmail.length) {
      dispatch(addAlert('Данная электронная почта привязана к другой учетной записи'));
    } else {
      dispatch(
        setImportedEmployees(
          importedEmployees.map((el) =>
            el.guid === selectedImportedEmployee.guid ? { ...el, name, email, phone } : el
          )
        )
      );
      closeModal();
    }
  }, [importedEmployees, selectedImportedEmployee, name, email, phone]);

  useEffect(() => {
    setName(selectedImportedEmployee.name);
    setEmail(selectedImportedEmployee.email);
    selectedImportedEmployee.phone && setPhone(selectedImportedEmployee.phone);
  }, [selectedImportedEmployee]);
  return (
    <div>
      <div className={`${s.title} ${s.field}`}>Информация о сотруднике</div>
      <Input
        value={name}
        clearIconTop={30}
        onChange={(e) => setName(e.target.value)}
        onClear={() => setName('')}
        className={s.field}
        label="Сотрудник"
        isBorder
      />
      <Input
        onClear={() => setEmail('')}
        value={email}
        clearIconTop={30}
        onChange={(e) => setEmail(e.target.value)}
        label="Email"
        className={s.field}
        isBorder
      />
      <InputTel onChange={setPhone} value={phone} label="Телефон" className={s.field} />
      <div className={s.btns}>
        <Button onClick={onSave} title="Сохранить" />
        <ButtonCancel onClick={closeModal} />
      </div>
    </div>
  );
};

export default React.memo(EditImportedEmployee);
