import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RemoveItem } from '../../../../assets/Icons/removeItem';
import { getOrderDetail as fetchOrderDetail } from '../../../../redux/actions/orders';
import style from './OrderDetailDishes.module.css';
import { callRkeeperCommand } from '../../../../redux/actions/integrationRkeeper';

const OrderDetailDishes = ({ orderDetail, orderArray, isEnabledDeleteDish, isAdditionalOrder }) => {
  const dispatch = useDispatch();
  const onDeleteDish = (item) => {
    dispatch(
      callRkeeperCommand(
        'delete_dishes',
        false,
        () => {
          setTimeout(() => {
            dispatch(fetchOrderDetail(orderDetail.id));
          }, 2000);
        },
        {
          data: { [item.uni || item.dishId]: item.quantity },
          type: item.uni ? 'uni' : 'dishId',
          order_id: orderDetail.id,
        }
      )
    );
  };
  function formatCurrency(value) {
    // /\.?0+$/ ищет ноли, следующих за точкой
    const formattedValue = (value / 100).toFixed(2).replace(/\.?0+$/, '');
    return `${formattedValue}`;
  }
  return (
    <div className={isAdditionalOrder ? style.orderDetailBoxNewDishes : style.orderDetailBox}>
      <div className={style.orderDetailHeader}>
        <span className={style.orderDetailText}>Блюдо</span>
        <div className={style.orderDetailHeaderRight}>
          <span className={style.orderDetailText}>Ед.</span>
          <span className={style.orderDetailText}>Цена</span>
        </div>
      </div>
      {(Array.isArray(orderArray) &&
        orderArray?.length &&
        orderArray.map((item) => {
          const key = uuidv4();
          return (
            <div key={key} className={style.orderDetail}>
              <div className={style.orderDetailBody}>
                <div className={style.orderDetailDishName}>
                  <span
                    className={`${style.orderDetailText} ${
                      item?.is_dish_marking && style.orderDetailTextMarked
                    }`}
                  >
                    {item?.dishName}
                  </span>
                </div>
                <div className={style.orderDetailPrice}>
                  <div className={style.orderDetailPriceLeft}>
                    <span className={style.orderDetailText}>{item?.quantity / 1000 || 0}</span>
                    <div className={style.orderDetailPriceBox}>
                      {item?.quantity >= 2000 && (
                        <span className={style.orderDetailPriceHint}>
                          {Number((item?.price_penny / 100).toFixed(2))}х{item?.quantity / 1000} =
                        </span>
                      )}
                      <span className={style.orderDetailText}>
                        {item?.price_penny_total / 100 || 0}
                      </span>
                    </div>
                  </div>
                  <div className={style.removeBtnBox}>
                    {isEnabledDeleteDish &&
                    orderArray?.length > 1 &&
                    orderDetail?.payment_type === 'single' ? (
                      <span onClick={() => onDeleteDish(item)}>
                        <RemoveItem />
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {item.hasOwnProperty('modifiers') &&
                item?.modifiers.map((item) => {
                  const key1 = uuidv4();
                  return (
                    <div key={key1} className={style.orderDetailBody}>
                      <div>
                        <span
                          className={`${style.orderDetailAdditionText} ${style.orderDetailAdditionTitle}`}
                        >
                          {item?.name}
                        </span>
                      </div>
                      <div className={style.orderDetailPrice}>
                        <div className={style.orderDetailPriceLeft} />
                        <div className={style.removeBtnBox} />
                      </div>
                    </div>
                  );
                })}
              {item.hasOwnProperty('combo_items') &&
                item?.combo_items.map((comboItem) => {
                  const key2 = uuidv4();
                  return (
                    <div key={key2}>
                      <div className={style.orderDetailBody}>
                        <div className={style.orderDetailAdditionTitle}>
                          <span className={`${style.orderDetailAdditionText}`}>
                            {comboItem?.name}
                          </span>
                        </div>
                        <div className={style.orderDetailPrice}>
                          <div className={style.orderDetailPriceLeft}>
                            <span className={style.orderDetailAdditionText}>
                              {comboItem?.count}
                            </span>
                          </div>
                          <div className={style.removeBtnBox} />
                        </div>
                      </div>
                      {comboItem.hasOwnProperty('modifiers') &&
                        comboItem?.modifiers?.map((m) => {
                          const key3 = uuidv4();
                          return (
                            <div key={key3} className={style.orderDetailBody}>
                              <div>
                                <span
                                  className={`${style.orderDetailAdditionText} ${style.orderDetailAdditionTextMore}`}
                                >
                                  {m?.name}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
              {item.visitorName && (
                <div className={style.orderDetailBody}>
                  <span className={style.orderComboText}>Имя: {item.visitorName}</span>
                </div>
              )}
            </div>
          );
        })) ||
        null}
      {!isAdditionalOrder && (
        <div>
          <div className={style.orderDetailTopFooter}>
            <span className={style.orderDetailText}>Сумма заказа</span>
            <span className={style.orderDetailText}>
              {formatCurrency(orderDetail?.without_discount_sum)} руб.
            </span>
          </div>
          {!!orderDetail?.discount_sum && (
            <div className={style.orderDetailFooter}>
              <span className={style.orderDetailText}>Скидка</span>
              <span className={style.orderDetailText}>
                -{formatCurrency(orderDetail?.discount_sum)} руб.
              </span>
            </div>
          )}
          {!!orderDetail?.total_sum && !!orderDetail?.discount_sum && (
            <div className={style.orderDetailFooter}>
              <span className={style.orderDetailText}>Итого</span>
              <span className={style.orderDetailText}>
                {formatCurrency(orderDetail?.total_sum)} руб.
              </span>
            </div>
          )}
          {!!orderDetail?.paid_sum &&
            Number(orderDetail?.paid_sum) !== Number(orderDetail?.total_sum) &&
            orderDetail?.status !== 'Оплачено' && (
              <div className={style.orderDetailFooter}>
                <span className={`${style.orderDetailText} ${style.textRed}`}>
                  Частично оплачено
                </span>
                <span className={`${style.orderDetailText} ${style.textRed}`}>
                  {(orderDetail?.paid_sum / 100).toFixed(2)} руб.
                </span>
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default React.memo(OrderDetailDishes);
