import { createSlice } from '@reduxjs/toolkit';
import { toIsoString } from '../../utils/date';
import { statisticsAPI } from '../../api/organisation';

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    transactionsStatistics: { tips: [], payment: [] },
    isCurrentPeriod: false,
    from: '',
    to: '',
  },
  reducers: {
    setTransactionsStatistics: (state, data) => {
      state.transactionsStatistics = data.payload;
    },
    setIsCurrentPeriod: (state, data) => {
      state.isCurrentPeriod = data.payload;
    },
    setFrom: (state, data) => {
      state.from = data.payload;
    },
    setTo: (state, data) => {
      state.to = data.payload;
    },
  },
});

export const { setTransactionsStatistics, setIsCurrentPeriod, setFrom, setTo } =
  statisticsSlice.actions;

export default statisticsSlice.reducer;

export const takeTransactionsStatistics = () => async (dispatch, getState) => {
  try {
    const { organisation } = getState().profile.data;
    const { isCurrentPeriod, from, to } = getState().statistics;
    let fromFormated;
    let toFormated;
    if (isCurrentPeriod) {
      fromFormated = toIsoString(new Date(from)).slice(0, 10);
      toFormated = toIsoString(new Date(to)).slice(0, 10);
    } else {
      const curDate = new Date();
      fromFormated = toIsoString(new Date(curDate.getFullYear(), curDate.getMonth(), 1)).slice(
        0,
        10
      );
      toFormated = toIsoString(new Date(curDate.getFullYear(), curDate.getMonth() + 1, 0)).slice(
        0,
        10
      );
    }
    if (organisation) {
      const res = await statisticsAPI.getTransactionsStatistics(
        organisation.id,
        fromFormated,
        toFormated
      );
      dispatch(setTransactionsStatistics(res.data.data.statistic));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response, 'err.response');
  }
};
