import React from 'react';

const ArrowToRight = ({ color }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 12.5L19.5 12.5"
        stroke={color || '#CBD5E1'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 5.5L19.5 12.5L12.5 19.5"
        stroke={color || '#CBD5E1'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(ArrowToRight);
