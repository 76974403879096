// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Confirm_button__fC\\+jq {
    width: 49%;
}

.Confirm_title__MsU\\+f {
    color: #1E293B;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
}
.Confirm_message__I8xFd {
    color: #94A3B8;
    font-weight: 550;
    font-size: 14px;
}
.Confirm_field__xhSag {
    margin-bottom: 20px;
}
.Confirm_btns__89WIt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.Confirm_btnConfirm__m4c6O {
    width: 100%;
}
.Confirm_btns__89WIt.Confirm_confirm__JYT4J {
    grid-template-columns: 100px 100px;
}
.Confirm_confirmBtn__-nTOF {
    width: 100%;
}

.Confirm_wrapper__VyBqY {
    z-index: 999;
}

.Confirm_selectWrapper__55c8G {
    margin-top: 10px;
}

@media (max-width: 1000px) {
    .Confirm_title__MsU\\+f {
        font-size: 18px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/Confirm/Confirm.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI,kCAAkC;AACtC;AACA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".button {\n    width: 49%;\n}\n\n.title {\n    color: #1E293B;\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 20px;\n}\n.message {\n    color: #94A3B8;\n    font-weight: 550;\n    font-size: 14px;\n}\n.field {\n    margin-bottom: 20px;\n}\n.btns {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 10px;\n    margin-top: 10px;\n}\n.btnConfirm {\n    width: 100%;\n}\n.btns.confirm {\n    grid-template-columns: 100px 100px;\n}\n.confirmBtn {\n    width: 100%;\n}\n\n.wrapper {\n    z-index: 999;\n}\n\n.selectWrapper {\n    margin-top: 10px;\n}\n\n@media (max-width: 1000px) {\n    .title {\n        font-size: 18px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Confirm_button__fC+jq`,
	"title": `Confirm_title__MsU+f`,
	"message": `Confirm_message__I8xFd`,
	"field": `Confirm_field__xhSag`,
	"btns": `Confirm_btns__89WIt`,
	"btnConfirm": `Confirm_btnConfirm__m4c6O`,
	"confirm": `Confirm_confirm__JYT4J`,
	"confirmBtn": `Confirm_confirmBtn__-nTOF`,
	"wrapper": `Confirm_wrapper__VyBqY`,
	"selectWrapper": `Confirm_selectWrapper__55c8G`
};
export default ___CSS_LOADER_EXPORT___;
