import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setModalType } from '../../../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../../constants/modals';
import {
  getCurrentEmployeeBalanceSelector,
  getCurrentEmployeeSelector,
  getShowControlModalSelector,
} from '../../../../../redux/selectors/management';
import { fireUser, setShowControlModal } from '../../../../../redux/actions/management';
import Confirm from '../../../atoms/Confirm';
import { addAlert } from '../../../../../redux/actions/alerts';
import { getProfile } from '../../../../../api/profile';

const ConfirmFire = ({ closeModal }) => {
  const dispatch = useDispatch();
  const employeeId = useSelector(getCurrentEmployeeSelector);
  const employeeBalance = useSelector(getCurrentEmployeeBalanceSelector);
  const showControlModal = useSelector(getShowControlModalSelector);
  const onFire = async () => {
    const balance = employeeBalance === null ? 0 : String(Number(employeeBalance) / 100);
    const res = await dispatch(fireUser(employeeId));
    if (res) {
      if (!Number(balance)) {
        dispatch(addAlert('Сотрудник уволен', 'success'));
      } else if (balance < 30.99) {
        dispatch(
          addAlert('Сотрудник уволен, денежные средства поступили на счет заведения', 'success')
        );
        getProfile();
      } else {
        window.location.href = res?.data;
      }
      closeModal(null, true);
    }
  };

  const onCancel = async () => {
    if (showControlModal) {
      dispatch(setModalType(MODAL_TYPES.controlEmployee));
      dispatch(setShowControlModal(false));
    } else {
      closeModal(null, true);
    }
  };
  return (
    <Confirm
      title="Подтверждение"
      paragraphs={['Вы уверены, что хотите уволить сотрудника?']}
      onConfirm={onFire}
      onConfirmTitle="Уволить"
      onCancel={onCancel}
    />
  );
};

ConfirmFire.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmFire);
