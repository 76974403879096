import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import s from './Break.module.css';
import robot from '../../../../images/authorization/robot.png';
import Button from '../../atoms/Button';
import { getProfile } from '../../../../api/profile';
import { setIsTooManyRequest } from '../../../../redux/actions/session';

const Break = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(30);

  useEffect(() => {
    const id = setInterval(() => {
      setCount((v) => (v > 0 ? v - 1 : 0));
    }, 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (count < 1) {
      dispatch(setIsTooManyRequest(false));
      getProfile();
    }
  }, [count]);
  return (
    <div className={s.page}>
      <div className={s.robot}>
        <img src={robot} alt="" />
      </div>
      <div className={s.messageBlock}>
        <div className={s.message}>Упс! Нам нужно передохнуть :)</div>
        <Button title={count} />
      </div>
    </div>
  );
};

export default React.memo(Break);
