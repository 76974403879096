import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../../api/auth';

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState: {
    inquiryLoading: false,
    policyData: {
      text: '',
      organisation_id: '',
      created_at: '',
    },
  },
  reducers: {
    setInquiryLoading: (state, action) => {
      return {
        ...state,
        inquiryLoading: action.payload,
      };
    },
    setPolicyData: (state, action) => {
      return {
        ...state,
        policyData: action.payload,
      };
    },
  },
});

export const updatePolicyData = (data) => (dispatch) => {
  dispatch(setInquiryLoading(true));

  axios
    .put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/data/processing`,
      {
        text: data,
      },
      getAuthHeaders()
    )
    .then((resp) => {
      const data = resp.data;
      dispatch(setPolicyData(data));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const deletePolicyData = () => (dispatch) => {
  dispatch(setInquiryLoading(true));
  axios
    .delete(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/data/processing`, getAuthHeaders())
    .then((resp) => {
      dispatch(setPolicyData(resp));
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setInquiryLoading(false));
    });
};

export const { setInquiryLoading, setPolicyData } = inquirySlice.actions;

export default inquirySlice.reducer;
