import axios from 'axios';
import store from '../redux/store';
import { setDataProfile } from '../redux/actions/profile';
import { setIsTooManyRequest, setSession } from '../redux/actions/session';
import { getAuthHeaders } from './auth';
import { addAlert } from '../redux/actions/alerts';
import { setIsLoad } from '../redux/actions/preloader';

export const getProfile = async () => {
  const { session } = store.getState();
  const config = {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  if (session.token) {
    await axios
      .get(`${process.env.REACT_APP_TENQ_API}/api/v0/my/profile`, config)
      .then((response) => {
        const roleName = response.data?.data?.user?.roles[0]?.name;
        if (roleName === 'Владелец заведения' || roleName === 'Администратор') {
          store.dispatch(setDataProfile(response.data.data.user));
        } else {
          window.localStorage.clear();
        }
        store.dispatch(setIsLoad(false));
      })
      .catch((err) => {
        if (err.response?.status === 429) {
          store.dispatch(setIsTooManyRequest(true));
        } else if (err.response?.status === 401) {
          store.dispatch(
            setSession({
              autorized: false,
              token: '',
            })
          );
          window.localStorage.clear();
          console.log(err);
        } else {
          store.dispatch(addAlert('Возникла ошибка.'));
        }
      });
  }
};

export const profileAPI = {
  getAccumulation: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/accumulation`,
      getAuthHeaders()
    ),
  saveAccumulation: (id, name, sum) =>
    axios.post(
      `${
        process.env.REACT_APP_TENQ_API
      }/api/v0/owner/settings/accumulation?name=${name}&budget=${sum}${id ? `&id=${id}` : ''}`,
      {},
      getAuthHeaders()
    ),
  toggleAccumulation: (accumulation, status) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/accumulation/${accumulation}/toggle`,
      { status },
      getAuthHeaders()
    ),
  uploadAvatar: (file) => {
    const { session } = store.getState();
    const formData = new FormData();
    formData.append('avatar', file);
    const auth = {
      headers: {
        Authorization: `Bearer ${session.token}`,
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    return axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/my/profile`, formData, auth);
  },
  removeAvatar: () =>
    axios.delete(`${process.env.REACT_APP_TENQ_API}/api/v0/my/profile/avatar`, getAuthHeaders()),
  updateProfile: (name, surname, email, phone) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/profile`,
      { name, surname, email, phone },
      getAuthHeaders()
    ),
  sendFcmToken: (data) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/firebase/token`, data, getAuthHeaders()),
};
