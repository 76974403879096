// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckBox_wrapper__yxx1J {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.CheckBox_wrapper__yxx1J.CheckBox_border__X93wv {
    height: 40px;
    padding: 12px;
    border: 2px solid #F2F5FA;
    border-radius: 10px;
    box-sizing: border-box;
}
.CheckBox_check__oW2lD {
    width: 16px !important;
    height: 16px !important;
    border-radius: 4px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CheckBox_title__z8SIa {
    flex-grow: 1;
    font-weight: 550;
    font-size: 14px;
    color: #1E293B;
    line-height: 14px;
    padding-top: 4px;
    display: flex;
    gap: 10px;
}
.CheckBox_titleNoActive__KT-14 {
    color: #94A3B8;
}

@media (max-width: 713px) {
    .CheckBox_title__z8SIa {
        font-size: 12px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/CheckBox/CheckBox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,SAAS;AACb;AACA;IACI,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".wrapper {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n.wrapper.border {\n    height: 40px;\n    padding: 12px;\n    border: 2px solid #F2F5FA;\n    border-radius: 10px;\n    box-sizing: border-box;\n}\n.check {\n    width: 16px !important;\n    height: 16px !important;\n    border-radius: 4px;\n    margin-right: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n.title {\n    flex-grow: 1;\n    font-weight: 550;\n    font-size: 14px;\n    color: #1E293B;\n    line-height: 14px;\n    padding-top: 4px;\n    display: flex;\n    gap: 10px;\n}\n.titleNoActive {\n    color: #94A3B8;\n}\n\n@media (max-width: 713px) {\n    .title {\n        font-size: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CheckBox_wrapper__yxx1J`,
	"border": `CheckBox_border__X93wv`,
	"check": `CheckBox_check__oW2lD`,
	"title": `CheckBox_title__z8SIa`,
	"titleNoActive": `CheckBox_titleNoActive__KT-14`
};
export default ___CSS_LOADER_EXPORT___;
