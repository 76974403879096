import React from 'react';
import s from './CheckBox.module.css';

const CheckBox = ({
  icon,
  title,
  value,
  onChange,
  border = true,
  checkBoxBackground = ['#A3DD34', '#E2E8F0'],
  checkboxColor,
  containerStyle,
  titleStl = {},
}) => {
  return (
    <div className={`${s.wrapper} ${border && s.border}`} onClick={onChange}>
      <div
        className={
          value
            ? `${s.check} ${s.checkActive} ${containerStyle || ''}`
            : `${s.check} ${containerStyle || ''}`
        }
        style={{ background: value ? checkBoxBackground[0] : checkBoxBackground[1] }}
      >
        {value && (
          <svg
            width="8"
            height="6"
            viewBox="0 0 8 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 1L2.875 5L1 3.18182"
              stroke={checkboxColor || 'white'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      {title && (
        <div style={titleStl} className={value ? s.title : `${s.title} ${s.titleNoActive}`}>
          {title}
          {icon && <div>{icon}</div>}
        </div>
      )}
    </div>
  );
};

export default React.memo(CheckBox);
