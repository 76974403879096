import * as React from 'react';

const AlertSuccessIcon = (props) => (
  <svg width={30} height={31} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect y={0.5} width={30} height={30} rx={15} fill="#95CA30" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.774 12.263a.846.846 0 0 1-.033 1.185l-5.563 5.323a.82.82 0 0 1-1.17-.033l-2.781-2.974a.846.846 0 0 1 .031-1.186.82.82 0 0 1 1.17.032l2.214 2.367 4.962-4.748a.82.82 0 0 1 1.17.034Z"
      fill="#fff"
    />
  </svg>
);

export default React.memo(AlertSuccessIcon);
