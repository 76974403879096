import { createSlice } from '@reduxjs/toolkit';
import { profileAPI } from '../../api/profile';
import { addAlert } from './alerts';
import { setIsLoad } from './preloader';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: {},
    accumulation: null,
    accumulationStatus: localStorage?.accumulationStatus ? localStorage?.accumulationStatus : false,
  },
  reducers: {
    setDataProfile: (state, data) => {
      state.data = data.payload;
    },
    setAccumulation: (state, data) => {
      state.accumulation = data.payload;
    },
    setAccumulationStatus: (state, action) => {
      state.accumulationStatus = action.payload;
    },
  },
});

export const { setDataProfile, setAccumulation, setAccumulationStatus } = profileSlice.actions;

export default profileSlice.reducer;

export const getAccumulation = () => async (dispatch) => {
  try {
    const response = await profileAPI.getAccumulation();
    const accumulationStatus = response?.data?.data?.accumulation?.status;
    localStorage.setItem('accumulationStatus', accumulationStatus);
    dispatch(setAccumulationStatus(accumulationStatus));
    dispatch(setAccumulation(response.data.data.accumulation));
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const saveAccumulation = (id, name, sum) => async (dispatch) => {
  try {
    dispatch(addAlert('Изменения сохранены', 'success'));
    const response = await profileAPI.saveAccumulation(id, name, sum);
    const accumulationStatus = response?.data?.data?.accumulation?.status;
    localStorage.setItem('accumulationStatus', !!accumulationStatus);
    dispatch(setAccumulationStatus(!!accumulationStatus));
    dispatch(setAccumulation(response.data.data.accumulation));
    return response;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  }
};

export const toggleAccumulation = (status) => async (dispatch, getState) => {
  const accumulation = getState().profile.accumulation;
  try {
    const response = await profileAPI.toggleAccumulation(accumulation.id, status);
    dispatch(setAccumulationStatus(status));
    dispatch(
      setAccumulation({
        ...accumulation,
        status,
      })
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const uploadAvatar = (file) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoad(true));
    const response = await profileAPI.uploadAvatar(file);
    dispatch(addAlert('Изменения сохранены', 'success'));
    const profileData = getState()?.profile?.data;
    dispatch(
      setDataProfile({
        ...profileData,
        ...response?.data.data.user,
      })
    );
    dispatch(setIsLoad(false));
    return response;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    dispatch(setIsLoad(false));
  }
};

export const removeAvatar = () => async (dispatch, getState) => {
  try {
    const response = await profileAPI.removeAvatar().then(() => {
      const profileData = getState()?.profile?.data;
      dispatch(
        setDataProfile({
          ...profileData,
          avatar: null,
        })
      );
    });
    dispatch(addAlert('Изменения сохранены', 'success'));
    return response;
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  }
};

export const updateProfile = (name, surname, email, phone) => async (dispatch, getState) => {
  try {
    await profileAPI.updateProfile(name, surname, email, phone).then((res) => {
      const profileData = getState()?.profile?.data;
      if (!res) {
        throw new Error('No response');
      }
      dispatch(addAlert('Изменения сохранены', 'success'));
      setDataProfile({
        ...profileData,
        ...res?.data.data,
      });
    });
  } catch (err) {
    dispatch(addAlert(err?.response?.data?.errors?.email[0] || 'Возникла ошибка'));
  }
};

export const sendFcmToken = (data) => async () => {
  try {
    const response = await profileAPI.sendFcmToken(data);
    return response;
  } catch (err) {
    console.log(err);
  }
};
