import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import stl from '../TableBookingEditor/TableBookingEditor.module.css';
import CloseIcon from '../../../../../../assets/Icons/close';
import Button from '../../../../atoms/Button/Button';
import { setModalType } from '../../../../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../../../constants/modals';
import { getBookingById } from '../../../../../../redux/actions/booking';

const BookedTableInfo = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedBooking } = useSelector((state) => state.booking);
  const onClose = () => {
    closeModal(null, true);
  };
  const onOpenEditBooking = () => {
    dispatch(getBookingById(selectedBooking?.id, selectedBooking?.date));
  };

  const onRemoveBooked = () => {
    dispatch(setModalType(MODAL_TYPES.ConfirmRemoveReservation));
  };
  const formatPhoneNumber = (phoneNumber) => {
    // Применяем шаблон форматирования номера
    const match = phoneNumber.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    }
    return phoneNumber;
  };
  return (
    <div className={stl.bookingTable}>
      <div className={stl.bookingTableHead}>
        <span>Бронь стола</span>
        <div onClick={onClose} className={stl.bookingTableClose}>
          <CloseIcon color="#94A3B8" />
        </div>
      </div>
      <div className={stl.bookingZoneInfo}>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateLabel}>Дата</span>
          <span className={`${stl.bookingTableDateTitle}`}>{selectedBooking?.date}</span>
        </div>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateLabel}>Стол</span>
          <span className={`${stl.bookingTableDateTitle}`}>{selectedBooking?.desk_unit?.num}</span>
        </div>
      </div>
      <div className={stl.bookingZoneInfo}>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateLabel}>Начало брони</span>
          <span className={`${stl.bookingTableDateTitle}`}>{selectedBooking?.time_from}</span>
        </div>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateLabel}>Конец брони</span>
          <span className={`${stl.bookingTableDateTitle}`}>{selectedBooking?.time_to}</span>
        </div>
      </div>
      <div className={stl.bookingZoneInfo}>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateLabel}>Имя</span>
          <span className={`${stl.bookingTableDateTitle}`}>{selectedBooking?.name}</span>
        </div>
        <div className={stl.bookingTableDateBox}>
          <span className={stl.bookingTableDateLabel}>Телефон</span>
          <span className={`${stl.bookingTableDateTitle}`}>
            {selectedBooking?.phone && formatPhoneNumber(selectedBooking?.phone)}
          </span>
        </div>
      </div>
      <div className={stl.bookingActionBoxHor}>
        <Button onClick={onOpenEditBooking} title="Редактировать" className={stl.bookingEditBtn} />
        <Button onClick={onClose} title="ОК" className={stl.bookingBtn} />
      </div>
      <Button onClick={onRemoveBooked} title="Удалить бронь" className={stl.bookingRemoveBtn} />
    </div>
  );
};

BookedTableInfo.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(BookedTableInfo);
