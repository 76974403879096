import QRCodeStyling from 'qr-code-styling';
import { v4 as uuidv4 } from 'uuid';

const qrCode = new QRCodeStyling({
  dotsOptions: {
    color: '#5300a2',
    type: 'rounded',
  },
  cornersSquareOptions: {
    type: 'dot',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 0.5,
  },
});

export const getQrFile = async (baseUrl = 'https://form.tenq.ru/') => {
  const hash = uuidv4();

  qrCode.update({
    width: 300,
    height: 300,
    data: `${baseUrl}${hash}`,
  });
  const qrFile = await qrCode.getRawData('png');
  return [qrFile, hash];
};

export const isHexColor = (value) => /^#([a-fA-F0-9]{6})$/.test(value);
