import React from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import s from './InputTel.module.css';
import clear from '../../../../images/clear.svg';

const InputTel = ({
  value,
  onChange,
  onKeyDown,
  label,
  className,
  enableInputCode,
  codeClassName,
  phoneClassName,
  wrapClassName,
}) => {
  const onChangePhoneCode = (e) => {
    const code = e.target.value;
    if (code.search(/^\+[1-9][0-9]{0,2}$/) !== -1 || (code.length === 1 && code[0] === '+')) {
      onChange((phone) => ({
        ...phone,
        code,
      }));
    }
  };

  const onChangePhoneNumber = (e) => {
    onChange((phone) => ({
      ...phone,
      number: e.target.value,
    }));
  };

  const onClear = () => {
    onChange((phone) => ({
      ...phone,
      number: '',
    }));
  };

  return (
    <div className={className}>
      {!!label && <div className={s.label}>{label}</div>}
      <div className={`${s.wrapper} ${wrapClassName || ''}`}>
        <input
          disabled={!enableInputCode}
          maxLength={4}
          value={value.code}
          onChange={onChangePhoneCode}
          className={`${s.code} ${codeClassName || ''}`}
        />
        <InputMask
          mask="999 999 99 99"
          maskChar={null}
          value={value.number}
          onChange={onChangePhoneNumber}
          onKeyDown={onKeyDown}
          className={`${s.number} ${phoneClassName || ''}`}
        />
        {!!value.number && (
          <div onClick={onClear} className={s.clear}>
            <img src={clear} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(InputTel);

InputTel.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  codeClassName: PropTypes.string,
  phoneClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  wrapClassName: PropTypes.string,
};
