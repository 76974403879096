import get from 'lodash.get';

export const MISSING_EMULATOR_IDS_IN = [
  'is_enabled_btn_book_table',
  'is_enabled_btn_on_waiter_account',
  'is_enabled_btn_description_organisation',
];
export const MISSING_EMULATOR_IDS_OUT = [
  'is_enabled_btn_admin_waiting_time',
  'is_enabled_btn_waiter_waiting_time',
  'is_enabled_btn_wifi',
];

export const constructorDataParser = (data) => {
  const socialItems = () => {
    let currentData = {};
    const currentItems = get(data, 'soc_managements.soc_managements_items', []);
    const sortedItems = currentItems.sort((a, b) => a.position - b.position);
    sortedItems.forEach((current) => {
      currentData = {
        ...currentData,
        [current.name]: {
          ...current,
        },
      };
    });
    return currentData;
  };
  const buttons = () => {
    const currentItems = get(data, 'setting_buttons.setting_buttons_items', []);
    const sortedItems = currentItems.sort((a, b) => a.position - b.position);
    return sortedItems;
  };
  return {
    fon: get(data, 'fon', ''),
    logo: get(data, 'logo', ''),
    name: get(data, 'name_organisation', ''),
    description: get(data, 'description_organisation', ''),
    theme: get(data, 'color_theme', 'white'),
    socilaItems: socialItems(),
    use_app_link: get(data, 'use_app_link', false),
    buttons: buttons(),
    organisation_id: get(data, 'organisation_id', ''),
    dragVersion: get(data, 'soc_managements.drag_and_drop_version', ''),
    dragVersionButtons: get(data, 'setting_buttons.drag_and_drop_version', ''),
    rkeeper_station_active: get(data, 'rkeeper_station_active', false),
    on_waiter_account: get(data, 'on_waiter_account', ''),
    org_work_period: get(data, 'org_work_period', {}),
    org_break_period: get(data, 'org_break_period', {}),
    is_allowed_order_payment: get(data, 'is_allowed_order_payment', false),
    is_allowed_divide_order_into_positions: get(
      data,
      'is_allowed_divide_order_into_positions',
      false
    ),
    is_allowed_divide_order_into_parts: get(data, 'is_allowed_divide_order_into_parts', false),
  };
};

export const buttanDataCreator = (data) => {
  return {
    on_waiter_account: get(data, 'on_waiter_account', true),
    drag_and_drop_version: get(data, 'setting_buttons.drag_and_drop_version', 0),
    feedback: {
      dishes_feedback_is_allowed: get(data, 'dishes_feedback_is_allowed', false),
      employee_feedback_is_allowed: get(data, 'employee_feedback_is_allowed', false),
      organisation_feedback_is_allowed: get(data, 'organisation_feedback_is_allowed', false),
      yamaps_feedback_is_allowed: get(data, 'yamaps_feedback_is_allowed', false),
    },
    admin_waiting_time: get(data, 'admin_waiting_time', 0),
    wifi_password: get(data, 'wifi_password', ''),
    wifi_login: get(data, 'wifi_login', ''),
    loyal_card_selected: get(data, 'loyal_card_selected', null),
    waiter_waiting_time: get(data, 'waiter_waiting_time', 0),
    description_organisation: get(data, 'description_organisation', ''),
  };
};

export const socialLinkParser = (result, mainData) => {
  const draggableIndex = mainData.findIndex((curr) => curr.id === Number(result.draggableId));
  const droppableId = mainData.findIndex((curr) => curr.id === result.destination.index);
  const newData = [...mainData];
  newData[draggableIndex] = mainData[droppableId];
  newData[droppableId] = mainData[draggableIndex];
  const keysWithEntries = newData.reduce((acc, element) => {
    return {
      ...acc,
      [element.name]: element,
    };
  }, {});
  return keysWithEntries;
};
export const emulatorDataCreator = (data) => {
  const currentItems = get(data, 'setting_buttons.setting_buttons_items', []);
  const filteredData = currentItems.filter((element) => {
    if (!MISSING_EMULATOR_IDS_IN.includes(element.attribute)) {
      return {
        attribute: element.attribute,
      };
    }
  });
  return filteredData;
};

export const buttonsParser = (result, mainData) => {
  const items = Array.from(mainData);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  return items;
};

export const reorderDragButtons = (result, mainData) => {
  const items = Array.from(mainData);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  return items;
};

export const sortConstructor = (buttons) => {
  const customButtons = buttons.filter((b) => b.custom_button);

  const buttonsOrder = {
    is_enabled_btn_menu: 1,
    is_enabled_btn_pay_bill: 2,
    is_enabled_btn_on_waiter_account: 3,
    is_enabled_btn_get_loyalty_card: 4,
    is_enabled_btn_admin_waiting_time: 5,
    is_enabled_btn_waiter_waiting_time: 6,
    is_enabled_btn_feedback_allowed: 7 + customButtons?.length,
    is_enabled_btn_wifi: 8 + customButtons?.length,
  };

  return buttons
    .map((b) => {
      return {
        ...b,
        position: b.custom_button
          ? 6 + Number(b.attribute.split('is_enabled_btn_custom_waiting_time_')[1])
          : buttonsOrder[b.attribute] || b.position,
      };
    })
    .sort((a, b) => a.position - b.position);
};
