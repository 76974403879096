import { createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { historyAPI } from '../../api/history';
import { formatDate } from '../../utils/date';
import { setIsLoad } from './preloader';

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    transactions: [],
    selectedFilterUsers: [],
    filterDate: {
      isCurrentPeriod: false,
      from: '',
      to: '',
    },
    isFetching: false,
  },
  reducers: {
    setTransactions: (state, data) => {
      state.transactions = data.payload;
    },
    setNewPageTransactions: (state, data) => {
      for (const key in data.payload.data) {
        if (state.transactions.data.hasOwnProperty(key)) {
          state.transactions.data[key] = [
            ...state.transactions.data[key],
            ...data.payload.data[key],
          ];
        } else {
          state.transactions.data[key] = data.payload.data[key];
        }
      }
      state.transactions.pagination = data.payload.pagination;
    },
    setIsCurrentPeriod: (state, data) => {
      state.filterDate.isCurrentPeriod = data.payload;
    },
    setFrom: (state, data) => {
      state.filterDate.from = data.payload;
    },
    setTo: (state, data) => {
      state.filterDate.to = data.payload;
    },
    setIsFetching: (state, data) => {
      state.isFetching = data.payload;
    },
    setSelectedFilterUsers: (state, data) => {
      state.selectedFilterUsers = data.payload;
    },
  },
});

export const {
  setTransactions,
  setIsCurrentPeriod,
  setFrom,
  setTo,
  setIsFetching,
  setNewPageTransactions,
  setSelectedFilterUsers,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;

export const getTransactions = (type, period, from, to, userId, user_ids) => async (dispatch) => {
  dispatch(setIsLoad(true));
  let dateParams;
  if (period) {
    dateParams = `date=${period}`;
  } else if (from && to) {
    dateParams = `date[from]=${formatDate(from, 'dd.mm.yyyy')}&date[to]=${formatDate(
      to,
      'dd.mm.yyyy'
    )}`;
  }

  try {
    const response = await historyAPI.getTransactions(type, dateParams, userId, user_ids);
    dispatch(setTransactions(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getNewPageTransactions =
  (type, period, from, to, id, user_ids) => async (dispatch, getState) => {
    const { transactions, isFetching } = getState().transactions;
    const { pagination } = transactions;
    let dateParams;
    if (period) {
      dateParams = `date=${period}&page=${pagination?.current_page + 1}`;
    } else if (from && to) {
      dateParams = `date[from]=${formatDate(from, 'dd.mm.yyyy')}&date[to]=${formatDate(
        to,
        'dd.mm.yyyy'
      )}&page=${pagination?.current_page + 1}`;
    }

    try {
      if (!isFetching && pagination != null && pagination?.current_page < pagination?.total_pages) {
        dispatch(setIsFetching(true));
        const response = await historyAPI.getTransactions(type, dateParams, id, user_ids);
        dispatch(setNewPageTransactions(response.data.data));
        dispatch(setIsFetching(false));
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  };

export const uploadCsv = (type, period, from, to, employee, user_ids) => async () => {
  let dateParams;
  if (period !== 'customPeriod') {
    dateParams = `date=${period}`;
  } else if (from && to) {
    dateParams = `date[from]=${formatDate(from, 'dd.mm.yyyy')}&date[to]=${formatDate(
      to,
      'dd.mm.yyyy'
    )}`;
  }
  try {
    const response = await historyAPI.uploadCsv(type, dateParams, employee, user_ids);
    const contentType = response.headers['content-type'];
    const file = new Blob([response.data], { type: contentType });
    const name = 'file.csv';
    saveAs(file, name);
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
