import axios from 'axios';
import store from '../redux/store';
import { setOrganisationDashboard, setOrganisationUsers } from '../redux/actions/organisation';
import { setIsLoad } from '../redux/actions/preloader';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

export const getStatistic = () => {
  const { profile } = store.getState();
  const { session } = store.getState();
  const auth = {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  if (profile.data.organisation) {
    store.dispatch(setIsLoad(true));
    axios
      .get(
        `${process.env.REACT_APP_TENQ_API}/api/v0/organisations/${profile.data.organisation.id}/statistic/dashboard`,
        auth
      )
      .then((res) => {
        store.dispatch(setOrganisationDashboard(res.data.data.statistic));
        store.dispatch(setIsLoad(false));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const getUsers = () => {
  const { profile } = store.getState();
  const { session } = store.getState();
  const auth = {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  if (profile.data.organisation) {
    axios
      .get(
        `${process.env.REACT_APP_TENQ_API}/api/v0/organisations/${profile.data.organisation.id}/statistic/users`,
        auth
      )
      .then((res) => {
        store.dispatch(setOrganisationUsers(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const organisationAPI = {
  initOutputMoney: (amount) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/payment/payout?order[amount]=${amount}&payout_type=external_card`,
      {},
      getAuthHeaders()
    ),
  addPersonal: (data) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/personal/invite`, data, getAuthHeaders()),
  addPersonalWithQr: (data) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/`, data, getAuthHeaders()),
  acceptCall: (visitor_call_id, hash, user_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/acceptAdminCall`,
      { visitor_call_id, hash, user_id },
      getAuthHeaders()
    ),
};

export const statisticsAPI = {
  getTransactionsStatistics: (organisationId, from, to) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/organisations/${organisationId}/statistic/transactions?from=${from}&to=${to}`,
      getAuthHeaders()
    ),
};
