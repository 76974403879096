import React, { useState } from 'react';
import s from './InputSelect.module.css';
import useOutsideDetect from '../../../hooks/useOutsideDetect';
import ArrowDown from '../../../../assets/Icons/arrowDown';

export const InputSelect = ({
  defaultValue,
  value,
  options,
  top = '70px',
  bottom = 'none',
  label,
  className,
  selecter,
  disabled,
  textStl,
  emptyText,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const popUpRef = useOutsideDetect(setShowOptions);
  return (
    <div ref={popUpRef} className={`${s.wrapper} ${className}`}>
      {!!label && <div className={s.label}>{label}</div>}
      <div
        className={`${s.button} ${textStl}`}
        onClick={() => !disabled && setShowOptions((v) => !v)}
      >
        <div className={s.text}>{value || defaultValue}</div>
        {(!disabled && (
          <div className={`${s.icon} ${showOptions && s.dropdownArrowActive}`}>
            <ArrowDown />
          </div>
        )) ||
          null}
      </div>
      {showOptions && (
        <div className={s.options} style={{ top, bottom }}>
          {options?.length ? (
            options.map((option, i) => {
              return (
                <div
                  className={option === value ? `${s.option} ${s.active}` : s.option}
                  onClick={() => {
                    setShowOptions(!showOptions);
                    selecter(i, option);
                  }}
                  key={i}
                >
                  {option}
                </div>
              );
            })
          ) : (
            <div className={`${s.option} ${s.empty}`}>{emptyText}</div>
          )}
        </div>
      )}
    </div>
  );
};
