import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from '../ManagementFilter/ManagementFilter.module.css';
import filterIcon from '../../../../images/filterIcon.svg';
import Button from '../../atoms/Button';
import ButtonCancel from '../../atoms/ButtonCancel';
import CheckBox from '../../atoms/CheckBox';

const PersonalFilterInner = ({ onCancel, onSave, defaultPositionsFilterData }) => {
  const [positions, setPositions] = useState(defaultPositionsFilterData);

  const handlePositionChange = ({ position, checked }) => {
    let newPosition;

    if (position === 'Все') {
      newPosition = positions.map((item) => ({
        ...item,
        checked: !checked,
      }));
    } else {
      newPosition = positions.map((item) => {
        if (item.position === 'Все') {
          return {
            ...item,
            checked: false,
          };
        }
        if (item.position === position) {
          return {
            ...item,
            checked: !checked,
          };
        }
        return { ...item };
      });
    }
    if (newPosition.filter((item) => item.checked).length === newPosition.length - 1) {
      newPosition[0].checked = true;
    }
    setPositions(newPosition);
  };
  useEffect(() => {
    setPositions(defaultPositionsFilterData);
  }, [defaultPositionsFilterData]);

  return (
    <div className={s.wraper}>
      <div className={s.header}>
        <div>Фильтры</div>
        <img src={filterIcon} alt="" />
      </div>
      <div className={s.label}>Выберите позиции</div>
      <>
        <div className={s.positions}>
          {positions.map((item, i) => (
            <CheckBox
              title={item.position}
              value={item.checked}
              onChange={() => handlePositionChange(item)}
              key={i}
            />
          ))}
        </div>
      </>
      <div className={s.btns}>
        <Button title="Сохранить" onClick={() => onSave(positions)} />
        <ButtonCancel onClick={onCancel} />
      </div>
    </div>
  );
};

PersonalFilterInner.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  defaultPositionsFilterData: PropTypes.any,
};

export default React.memo(PersonalFilterInner);
