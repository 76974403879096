import React, { useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styles from './modal.module.css';
import { setModal, setModalType } from '../../../../redux/actions/modal';
import { getModal, getModalType } from '../../../../redux/selectors/modal';
import { MODALS } from '../../../constants/modals';
import { useScrollFromWindow } from '../../../hooks/useScrollFromWindow';

Modal.setAppElement('#root');

const AppModal = () => {
  const setScrollEl = useScrollFromWindow();
  const dispatch = useDispatch();
  const isOpen = useSelector(getModal);
  const currentModal = useSelector(getModalType);
  const isLoad = useSelector((state) => state.preloader.isLoad);

  const currentModalComponent = useMemo(() => {
    if (currentModal) {
      return MODALS[currentModal];
    }
  }, [currentModal]);

  const Component = useMemo(() => {
    if (currentModal && currentModalComponent) {
      return currentModalComponent.component;
    }
  }, [currentModal, currentModalComponent]);

  const closeAction = useMemo(() => {
    if (currentModal && currentModalComponent) {
      return currentModalComponent.closeAction;
    }
  }, [currentModal, currentModalComponent]);

  const closeModal = (e, canClose = false) => {
    if (!(currentModalComponent && currentModalComponent.closeWithConfirmation) || canClose) {
      dispatch(setModal(false));
      dispatch(setModalType(''));
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <Modal
      portalClassName="modal_react"
      contentRef={setScrollEl}
      style={{
        content: {
          backgroundColor: isLoad ? 'transparent' : '#fff',
        },
      }}
      overlayClassName={
        currentModalComponent && currentModalComponent.overlayClass
          ? `${currentModalComponent.overlayClass} ${styles.overlay}`
          : styles.overlay
      }
      className={
        currentModalComponent && currentModalComponent.contentClass
          ? `${currentModalComponent.contentClass} ${styles.content}`
          : styles.content
      }
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
    >
      {currentModalComponent && (
        <div style={{ backgroundColor: '#fff', padding: 20 }}>
          {currentModalComponent.title && (
            <h2 className={styles.addTableTitle}>{currentModalComponent.title}</h2>
          )}
          <Component closeModal={closeAction && closeModal} currentModal={currentModal} />
        </div>
      )}
    </Modal>
  );
};

export default React.memo(AppModal);
