import { useEffect, useRef } from 'react';

const useOutsideDetect = (setterFunction) => {
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target) && setterFunction) {
      setterFunction(false, event);
    }
  };
  useEffect(() => {
    document.body.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.body.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);
  return ref;
};

export default useOutsideDetect;
