import axios from 'axios';
import { getAuthHeaders } from './auth';

export const integrationRKeeperAPI = {
  callRkeeperCommand: (rkeeper_command, props = {}) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/ext/rest/service`,
      {
        rkeeper_command,
        ...props,
      },
      getAuthHeaders()
    ),
  getStations: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/stations`, getAuthHeaders()),
  getTables: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/import/tables`, getAuthHeaders()),
  getDiscounts: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/import/discounts`,
      getAuthHeaders()
    ),
  getDesksWithoutQr: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/settings/new/desks`, getAuthHeaders()),
  importEmployees: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/rkeeper/import/employees`,
      getAuthHeaders()
    ),
  getEmployeesFromTemp: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/getTempFileForImportUsers`,
      getAuthHeaders()
    ),
  saveImportEmployees: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/saveImportUsers`,
      { data },
      getAuthHeaders()
    ),
  saveQrMultiple: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/saveQrCodeMultiple`,
      data,
      getAuthHeaders()
    ),
  saveCurrentStation: (body) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/restaurant-station/save`,
      body,
      getAuthHeaders()
    ),
  saveCurrentCurrency: (cash_currency_id, tenq_currency_id) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/rkeeper/currency/save`,
      { cash_currency_id, tenq_currency_id },
      getAuthHeaders()
    ),
  saveCurrentCashier: (dishId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/settings/main/organisation`,
      { rkeeper_custom_composition_dish_ident: dishId },
      getAuthHeaders()
    ),
  getCashierRkeeperEmployees: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/getCashierRkeeperEmployees`,
      getAuthHeaders()
    ),
  getOrgIp: () =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/business-automation/v1/web/settings/get`,
      {},
      getAuthHeaders()
    ),
  updateOrgIp: (ip, port) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/business-automation/v1/web/settings/update`,
      {
        business_automation_type: 'rkeeper',
        connection_method: 'ip_port',
        ip,
        port,
      },
      getAuthHeaders()
    ),
};
