import React from 'react';
import { useDispatch } from 'react-redux';
import style from '../../organisms/Modals/OrderDetails/orderDetail.module.css';
import Button from '../../atoms/Button';
import ButtonCancel from '../../atoms/ButtonCancel';
import { callRkeeperCommand } from '../../../../redux/actions/integrationRkeeper';

const OrderDetailActionBox = ({
  orderDetail,
  onOrderHandler,
  onOrderAdditionHandler,
  onCancelOrder,
  onPaidInCashOrder,
  onCloseTakeawayOrder,
  partPay,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={style.actionBox}>
      {orderDetail?.status === 'Новое' && (
        <div className={style.orderDetailBtnBox}>
          <Button title="Принять" onClick={() => onOrderHandler('confirm')} />
          <ButtonCancel title="Отклонить" onClick={() => onCancelOrder('Заказ')} />
        </div>
      )}
      {orderDetail?.status === 'Дозаказ' && (
        <div className={style.orderDetailBtnBox}>
          <Button title="Принять" onClick={() => onOrderAdditionHandler('confirm')} />
          <ButtonCancel title="Отклонить" onClick={() => onOrderAdditionHandler('decline')} />
        </div>
      )}
      {orderDetail?.status !== 'Новое' && orderDetail?.status !== 'Дозаказ' && (
        <div className={style.orderDetailBtnBoxColum}>
          {orderDetail?.status === 'Принято' &&
          !partPay &&
          !orderDetail.is_takeaway_order &&
          orderDetail?.payment_type === 'single' ? (
            <Button
              onClick={() =>
                orderDetail?.is_order_can_pay
                  ? onPaidInCashOrder()
                  : dispatch(
                      callRkeeperCommand('sync_order', true, null, { order_id: orderDetail?.id })
                    )
              }
              className={style.rowButton}
              title={orderDetail?.is_order_can_pay ? 'Оплата наличными' : 'Отсканировано'}
            />
          ) : null}
          {orderDetail?.status === 'Принято' && !partPay ? (
            <Button
              className={`${style.rowButton} ${
                ((orderDetail?.payment_type !== 'single' || orderDetail.is_takeaway_order) &&
                  style.btnFull) ||
                ''
              }`}
              title="Отменить заказ"
              isDanger
              onClick={() => onCancelOrder('Заказ')}
            />
          ) : null}

          {orderDetail?.status === 'Оплачено' && orderDetail?.is_takeaway_order ? (
            <Button
              className={style.rowButton}
              onClick={() =>
                orderDetail?.is_order_can_pay
                  ? onCloseTakeawayOrder()
                  : dispatch(
                      callRkeeperCommand('sync_order', true, null, { order_id: orderDetail?.id })
                    )
              }
              title={orderDetail?.is_order_can_pay ? 'Заказ передан посетителю' : 'Отсканировано'}
            />
          ) : null}
        </div>
      )}
      {orderDetail?.status === 'Дозаказ' && !orderDetail.is_takeaway_order ? (
        <Button
          onClick={() => onCancelOrder('Дозаказ')}
          isDanger
          className={style.button}
          title="Отменить заказ"
        />
      ) : null}
    </div>
  );
};

export default React.memo(OrderDetailActionBox);
