import { createSelector } from 'reselect';

const integrationRkeeperSelector = (state) => state.integrationRkeeper;

export const getIsSuccessConnection = createSelector(
  integrationRkeeperSelector,
  (state) => state.isSuccessConnection
);
export const getImportedRestaurants = createSelector(
  integrationRkeeperSelector,
  (state) => state.importedRestaurants
);
export const getIsSuccessIntegration = createSelector(
  integrationRkeeperSelector,
  (state) => state.isSuccessIntegration
);
export const getImportedCurrencies = createSelector(
  integrationRkeeperSelector,
  (state) => state.importedCurrencies
);
export const getIsSuccessSaveCurrency = createSelector(
  integrationRkeeperSelector,
  (state) => state.isSuccessSaveCurrency
);
export const getIsSuccessSaveCashier = createSelector(
  integrationRkeeperSelector,
  (state) => state.isSuccessSaveCashier
);
export const getIsSuccessGenerateQrCodes = createSelector(
  integrationRkeeperSelector,
  (state) => state.isSuccessGenerateQrCodes
);

export const getImportedEmployees = createSelector(
  integrationRkeeperSelector,
  (state) => state.importedEmployees
);

export const getSelectedImportedEmployee = createSelector(
  integrationRkeeperSelector,
  (state) => state.selectedImportedEmployee
);

export const getDesksWithoutQrs = createSelector(
  integrationRkeeperSelector,
  (state) => state.desksWithoutQr
);

export const getCashiers = createSelector(
  integrationRkeeperSelector,
  (state) => state.cashierRkeeperEmployees
);
