import { createSelector } from 'reselect';

const modalSelector = (state) => state.modal;
export const getModal = createSelector(modalSelector, (state) => state.open);
export const getModalType = createSelector(modalSelector, (state) => state.type);
export const getCurrentParamsForModalRedirection = createSelector(
  modalSelector,
  (state) => state.constructoRedirectionModals
);
export const getShowFilterValue = createSelector(modalSelector, (state) => state.showFilterValue);
