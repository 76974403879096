import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import s from '../InviteEmployee/InviteEmployee.module.css';
import stl from './EditBreakPeriod.module.css';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import { updateBreakPeriod } from '../../../../../redux/actions/constructor';
import { addAlert } from '../../../../../redux/actions/alerts';
import { weekDays } from '../../../../../utils/date';

const EditBreakPeriod = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { start, end, days, active } = useSelector(
    (state) => state.constructorCreator?.breakPeriod
  );
  const [oneTime, setOneTime] = useState(days.length === 0);
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);
  const [period, setPeriod] = useState(weekDays);

  const updateCheckedStatus = (selectedDays) => {
    const updatedPeriod = period.map((day) => {
      if (selectedDays.includes(day.weekday)) {
        return { ...day, checked: true };
      }
      return { ...day, checked: false };
    });
    setPeriod(updatedPeriod);
  };
  const onCHeckDay = (id) => {
    const updatedPeriod = period.map((day) => {
      if (day.weekday === id) {
        return { ...day, checked: !day?.checked };
      }
      return { ...day };
    });
    setPeriod(updatedPeriod);
  };
  useEffect(() => {
    updateCheckedStatus(days);
  }, []);
  const isTimeValid = (type) => {
    const startTimes = String(startTime).split(':').join('');
    const endTimes = String(endTime).split(':').join('');
    if (startTimes.length === 4 && endTimes === '0000') {
      return true;
    }
    if (type === 'start' && startTimes.length === 4 && Number(startTimes) < Number(endTimes)) {
      return true;
    }
    if (type === 'end' && endTimes.length === 4 && Number(startTimes) < Number(endTimes)) {
      return true;
    }
    return (
      startTimes.length === 4 && endTimes.length === 4 && Number(startTimes) < Number(endTimes)
    );
  };
  const onSaveChanges = async () => {
    if (!isTimeValid()) {
      dispatch(addAlert('Введите корректное значение времени', 'error'));
      return;
    }
    if (period.every((item) => !item.checked) && !oneTime) {
      dispatch(addAlert('Выберите дни для повтора перерыва', 'error'));
      return;
    }

    // if(!oneTime && period)
    const body = {
      active,
      start: startTime,
      end: endTime,
      days: oneTime ? [] : period.filter((day) => day.checked).map((day) => day.weekday),
    };
    dispatch(updateBreakPeriod(body));
    closeModal();
  };
  const startsWithTwo = useMemo(() => {
    return {
      startTime: startTime && startTime[0] === '2',
      endTime: endTime && endTime[0] === '2',
    };
  }, [startTime, endTime]);

  const formatChars = useMemo(
    () => ({
      start: {
        0: '[0-2]',
        1: startsWithTwo.startTime ? /[0-3]/ : /[0-9]/,
        2: '[0-5]',
        3: '[0-9]',
        4: startsWithTwo.startTime ? /[0-3]/ : /[0-9]/,
      },
      end: {
        0: '[0-2]',
        1: startsWithTwo.endTime ? /[0-3]/ : /[0-9]/,
        2: '[0-5]',
        3: '[0-9]',
        4: startsWithTwo.endTime ? /[0-3]/ : /[0-9]/,
      },
    }),
    [startsWithTwo]
  );

  return (
    <div>
      <div className={`${s.title} ${s.field}`}>Технический перерыв</div>
      <div className={stl.inputBox}>
        <div>
          <p className={stl.sectionLabel}>Время начала</p>
          <InputMask
            maskChar=""
            onChange={(e) => setStartTime(e.target.value)}
            value={startTime || ''}
            className={`${stl.inpMask} ${!isTimeValid('start') && stl.notCorrectTime}`}
            mask="01:23"
            placeholder="__:__"
            formatChars={formatChars.start}
          />
        </div>
        <div>
          <p className={stl.sectionLabel}>Время окончания</p>
          <InputMask
            maskChar=""
            onChange={(e) => setEndTime(e.target.value)}
            value={endTime || ''}
            mask="01:23"
            placeholder="__:__"
            formatChars={formatChars.end}
            className={`${stl.inpMask} ${!isTimeValid('end') && stl.notCorrectTime}`}
          />
        </div>
      </div>
      <div>
        <p className={stl.sectionLabel}>Периодичность</p>
        <div className={stl.daysModeBox}>
          <div
            onClick={() => setOneTime(true)}
            className={`${stl.btnCheckbox} ${oneTime && stl.btnCheckActive}`}
          >
            Однократно
          </div>
          <div
            onClick={() => setOneTime(false)}
            className={`${stl.btnCheckbox} ${!oneTime && stl.btnCheckActive}`}
          >
            Выбрать дни
          </div>
        </div>
        {!oneTime && (
          <div className={stl.daysBox}>
            {period.map((day) => {
              return (
                <div
                  onClick={() => onCHeckDay(day?.weekday)}
                  key={day.weekday}
                  className={`${stl.btnCheckbox} ${stl.btnCheckboxText} ${
                    day?.checked && stl.btnCheckActive
                  }`}
                >
                  {day?.weekdayShort}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div className={stl.btnBox}>
        <Button onClick={onSaveChanges} title="Сохранить" />
        <ButtonCancel onClick={closeModal} />
      </div>
    </div>
  );
};

export default React.memo(EditBreakPeriod);
