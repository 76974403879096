// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VisitorCall_headerTitle__RLUSf {
  font-weight: 600;
  border-radius: 12px;
  font-size: 18px;
  color: #334155;
  margin-bottom: 15px;
}

.VisitorCall_timerWrapper__U-RhL {
  padding: 0 20px;
}

.VisitorCall_timer__\\+aTwj {
  border: 2px solid #F2F5FA;
  border-radius: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 20px;
}

.VisitorCall_timerText__f\\+a2h {
  padding: 10px 0;
  margin-bottom: 0;
  color: #1E293B;
  font-size: 26px;
  font-weight: 550;
}

.VisitorCall_progressWrapper__nnwCi {
  position: relative;
  margin-top: 15px;
}

.VisitorCall_progressBar__lqwzD {
  border: 2px solid #F2F5FA;
  height: 12px;
  border-radius: 20px;
}

.VisitorCall_progressBarFill__kFW58 {
  transition: width cubic-bezier(0.6, 0.04, 0.98, 0.335) .3s;
  position: absolute;
  top: 1px;
  left: 0;
  height: 10px;
  background: #FF6262;
  box-shadow: 20px 20px 20px -10px rgba(139, 139, 139, 0.1);
  border-radius: 20px;
}

.VisitorCall_description__ooyxi {
  font-size: 14px;
  color: #334155;
  margin-bottom: 15px;
}

.VisitorCall_button__Jk\\+X4 {
  width: 100%;
  margin-top: 15px;
}

.VisitorCall_buttons__bDrEX {
  display: flex;
  gap: 10px;
}

.VisitorCall_buttonGrey__U4zMx {
  background: #F2F5FA;
  color: #1E293B;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/VisitorCall/VisitorCall.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,0DAA0D;EAC1D,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,YAAY;EACZ,mBAAmB;EACnB,yDAAyD;EACzD,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".headerTitle {\n  font-weight: 600;\n  border-radius: 12px;\n  font-size: 18px;\n  color: #334155;\n  margin-bottom: 15px;\n}\n\n.timerWrapper {\n  padding: 0 20px;\n}\n\n.timer {\n  border: 2px solid #F2F5FA;\n  border-radius: 66px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 20px 0 20px;\n}\n\n.timerText {\n  padding: 10px 0;\n  margin-bottom: 0;\n  color: #1E293B;\n  font-size: 26px;\n  font-weight: 550;\n}\n\n.progressWrapper {\n  position: relative;\n  margin-top: 15px;\n}\n\n.progressBar {\n  border: 2px solid #F2F5FA;\n  height: 12px;\n  border-radius: 20px;\n}\n\n.progressBarFill {\n  transition: width cubic-bezier(0.6, 0.04, 0.98, 0.335) .3s;\n  position: absolute;\n  top: 1px;\n  left: 0;\n  height: 10px;\n  background: #FF6262;\n  box-shadow: 20px 20px 20px -10px rgba(139, 139, 139, 0.1);\n  border-radius: 20px;\n}\n\n.description {\n  font-size: 14px;\n  color: #334155;\n  margin-bottom: 15px;\n}\n\n.button {\n  width: 100%;\n  margin-top: 15px;\n}\n\n.buttons {\n  display: flex;\n  gap: 10px;\n}\n\n.buttonGrey {\n  background: #F2F5FA;\n  color: #1E293B;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerTitle": `VisitorCall_headerTitle__RLUSf`,
	"timerWrapper": `VisitorCall_timerWrapper__U-RhL`,
	"timer": `VisitorCall_timer__+aTwj`,
	"timerText": `VisitorCall_timerText__f+a2h`,
	"progressWrapper": `VisitorCall_progressWrapper__nnwCi`,
	"progressBar": `VisitorCall_progressBar__lqwzD`,
	"progressBarFill": `VisitorCall_progressBarFill__kFW58`,
	"description": `VisitorCall_description__ooyxi`,
	"button": `VisitorCall_button__Jk+X4`,
	"buttons": `VisitorCall_buttons__bDrEX`,
	"buttonGrey": `VisitorCall_buttonGrey__U4zMx`
};
export default ___CSS_LOADER_EXPORT___;
