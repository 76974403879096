import React from 'react';
import stl from './CustomRadio.module.css';

const CustomRadio = ({ title, onClick, checked, id, radioRight }) => {
  return (
    <div
      className={`${stl.radioBtnBox} ${(radioRight && stl.radioBtnBoxRight) || ''}`}
      onClick={() => onClick(id)}
    >
      {!radioRight && (
        <input readOnly checked={checked} type="radio" className={stl.radioBtn} id={id} />
      )}
      {!radioRight && <label htmlFor={id} />}
      <div className={stl.radioBtText}>{title}</div>
      {radioRight && (
        <input readOnly checked={checked} type="radio" className={stl.radioBtn} id={id} />
      )}
      {radioRight && <label htmlFor={id} />}
    </div>
  );
};

export default React.memo(CustomRadio);
