import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import stl from './SettingMenuView.module.css';
import Button from '../../../atoms/Button';
import CustomRadio from '../../../atoms/CustomRadio';
import { updateMenuView } from '../../../../../redux/actions/settingMenu';

const SettingMenuView = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { is_enabled_menu_maps_pictures } = useSelector(
    (state) => state.organisation?.data?.info?.settings
  );
  const [withImg, setWithImg] = useState(is_enabled_menu_maps_pictures);
  const onUpdateMenuView = () => {
    const body = {
      is_enabled_menu_maps_pictures: withImg,
    };
    dispatch(updateMenuView(body));
    closeModal();
  };
  return (
    <>
      <div>
        <p className={stl.menuViewHint}>
          Вы можете выбрать меню с изображениями категорий и блюд или без них
        </p>
        <div className={stl.radioBox}>
          <CustomRadio
            title="С изображениями"
            checked={withImg}
            onClick={() => setWithImg(!withImg)}
          />
          <CustomRadio
            title="Без изображений"
            checked={!withImg}
            onClick={() => setWithImg(!withImg)}
          />
        </div>
        <div className={stl.btnBox}>
          <Button title="Сохранить" onClick={onUpdateMenuView} />
          <Button isCansel title="Отмена" onClick={closeModal} />
        </div>
      </div>
    </>
  );
};

export default React.memo(SettingMenuView);
