import axios from 'axios';
import { getAuthHeaders } from './auth';

export const bookingApi = {
  getBookingData: (data) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/booking?date=${data}`,
      getAuthHeaders()
    ),
  createBookingTable: (data) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/booking`, data, getAuthHeaders()),
  removeBooked: (bookingId) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/booking/${bookingId}`,
      getAuthHeaders()
    ),
  getBookingById: (bookingId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/booking/${bookingId}/edit`,
      getAuthHeaders()
    ),
  updateReservation: (data, bookingId) =>
    axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/booking/${bookingId}`,
      data,
      getAuthHeaders()
    ),
};
