// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CameraScanner_scanVideoContainer__DiP3A {
  border-radius: 12px;
  width: 100%;
}
.CameraScanner_scanWrapper__ma2aj {
  position: relative;
  margin-bottom: 5px;
}

.CameraScanner_scanInterface__dwVoE {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0,0,0,.3);
}

.CameraScanner_scanInterface__dwVoE p {
  text-align: center; 
  padding-bottom: 10px;
}

.CameraScanner_scanBtn__qFxgq {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(255,255,255,.3);
  border-radius: 12px;
  margin-left: 10px;
  margin-top: 10px;
}

.CameraScanner_scanBtn__qFxgq svg {
  transform: rotate(180deg);
}

.CameraScanner_markingBtns__bJJ4k {
  margin: 0 0 0 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  row-gap: 10px;
}

.CameraScanner_markingBtns__bJJ4k p {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/App/features/molecules/CameraScanner/CameraScanner.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,gCAAgC;EAChC,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".scanVideoContainer {\n  border-radius: 12px;\n  width: 100%;\n}\n.scanWrapper {\n  position: relative;\n  margin-bottom: 5px;\n}\n\n.scanInterface {\n  border-radius: 12px;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 98%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  background: rgba(0,0,0,.3);\n}\n\n.scanInterface p {\n  text-align: center; \n  padding-bottom: 10px;\n}\n\n.scanBtn {\n  width: 40px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: none;\n  background: rgba(255,255,255,.3);\n  border-radius: 12px;\n  margin-left: 10px;\n  margin-top: 10px;\n}\n\n.scanBtn svg {\n  transform: rotate(180deg);\n}\n\n.markingBtns {\n  margin: 0 0 0 10px;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  row-gap: 10px;\n}\n\n.markingBtns p {\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scanVideoContainer": `CameraScanner_scanVideoContainer__DiP3A`,
	"scanWrapper": `CameraScanner_scanWrapper__ma2aj`,
	"scanInterface": `CameraScanner_scanInterface__dwVoE`,
	"scanBtn": `CameraScanner_scanBtn__qFxgq`,
	"markingBtns": `CameraScanner_markingBtns__bJJ4k`
};
export default ___CSS_LOADER_EXPORT___;
