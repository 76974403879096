import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedFilterUsers } from '../../../../../redux/actions/transactions';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import CheckBox from '../../../atoms/CheckBox';
import s from '../../../molecules/ManagementFilter/ManagementFilter.module.css';

const TransactionsEmployeeFilter = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.organisation.data);
  const { selectedFilterUsers } = useSelector((state) => state.transactions);
  const filteredUsers = useMemo(() => {
    return users.filter((i) => i.is_active);
  }, [users]);
  const [selectedUsers, setSelectedUsers] = useState(selectedFilterUsers);
  useEffect(() => {
    !selectedUsers?.length && setSelectedUsers(filteredUsers);
  }, []);
  return (
    <>
      <div className={s.label}>Выберите сотрудников</div>
      <>
        <div className={s.positions}>
          <CheckBox
            title="Все"
            value={filteredUsers?.length === selectedUsers?.length}
            onChange={() =>
              setSelectedUsers(filteredUsers?.length === selectedUsers?.length ? [] : filteredUsers)
            }
          />
          {filteredUsers &&
            filteredUsers.map((item, i) => (
              <UserItem
                item={item}
                index={i}
                key={i}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
              />
            ))}
        </div>
      </>
      <div className={s.btns}>
        <Button
          title="Сохранить"
          onClick={() => {
            dispatch(
              setSelectedFilterUsers(
                selectedUsers?.length !== filteredUsers?.length ? selectedUsers : []
              )
            );
            closeModal();
          }}
        />
        <ButtonCancel
          onClick={() => {
            closeModal();
          }}
        />
      </div>
    </>
  );
};

const UserItem = ({ item, selectedUsers, setSelectedUsers }) => {
  const isActive = useMemo(() => {
    if (selectedUsers?.length) {
      return selectedUsers.find((el) => el.id === item.id);
    }
  }, [selectedUsers]);

  const onChange = () => {
    setSelectedUsers(
      isActive ? selectedUsers.filter((el) => el.id !== item.id) : [...selectedUsers, item]
    );
  };

  return (
    <CheckBox title={`${item.name} ${item.surname || ''}`} value={isActive} onChange={onChange} />
  );
};

export default React.memo(TransactionsEmployeeFilter);
