import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Confirm from '../../../../atoms/Confirm';
import { removeBooked, setSelectedTimes } from '../../../../../../redux/actions/booking';

const ConfirmRemoveReservation = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedBooking } = useSelector((state) => state.booking);
  const onConfirm = () => {
    const bookingId = selectedBooking?.id;
    dispatch(removeBooked(bookingId));
    dispatch(setSelectedTimes([]));
    closeModal(null, true);
  };

  return (
    <Confirm
      title="Подтверждение"
      paragraphs={['Вы уверены, что хотите удалить бронь?']}
      onConfirm={onConfirm}
      onConfirmTitle="Удалить"
      onCancel={(e) => closeModal(e, true)}
    />
  );
};

ConfirmRemoveReservation.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(ConfirmRemoveReservation);
