import { createSlice } from '@reduxjs/toolkit';
import { authAPI, registrationAPI, recoveryAPI } from '../../api/auth';
import { getProfile } from '../../api/profile';
import { setIsLoad } from './preloader';
import { addAlert } from './alerts';
import { setModal, setModalType } from './modal';
import { MODAL_TYPES } from '../../App/constants/modals';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    headerTitle: '',
    autorized: localStorage.auth ? localStorage.auth : false,
    token: localStorage.session ? localStorage.session : '',
    isTooManyRequest: false,
    login: '',
    password: '',
    positions: [],
    completedFields: {
      name: '',
      surname: '',
      position_id: null,
    },
    role: '',
    inviteHash: null,
    dataFromSoc: null,
    accountInfo: {},
    flags: {
      controled: false,
      withSoc: false,
    },
    hash: '',
    time_left_to_resend: 120,
    captcha: '',
    captchaInfo: '',
  },
  reducers: {
    setSession: (state, data) => {
      state.autorized = data.payload.autorized;
      state.token = data.payload.token;
    },
    setIsTooManyRequest: (state, data) => {
      state.isTooManyRequest = data.payload;
    },
    setLogin: (state, data) => {
      state.login = data.payload;
    },
    setPassword: (state, data) => {
      state.password = data.payload;
    },
    setPositions: (state, data) => {
      state.positions = data.payload;
    },
    setCompletedFields: (state, data) => {
      state.completedFields = data.payload;
    },
    setRole: (state, data) => {
      state.role = data.payload;
    },
    setInviteHash: (state, data) => {
      state.inviteHash = data.payload;
    },
    setDataFromSoc: (state, data) => {
      state.dataFromSoc = data.payload;
    },
    setAccountInfo: (state, data) => {
      state.accountInfo = data.payload;
    },
    setHash: (state, data) => {
      state.hash = data.payload;
    },
    setAuthFlags: (state, data) => {
      return {
        ...state,
        flags: {
          ...state.flags,
          ...data.payload,
        },
      };
    },
    setTimeLeftToResent: (state, data) => {
      state.time_left_to_resend = data.payload;
    },
    setCaptcha: (state, data) => {
      state.captcha = data.payload;
    },
    setCaptchaInfo: (state, data) => {
      state.captchaInfo = data.payload;
    },
    setHeaderTitle: (state, data) => {
      state.headerTitle = data.payload;
    },
  },
});

export const {
  setSession,
  setIsTooManyRequest,
  setLogin,
  setPassword,
  setPositions,
  setCompletedFields,
  setRole,
  setInviteHash,
  setAuthFlags,
  setDataFromSoc,
  setAccountInfo,
  setHash,
  setTimeLeftToResent,
  setCaptcha,
  setCaptchaInfo,
  setHeaderTitle,
} = sessionSlice.actions;

export default sessionSlice.reducer;

export const getCaptcha = () => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    await authAPI.getCaptcha().then((res) => {
      dispatch(setCaptchaInfo({ hash: res.data?.hash, image: res.data?.image }));
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const sendAuthLogin = (login, captcha, captcha_hash, redirect) => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await registrationAPI.sendLogin(login, captcha, captcha_hash);
    dispatch(setLogin(login));
    const message = response?.data?.data;
    if (message?.error?.code === 'SMS_WAS_SENT') {
      dispatch(setTimeLeftToResent(message?.error?.details?.time_left_to_resend || 120));
    } else if (message?.error?.code === 'SMS_ALREADY_RESENT') {
      dispatch(addAlert(message?.error?.message));
    }
    redirect(message?.user_exists ? '/auth/signin' : '/auth/check');
  } catch (err) {
    dispatch(
      addAlert(
        err?.response?.data?.error?.details?.captcha ||
          err?.response?.data?.error?.message ||
          'Возникла ошибка'
      )
    );
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getAccountInfo = (redirect) => async (dispatch, getState) => {
  try {
    const response = await authAPI.getAccountInfo(getState().session.login);
    if (response?.data?.data?.position && response?.data?.data?.position !== 'Администратор') {
      dispatch(setModal(true));
      dispatch(setModalType(MODAL_TYPES.loginHint));
      redirect('/auth');
    } else {
      dispatch(setAccountInfo(response.data.data));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const socLogin = (user, redirect) => async (dispatch) => {
  try {
    if (user.data.token) {
      dispatch(
        setSession({
          autorized: true,
          token: user.data.token,
        })
      );
      getProfile();
    } else if (user.data.response.email) {
      dispatch(
        setAuthFlags({
          controled: true,
        })
      );
      dispatch(setDataFromSoc(user));
      dispatch(setLogin(user.data.response.email));
      dispatch(setPassword('secret'));
      redirect('/auth/gender');
    } else {
      dispatch(setDataFromSoc(user));
      redirect('/auth');
      dispatch(addAlert('Возникла ошибка'));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const sendRecLogin = (login, redirect) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await recoveryAPI.sendLogin(login);
    if (response.status === 200) {
      const message = response?.data?.data;
      if (message?.error?.code === 'SMS_WAS_SENT') {
        dispatch(setTimeLeftToResent(message?.error?.details?.time_left_to_resend || 120));
      } else if (
        message?.error?.code === 'SMS_ALREADY_RESENT' ||
        message?.error?.code === 'SMS_SENT_LIMIT'
      ) {
        dispatch(addAlert(message?.error?.message));
      }
      redirect('/auth/recoverycheck');
    }
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(
      addAlert(
        err?.response?.data?.error?.details?.captcha ||
          err?.response?.data?.error?.message ||
          'Возникла ошибка'
      )
    );
    console.log(err);
    console.log(err.response);
  }
};

export const sendRecCode = (code, redirect) => async (dispatch) => {
  try {
    const response = await recoveryAPI.sendCode(code);
    if (response.data.data === true) {
      redirect('/auth/recoverypassword');
    }
  } catch (error) {
    dispatch(addAlert(error.response?.data?.error?.details?.code || 'Возникла ошибка'));
  }
};

export const resendCode = (login) => async (dispatch) => {
  try {
    await registrationAPI.resendCode(login);
  } catch (err) {
    dispatch(addAlert(err.response.data.error?.message || 'Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const sendRegCode = (code, redirect) => async (dispatch) => {
  try {
    await registrationAPI.sendCode(code).then(() => {
      redirect('/auth/password');
    });
  } catch (err) {
    dispatch(addAlert(err?.response?.data?.error?.details?.code || 'Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const sendRegPassword = (password, redirect, path) => async (dispatch) => {
  dispatch(setPassword(password));
  redirect(path);
};

export const sendGender = (regData, redirect, callback) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    regData.password = getState().session.password;
    if (getState().session.dataFromSoc) {
      regData.soc = JSON.stringify(getState().session.dataFromSoc);
    }
    if (getState().session.inviteHash) {
      regData.hash = getState().session.inviteHash;
    }
    const response = await registrationAPI.sendGender(regData);
    dispatch(
      setSession({
        autorized: false,
        token: response.data.data.token,
      })
    );
    callback && callback();
    dispatch(
      setAuthFlags({
        withSoc: false,
      })
    );
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert(err?.response?.data?.error?.message || 'Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const sendPassword =
  (password, password_confirmation, redirect, path, callback) => async (dispatch) => {
    try {
      const response = await registrationAPI.sendPassword(password, password_confirmation);
      console.log(response);
      callback();
    } catch (err) {
      dispatch(addAlert('Возникла ошибка'));
      console.log(err);
      console.log(err.response);
    }
  };

export const toFinish = (redirect) => (dispatch) => {
  dispatch(
    setAuthFlags({
      withSoc: false,
    })
  );
  redirect('/auth');
  getProfile();
};
