// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginHint_messageBlock__3hN5W {
    /*display: flex;*/
}
.LoginHint_title__FH01P {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1E293B;
    margin-bottom: 10px;
}
.LoginHint_text__V9VGk {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #1E293B;
}
.LoginHint_logo__Tazk\\+ {
    width: 40px;
}
.LoginHint_button__OoMZz {
     margin-top: 20px;
 }

@media (max-width: 1000px) {
    .LoginHint_text__V9VGk {
        font-size: 18px;
        line-height: 25px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/LoginHint/LoginHint.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;AACA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,WAAW;AACf;AACA;KACK,gBAAgB;CACpB;;AAED;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".messageBlock {\n    /*display: flex;*/\n}\n.title {\n    font-family: 'Helvetica Neue';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 24px;\n    line-height: 30px;\n    color: #1E293B;\n    margin-bottom: 10px;\n}\n.text {\n    font-family: 'Helvetica Neue';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 140%;\n    color: #1E293B;\n}\n.logo {\n    width: 40px;\n}\n.button {\n     margin-top: 20px;\n }\n\n@media (max-width: 1000px) {\n    .text {\n        font-size: 18px;\n        line-height: 25px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageBlock": `LoginHint_messageBlock__3hN5W`,
	"title": `LoginHint_title__FH01P`,
	"text": `LoginHint_text__V9VGk`,
	"logo": `LoginHint_logo__Tazk+`,
	"button": `LoginHint_button__OoMZz`
};
export default ___CSS_LOADER_EXPORT___;
