import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};

export const getAuthHeadersJSON = () => {
  const { session } = store.getState();
  return {
    headers: { Authorization: `Bearer ${session.token}` },
  };
};

const getOrgId = () => {
  const { profile } = store.getState();
  return profile.data.organisation.id;
};

export const clubcardApi = {
  getDiscountsForNew: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/discounts`,
      getAuthHeaders()
    ),
  getClubcards: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card`, getAuthHeaders()),
  getClubcardTemplates: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/templates`,
      getAuthHeaders()
    ),
  getEditClubcardInfo: (id) => {
    return axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/edit/${id}`,
      getAuthHeaders()
    );
  },
  createClubcard: (data, cardId, type) => {
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/${
        type === 'add' ? 'store' : `update/${cardId}`
      }?organisation_id=${getOrgId()}`,
      data,
      getAuthHeaders()
    );
  },
  applyDiscountForClubcard: (data) => {
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/discounts/updateMultiple`,
      data,
      getAuthHeaders()
    );
  },
  getFreeClubcards: () => {
    return axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/freeLoyalCard`,
      getAuthHeaders()
    );
  },
  selectClubcard: (id) => {
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/selected/${id}`,
      {},
      getAuthHeaders()
    );
  },
  deleteClubcard: (id) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/card/destroy/${id}`,
      getAuthHeaders()
    ),
};
export const groupsSettingsApi = {
  getGroups: (programId, page) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/groups?program_id=${programId}&page=${page}`,
      getAuthHeadersJSON()
    ),
  getClients: (programId, groupId, page) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/getSingleGroup?program_id=${programId}&group_id=${groupId}&page=${page}`,
      getAuthHeadersJSON()
    ),
  getClientsCsv: (loyalCardId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/discount/v1/web/download-clients-xlsx/${loyalCardId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${store.getState().session.token}`,
        },
        responseType: 'blob',
      }
    ),
  deleteClient: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/deleteLoyalUser `,
      data,
      getAuthHeadersJSON()
    ),
  regroupClient: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/regroupUser `,
      data,
      getAuthHeadersJSON()
    ),
  importGroup: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/importGroup `,
      data,
      getAuthHeaders()
    ),
  addSumClientClubcardGroup: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/addAmount`,
      data,
      getAuthHeaders()
    ),
};
