import React from 'react';
import PropTypes from 'prop-types';
import s from './Button.module.css';

const Button = ({
  title,
  onClick,
  className,
  textClassName,
  isDanger,
  isInfo,
  isActive,
  isLight,
  widthByContent,
  changeStyle = false,
  changeStyleMove = false,
  icon,
  iconRight,
  isDisabled,
  isCansel,
}) => {
  let style = s.button;
  if (isLight) {
    style = `${s.button} ${s.light}`;
  }
  if (isDanger) {
    style = `${s.button} ${s.danger}`;
  }
  if (isInfo) {
    style = `${s.button} ${s.info}`;
  }
  if (isCansel) {
    style = `${s.button} ${s.inActive}`;
  }
  if (isActive === false || isDisabled) {
    style = `${s.button} ${s.inActive}`;
  }
  if (widthByContent) {
    style = `${style} ${s.widthByContent}`;
  }
  if (changeStyle) {
    style = `${style} ${s.checkBtn}`;
  }
  if (changeStyleMove) {
    style = `${style} ${s.moveBtn}`;
  }

  return (
    <div onClick={isDisabled ? null : onClick} className={`${style} ${className}`}>
      {(icon && !iconRight && <span className={s.icon}>{icon}</span>) || null}
      <span className={`${s.text} ${textClassName || ''}`}>{title}</span>
      {(icon && iconRight && <span className={`${s.icon} ${s.iconRight}`}>{icon}</span>) || null}
    </div>
  );
};

export default React.memo(Button);

Button.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  onClick: PropTypes.func,
  isDanger: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  title: 'Oкей',
  isDanger: false,
  isDisabled: false,
};
