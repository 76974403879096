import React from 'react';

export const SvgLoyaltyIcon = ({ color }) => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <g
          clipPath="url(#b)"
          stroke={color || '#94A3B8'}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="m5.8 10.389 4.4-4.4-4.4 4.4Zm8.228-4.158c-.22-.667-.074-1.525-.477-2.075-.403-.55-1.268-.69-1.826-1.1-.557-.411-.938-1.181-1.606-1.394-.667-.212-1.386.19-2.12.19-.732 0-1.466-.402-2.118-.19-.653.213-1.05.998-1.606 1.394-.558.396-1.408.535-1.826 1.1-.418.564-.257 1.408-.477 2.075-.22.667-.836 1.225-.836 1.958 0 .733.63 1.313.836 1.958.205.645.073 1.525.477 2.075.403.55 1.268.69 1.826 1.093.557.403.938 1.188 1.606 1.4.667.213 1.386-.19 2.119-.19.733 0 1.467.403 2.12.19.652-.212 1.048-.997 1.605-1.4.558-.403 1.423-.528 1.826-1.093.403-.564.257-1.408.477-2.075.22-.667.836-1.225.836-1.958 0-.733-.63-1.313-.836-1.958Z"
            strokeWidth={1.5}
          />
          <path d="M6.13 6.166h.073M9.797 9.834h.073" strokeWidth={2} />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
