import { createSlice } from '@reduxjs/toolkit';

import { shiftsApi } from '../../api/shifts';
import { setIsLoad } from './preloader';
import { addAlert } from './alerts';

export const shiftsSlice = createSlice({
  name: 'shifts',
  initialState: {
    currentDays: {
      userId: null,
      colsIndexes: [],
    },
    calendar: null,
    shiftsLoading: false,
    sections: [],
    shiftInfo: {
      desk_map_id: null,
    },
    shiftHistory: {},
    dateFilter: {
      period: 'month',
      from: '',
      to: '',
      translatePeriods: {
        day: 'Сегодня',
        week: 'Неделя',
        month: 'Месяц',
      },
    },
    positionsFilter: [],
  },
  reducers: {
    setCurrentDays: (state, data) => {
      state.currentDays = data.payload;
    },
    setShifts: (state, data) => {
      state.calendar = data.payload;
    },
    setShiftsLoading: (state, data) => {
      state.shiftsLoading = data.payload;
    },
    setSections: (state, data) => {
      state.sections = data.payload;
    },
    setShiftInfo: (state, data) => {
      state.shiftInfo = data.payload;
    },
    setShiftHistory: (state, data) => {
      state.shiftHistory = data.payload;
    },
    setDateFilter: (state, data) => {
      state.dateFilter = data.payload;
    },
    clearDateFilter: (state) => {
      state.dateFilter = {
        ...state.dateFilter,
        period: 'month',
        from: '',
        to: '',
      };
    },
    setPositionsOfFilter: (state, data) => {
      state.positionsFilter = data.payload;
    },
    resetPositionsOfFilter: (state, data) => {
      const currentPath = data.payload || '';
      if (
        currentPath !== '/management' &&
        !currentPath.includes('/transactions/') &&
        !!state?.positionsFilter?.length
      ) {
        state.positionsFilter = [];
      }
    },
    clearPositionOfFilter: (state, data) => {
      state.positionsFilter.splice(data.payload, 1);
    },
  },
});

export const {
  setShifts,
  setSections,
  setCurrentDays,
  setShiftInfo,
  setShiftHistory,
  setDateFilter,
  clearDateFilter,
  setPositionsOfFilter,
  resetPositionsOfFilter,
  clearPositionOfFilter,
  setShiftsLoading,
} = shiftsSlice.actions;

export default shiftsSlice.reducer;

export const updatePositionsFilter = (newFilter) => async (dispatch) => {
  dispatch(setPositionsOfFilter(newFilter));
  setTimeout(() => dispatch(setIsLoad(false)), 200);
};
export const getСalendar = () => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  dispatch(setShiftsLoading(true));
  try {
    const { dateFilter, positionsFilter } = getState().shifts;
    const { period, from, to } = dateFilter;
    let data;
    if (period) {
      data = {
        date: period,
      };
    } else {
      data = {
        'date[from]': from,
        'date[to]': to,
      };
    }
    if (positionsFilter.length) {
      data.position = JSON.stringify(positionsFilter);
    }
    const response = await shiftsApi.getСalendar(data);
    if (response.data.data.body.length) {
      dispatch(setShifts(response.data.data));
    } else {
      dispatch(setShifts(null));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setShiftsLoading(false));
    dispatch(setIsLoad(false));
  }
};

export const getSections = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await shiftsApi.getSections();
    dispatch(setSections(response.data.data));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const createShift = (data) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await shiftsApi.createShift(data);
    dispatch(getСalendar());
    dispatch(setIsLoad(false));
    dispatch(addAlert('Смена создана', 'success'));
    return response;
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const edidShift = (id, data, userIndex, colIndex) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const calendar = getState().shifts.calendar;
    const response = await shiftsApi.edidShift(id, data);
    const newCalendar = calendar.body.map((userItem, i) =>
      i === userIndex
        ? userItem.map((colItem, colIdx) =>
            colIdx === colIndex
              ? {
                  ...colItem,
                  shift: response.data.data.result,
                }
              : colItem
          )
        : userItem
    );
    dispatch(
      setShifts({
        ...calendar,
        body: newCalendar,
      })
    );
    dispatch(setShiftInfo({ desk_map_id: null }));
    dispatch(setIsLoad(false));
    return response;
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const deleteShift = (id, colIndex, userIndex) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const response = await shiftsApi.deleteShift(id).then((res) => {
      const calendar = getState().shifts.calendar;

      const newCalendar = calendar.body.map((userItem, i) =>
        i === userIndex
          ? userItem.map((colItem, colIdx) =>
              colIdx === colIndex
                ? {
                    ...colItem,
                    shift: null,
                  }
                : colItem
            )
          : userItem
      );
      dispatch(
        setShifts({
          ...calendar,
          body: newCalendar,
        })
      );
      dispatch(addAlert('Смена удалена', 'success'));
      return res;
    });
    return response;
  } catch (err) {
    dispatch(getСalendar());
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getShiftInfo = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await shiftsApi.getShiftInfo(id);
    dispatch(setShiftInfo(response.data.data));
    dispatch(setIsLoad(false));
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const getShiftHistory = (id) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const response = await shiftsApi.getShiftHistory(id);
    dispatch(setShiftHistory(response.data.data));
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};
