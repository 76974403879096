import { createSlice } from '@reduxjs/toolkit';
import { settingAPI } from '../../api/setting';
import { setOrganisationInfo } from './organisation';
import { getProfile } from '../../api/profile';
import { setIsLoad } from './preloader';
import { setMaindata } from './constructor';
import { addAlert } from './alerts';
import { setDataProfile } from './profile';

export const settingSlice = createSlice({
  name: 'setting',
  initialState: {
    desks: [],
    confirmEmailSeconds: 120,
    addDesks: false,
    updateSection: null,
    subscriptions: {
      subscribe: {
        id: null,
        type: 0,
        time: '00:00',
      },
      providers: [
        {
          id: null,
          provider: 'telegram',
          provider_id: null,
          is_subscribe: false,
          events: [
            {
              id: null,
              event: 'review',
              value: false,
            },
            {
              id: null,
              event: 'nachai',
              value: false,
            },
            {
              id: null,
              event: 'worckday',
              value: false,
            },
          ],
        },
      ],
    },
    menuData: {
      menus: [],
      platform: 'anotherPlatform',
    },
    transfer: {
      isTransferWaiter: false,
    },
  },
  reducers: {
    setDesks: (state, data) => {
      state.desks = data.payload;
    },
    toAddDesks: (state, data) => {
      state.addDesks = data.payload;
    },
    toChangeDesks: (state, data) => {
      state.updateSection = data.payload.section;
    },
    setSubscriptions: (state, data) => {
      state.subscriptions = data.payload;
    },
    setIsTransferWaiter: (state, data) => {
      state.transfer.isTransferWaiter = data.payload;
    },
    setConfirmEmailSeconds: (state, data) => {
      state.confirmEmailSeconds = data.payload;
    },
  },
});

export const { setDesks, setSubscriptions, setIsTransferWaiter, setConfirmEmailSeconds } =
  settingSlice.actions;

export default settingSlice.reducer;

export const getDesks = () => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await settingAPI.getDesks();
    dispatch(setDesks(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getNewDesks = () => async (dispatch) => {
  try {
    await settingAPI.getNewDesks().then((res) => {
      dispatch(setDesks(res.data?.data));
    });
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const selectTakeAwayDesk = (desk) => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    await settingAPI.selectTakeAwayDesk(desk).then(() => dispatch(getDesks()));
    dispatch(addAlert('Изменения сохранены', 'success'));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getSubscribe = (callback) => async (dispatch) => {
  try {
    const response = await settingAPI.getSubscriptions();
    callback && callback(response.data.data);
    dispatch(
      setSubscriptions({
        ...response.data.data,
        providers: response.data.data.providers.reverse(),
      })
    );
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const sendTypeSubscription = (provider, callback) => async (dispatch) => {
  try {
    await settingAPI.sendTypeSubscription(provider).then((response) => {
      if (provider.provider === 'telegram' && !provider.is_subscribe) {
        let newWindow = null;
        newWindow = window.open(response.data.data, '', 'width=400,height=400');
        let poolingInterval = setInterval(() => {
          if (!newWindow || newWindow.closed || newWindow.closed === undefined) {
            clearInterval(poolingInterval);
            poolingInterval = null;
            newWindow = null;
          }
          if (newWindow === null) {
            dispatch(
              getSubscribe((data) => {
                if (data.providers[0].is_subscribe) {
                  callback && callback();
                }
              })
            );
            getProfile();
          }
        }, 250);
      } else {
        dispatch(
          setSubscriptions({
            ...response.data.data,
            providers: response.data.data.providers.reverse(),
          })
        );
      }
      if (provider.is_subscribe && provider.provider === 'telegram') {
        getProfile();
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const sendEventSubscription = (event, value) => async (dispatch) => {
  try {
    const response = await settingAPI.sendEventSubscription(event, value);
    dispatch(
      setSubscriptions({
        ...response.data.data,
        providers: response.data.data.providers.reverse(),
      })
    );
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const changePassword = (password, passwordConfirmation) => async (dispatch) => {
  try {
    await settingAPI.changePassword(password, passwordConfirmation);
    dispatch(addAlert('Изменения сохранены', 'success'));
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  }
};

export const verifyEmailCode = (code, callback) => async (dispatch, getState) => {
  try {
    await settingAPI.verifyEmailCode(code).then(() => {
      const dataProfile = getState().profile.data;
      dispatch(
        setDataProfile({
          ...dataProfile,
          is_email_verified: true,
        })
      );
      dispatch(addAlert('Email подтвержден', 'success'));

      callback && callback();
    });
  } catch (err) {
    dispatch(
      addAlert(
        err?.response?.data?.error?.details?.code ||
          err?.response?.data?.error?.message ||
          'Возникла ошибка',
        'error'
      )
    );
    console.log(err);
    console.log(err.response);
  }
};

export const getEmailCode = (callback) => async (dispatch) => {
  try {
    await settingAPI.getEmailCode().then(() => {
      callback && callback();
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  }
};

export const resendEmailCode = (callback) => async (dispatch) => {
  try {
    await settingAPI.resendEmailCode().then(() => {
      callback && callback();
    });
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  }
};

export const getOrganisation = () => async (dispatch) => {
  try {
    const response = await settingAPI.getOrganisation();
    dispatch(setOrganisationInfo(response.data.data));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const updateOrganisation =
  (name, address, coordinates, description, widgetMapOrganisationId) =>
  async (dispatch, getState) => {
    try {
      const dataInfo = getState().organisation.data.info;
      dispatch(addAlert('Изменения сохранены', 'success'));
      dispatch(setIsLoad(true));
      const response = await settingAPI
        .updateOrganisation(name, address, coordinates, description, widgetMapOrganisationId)
        .then((res) => {
          dispatch(
            setOrganisationInfo({
              ...dataInfo,
              ...res.data.data,
            })
          );
          return res;
        });
      return response;
    } catch (err) {
      dispatch(getOrganisation());
      dispatch(addAlert('Возникла ошибка', 'error'));
      console.log(err);
      console.log(err.response);
    } finally {
      dispatch(setIsLoad(false));
    }
  };

export const uploadImgOrganisation = (img, type, mainData) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoad(true));
    const response = await settingAPI.sendImgOrganisation(img, type);
    const res = response?.data?.data;
    const dataInfo = getState().organisation.data.info;
    for (const i in res) {
      if (i === type) {
        dispatch(
          setMaindata({
            ...mainData,
            [i]: res[i],
          })
        );
        break;
      }
    }
    dispatch(setOrganisationInfo({ ...dataInfo, ...res }));
    dispatch(addAlert('Изменения сохранены', 'success'));
  } catch (err) {
    await getProfile();
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};
