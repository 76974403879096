import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { setIsLoad } from '../../redux/actions/preloader';
import {
  getDesksWithoutQr,
  getEmployeesFromTemp,
  onSetImportedCurrencies,
  setImportedRestaurants,
  setIsSuccessConnection,
} from '../../redux/actions/integrationRkeeper';
import { addAlert } from '../../redux/actions/alerts';
import { setIsRkeeperInfoLoaded } from '../../redux/actions/orders';
import { getMenuVariants } from '../../redux/actions/settingMenu';

const socket = io(process.env.REACT_APP_TENQ_API, {
  autoConnect: false,
}).connect();

export default function useSocket() {
  const dispatch = useDispatch();

  const organisationId = useSelector((state) => state.organisation?.data?.info?.id);
  useEffect(() => {
    if (organisationId) {
      const socketPath = `service.${organisationId}`;

      socket.on(`${socketPath}:rkeeper.error`, (e) => {
        dispatch(
          addAlert(
            String(e?.errorMessage || '').includes('нет права')
              ? 'У пользователя нет прав на совершение действия'
              : e?.errorMessage
          )
        );
      });

      socket.on(`${socketPath}:rkeeper.getOrderDetails`, (e) => {
        if (e?.isLoaded) {
          dispatch(setIsRkeeperInfoLoaded(true));
        }
      });
      socket.on(`${socketPath}:rkeeper.discount.applied`, (e) => {
        if (e?.rkeeperPreOrder) {
          dispatch(setIsRkeeperInfoLoaded(true));
        }
      });
    }
    if (
      organisationId &&
      (window.location.pathname.includes('organisation/integrationRkeeper') ||
        window.location.pathname.includes('organisation/menu'))
    ) {
      const socketPath = `service.${organisationId}`;

      socket.on(`${socketPath}:rkeeper.connection`, (e) => {
        if (e.isConnected) {
          dispatch(setIsLoad(false));
          dispatch(setIsSuccessConnection(true));
          dispatch(addAlert('Соединение успешно выполнено', 'success'));
        }
      });

      socket.on(`${socketPath}:rkeeper.restaurants`, (e) => {
        dispatch(setIsLoad(false));
        dispatch(setImportedRestaurants(e?.restaurants || []));
      });

      socket.on(`${socketPath}:rkeeper.tables`, () => {
        dispatch(getDesksWithoutQr());
      });

      socket.on(`${socketPath}:rkeeper.currencies`, (e) => {
        dispatch(onSetImportedCurrencies(e.currencies));
      });

      socket.on(`${socketPath}:rkeeper.menu`, () => {
        dispatch(setIsLoad(false));
        dispatch(addAlert('Интеграция выполнена успешно', 'success'));
        dispatch(getMenuVariants());
      });

      socket.on(`${socketPath}:rkeeper.employees`, () => {
        dispatch(setIsLoad(false));
        dispatch(getEmployeesFromTemp());
      });
    }

    return () => {
      const socketPath = `service.${organisationId}`;
      socket.off(`${socketPath}:rkeeper.connection`);
      socket.off(`${socketPath}:rkeeper.cashes`);
      socket.off(`${socketPath}:rkeeper.tables`);
      socket.off(`${socketPath}:rkeeper.restaurants`);
      socket.off(`${socketPath}:rkeeper.currencies`);
      socket.off(`${socketPath}:rkeeper.getOrderDetails`);
      socket.off(`${socketPath}:rkeeper.discount.applied`);
      socket.off(`${socketPath}:rkeeper.menu`);
      socket.off(`${socketPath}:rkeeper.employees`);
      socket.off(`${socketPath}:rkeeper.error`);
    };
  }, [organisationId, window.location.pathname]);
}
