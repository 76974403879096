// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonCancel_button__GaEra {
    height: 40px;
    background: #F2F5FA;
    border: 1px solid #F2F5FA;
    border-radius: 12px;
    color: #1E293B;
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 135px;
    width: 100%;
}
.ButtonCancel_button__GaEra.ButtonCancel_widthByContent__jjzhX {
    display: inline-flex;
    padding: 0 15px;
}

.ButtonCancel_moveBtn__dgJZa {
    width: 147px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px !important;
    border-radius: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/ButtonCancel/ButtonCancel.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,8BAA8B;AAClC","sourcesContent":[".button {\n    height: 40px;\n    background: #F2F5FA;\n    border: 1px solid #F2F5FA;\n    border-radius: 12px;\n    color: #1E293B;\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 14px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    min-width: 135px;\n    width: 100%;\n}\n.button.widthByContent {\n    display: inline-flex;\n    padding: 0 15px;\n}\n\n.moveBtn {\n    width: 147px;\n    height: 34px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-top: 2px !important;\n    border-radius: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonCancel_button__GaEra`,
	"widthByContent": `ButtonCancel_widthByContent__jjzhX`,
	"moveBtn": `ButtonCancel_moveBtn__dgJZa`
};
export default ___CSS_LOADER_EXPORT___;
