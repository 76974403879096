// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaptchaModal_btns__ZLgZD {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.CaptchaModal_btn__-YYOh {
  width: 48.7%;
}

.CaptchaModal_captchaContainer__ylWw5 {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
}

.CaptchaModal_captchaRefresh__P1Yuv {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.CaptchaModal_noSelect__cbGKc {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/CaptchaModal/CaptchaModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EAIzB,iBAAiB;AACnB","sourcesContent":[".btns {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 20px;\n}\n\n.btn {\n  width: 48.7%;\n}\n\n.captchaContainer {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  margin-bottom: 10px;\n}\n\n.captchaRefresh {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.noSelect {\n  -webkit-touch-callout: none;\n  -webkit-user-select: none;\n  -khtml-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btns": `CaptchaModal_btns__ZLgZD`,
	"btn": `CaptchaModal_btn__-YYOh`,
	"captchaContainer": `CaptchaModal_captchaContainer__ylWw5`,
	"captchaRefresh": `CaptchaModal_captchaRefresh__P1Yuv`,
	"noSelect": `CaptchaModal_noSelect__cbGKc`
};
export default ___CSS_LOADER_EXPORT___;
