import React, { memo } from 'react';
import styles from './ConstructoButtonWidgets.module.css';
import MinusIcon from '../../../../../assets/Icons/minusIcon';
import { PlusIcon } from '../../../../../assets/Icons/plusIcon';

const TimePickerWidget = ({
  title,
  minutes,
  seconds,
  setMinute,
  setSeconds,
  customValueChange,
}) => {
  const onIncrement = () => {
    if (seconds === 30) {
      setMinute((prev) => prev + 1);
      setSeconds(0);
      return;
    }
    if (seconds < 60 && minutes < 60) {
      setSeconds((prev) => prev + 30);
    }
  };
  const onDecrement = () => {
    if (minutes === 1 && seconds === 0) {
      return;
    }
    if (seconds > 0) {
      setSeconds((prev) => prev - 30);
      return;
    }
    if (seconds === 0 && minutes > 0) {
      setMinute((prev) => prev - 1);
      setSeconds(30);
    }
  };

  return (
    <div className={`grayContainer ${styles.subIContent}`}>
      <label className="plainGrayText">{title}</label>
      <div className={styles.countBlock}>
        <button
          aria-label="minus"
          onClick={() => {
            (customValueChange && customValueChange('decrement')) || onDecrement();
          }}
          type="button"
          className={styles.minusItem}
        >
          <MinusIcon />
        </button>
        <div className={styles.inputContainer}>
          <input
            name="minutes"
            placeholder="00"
            readOnly
            value={minutes.toString().padStart(2, '0')}
            type="number"
            min="0"
          />
          <span>мин :</span>
          <input
            name="seconds"
            placeholder="00"
            readOnly
            value={seconds.toString().padStart(2, '0')}
            type="number"
            min="1"
            max="60"
          />
          <span>сек</span>
        </div>
        <button
          aria-label="plus"
          onClick={() => {
            (customValueChange && customValueChange('increment')) || onIncrement();
          }}
          type="button"
          className={styles.plusItem}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
};

export default memo(TimePickerWidget);
