import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onMessage } from 'firebase/messaging';
import useSocket from './hooks/useSocket';
import { Preloader } from './components/Preloader/Preloader';
import AppModal from './features/organisms/Modal';
import { getSessionIsTooManyRequestSelector } from '../redux/selectors/session';
import Break from './features/organisms/Break';
import { fetchToken, useFirebaseMessaging } from '../firebase';
import { sendFcmToken } from '../redux/actions/profile';
import Alerts from './features/molecules/Alerts/Alerts';
import { setModal, setModalType } from '../redux/actions/modal';
import { MODAL_TYPES } from './constants/modals';
import PushNotificationModal from './features/organisms/Modal/PushNotificationModal';
import OrderDetailModal from './features/organisms/Modal/OrderDetailModal';
import { addAlert } from '../redux/actions/alerts';
import {
  getNotifications,
  getUnreadNotifications,
  setPushNotification,
  setPushNotificationType,
} from '../redux/actions/notifications';

const Auth = React.lazy(() => import('./features/organisms/Auth/Auth'));
const ErrorFallBack = React.lazy(() => import('./features/atoms/ErrorFallBack'));
const LK = React.lazy(() => import('./LK/LK'));

window.io = require('socket.io-client');

function PrivateRoute({ children, autorized }) {
  return autorized ? <>{children}</> : <Redirect to="/auth" />;
}

function App() {
  useSocket();
  const isTooManyRequest = useSelector(getSessionIsTooManyRequestSelector);
  const state = useSelector((state) => state);
  const { shiftsLoading } = useSelector((state) => state.shifts);
  const isQrLoading = useSelector((state) => state.qrcode?.isQrLoading);

  const sessionToken = state.session?.token;
  const { isLoad } = state.preloader;
  const dispatch = useDispatch();
  const sessionState = useSelector((state) => state.session);

  const message = useFirebaseMessaging();

  const [messaging, setMessaging] = useState(message);

  useEffect(() => {
    setMessaging(message);
  }, [message]);
  useEffect(() => {
    const registerServiceWorker = async () => {
      try {
        await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        if (messaging) {
          onMessage(messaging, (payload) => {
            if (!payload) return;

            if (payload?.data?.type_notification === 'owner') {
              dispatch(setPushNotificationType(MODAL_TYPES.VisitorCall));
              dispatch(setPushNotification(payload));
            }
            if (payload && (payload.data?.new_order_id || payload.data?.is_order_processing)) {
              dispatch(setPushNotification(payload));
              dispatch(setPushNotificationType(MODAL_TYPES.newOrderModal));
            }
            if (
              payload.data?.notification_tag === 'ADMIN_ACCEPT_CALL' ||
              payload.data?.notification_tag === 'ADMIN_CANCEL_CALL'
            ) {
              dispatch(setPushNotification(''));
              dispatch(setPushNotificationType(''));
            }
            if (window.location.pathname === '/notice') {
              dispatch(getNotifications(1));
            } else {
              dispatch(getUnreadNotifications());
            }
          });
        }
      } catch (error) {
        console.error('Service Worker registration failed:', error);
      }
    };
    registerServiceWorker();
  }, [messaging]);

  useEffect(async () => {
    if (sessionToken && messaging) {
      fetchToken(messaging).then((fcmToken) => {
        if (fcmToken) {
          const body = {
            device: 'web',
            token: fcmToken,
          };
          localStorage.fcm = fcmToken;
          dispatch(sendFcmToken(body));
        }
      });
    }
  }, [sessionToken, messaging]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const withdrawError = params.get('withdrawError');
    if (withdrawError) {
      dispatch(setModal(true));
      dispatch(setModalType(MODAL_TYPES.outputMoneyError));
    }
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const success = params.has('fireEmployeeSuccess');
    const err = params.has('fireEmployeeError');
    if (success) {
      dispatch(
        addAlert(
          'Сотрудник уволен, денежные средства поступили на банковскую карту Сотрудника',
          'success'
        )
      );
    }
    if (err) {
      dispatch(addAlert('Возникла ошибка при увольнении сотрудника', 'error'));
    }
  }, []);

  if (isTooManyRequest) {
    return <Break />;
  }

  return (
    <Suspense fallback={<Preloader />}>
      <BrowserRouter>
        <AppModal />
        <OrderDetailModal />
        <PushNotificationModal />
        <Alerts />
        <Switch>
          <Route path="/auth" render={() => <Auth />} />
          <Route path="/registration" render={() => <Auth />} />
          <Route path="/serverError" render={() => <ErrorFallBack />} />
          <PrivateRoute path="/" autorized={sessionState?.autorized}>
            <LK />
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
      {(isLoad || shiftsLoading || isQrLoading) && <Preloader />}
    </Suspense>
  );
}

export default React.memo(App);
