import React, { memo } from 'react';
import { useZxing } from 'react-zxing';
import style from './CameraScanner.module.css';
import ArrowToRight from '../../../../assets/Icons/arrowToRight';

const CameraScanner = ({ onScanQr, setScanType, scanType }) => {
  const { ref: videoRef } = useZxing({
    onDecodeResult(result) {
      const codeFormat = result.getBarcodeFormat();

      if (Number(codeFormat) === 11 && scanType === 'qr') {
        onScanQr(result.getText());
      }
    },
  });

  return (
    <div className={style.scanWrapper}>
      <video ref={videoRef} className={style.scanVideoContainer}>
        <track kind="captions" />
      </video>

      <div className={style.scanInterface}>
        <div>
          <button type="button" className={style.scanBtn} onClick={() => setScanType('')}>
            <ArrowToRight color="#FFFFFF" />
          </button>
        </div>
        <p className="plainWhiteText">Отсканируйте QR-код анкеты</p>
      </div>
    </div>
  );
};

export default memo(CameraScanner);
