import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrderDetail } from '../../../../../redux/actions/orders';
import Button from '../../../atoms/Button';
import s from '../VisitorCall/VisitorCall.module.css';
import {
  setPushNotification,
  setPushNotificationType,
} from '../../../../../redux/actions/notifications';

const NewOrderModal = () => {
  const dispatch = useDispatch();
  const { pushNotification } = useSelector((state) => state.notifications);

  return (
    <div>
      <div className={s.headerTitle}>{pushNotification?.notification?.body}</div>
      {pushNotification?.data?.additional_msg && (
        <div className={s.description}>{pushNotification?.data?.additional_msg}</div>
      )}
      <div className={s.buttons}>
        <Button
          className={`${s.button}`}
          title="Детали"
          onClick={() => {
            dispatch(setPushNotification(''));
            dispatch(setPushNotificationType(''));
            dispatch(
              getOrderDetail(pushNotification.data?.order_id || pushNotification.data?.new_order_id)
            );
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(NewOrderModal);
