// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Break_page__blAsI {
    background: #8672E8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.Break_robot__UAnC\\+ {
    position: absolute;
    left: 0;
    z-index: 0;
}
.Break_messageBlock__V6QaN {
    z-index: 1;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 20px;
}
.Break_message__yNZky {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #1E293B;
    margin-bottom: 20px;
}
@media (max-width: 900px) {
    .Break_robot__UAnC\\+ {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Break/Break.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,OAAO;IACP,UAAU;AACd;AACA;IACI,UAAU;IACV,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".page {\n    background: #8672E8;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n.robot {\n    position: absolute;\n    left: 0;\n    z-index: 0;\n}\n.messageBlock {\n    z-index: 1;\n    background: #FFFFFF;\n    border-radius: 16px;\n    padding: 20px;\n}\n.message {\n    font-weight: 700;\n    font-size: 20px;\n    line-height: 26px;\n    color: #1E293B;\n    margin-bottom: 20px;\n}\n@media (max-width: 900px) {\n    .robot {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Break_page__blAsI`,
	"robot": `Break_robot__UAnC+`,
	"messageBlock": `Break_messageBlock__V6QaN`,
	"message": `Break_message__yNZky`
};
export default ___CSS_LOADER_EXPORT___;
