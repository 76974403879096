import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { acceptCall } from '../../../../../redux/actions/organisation';
import { secondsToMinutes } from '../../../../../utils/date';
import Button from '../../../atoms/Button';
import s from './VisitorCall.module.css';

export const VisitorCall = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { pushNotification } = useSelector((state) => state.notifications);

  const [timer, setTimer] = useState(0);
  const [waitingTime, setWaitingTime] = useState(0);
  useEffect(() => {
    setWaitingTime(Number(pushNotification?.data?.waiting_time));
    setTimer(Number(pushNotification?.data?.waiting_time));
  }, [pushNotification]);
  useEffect(() => {
    const id = setInterval(() => {
      setTimer((sec) => (sec > 1 ? sec - 1 : 0));
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <div>
      <div className={s.headerTitle}>{pushNotification?.notification?.body}</div>
      <div className={s.timerWrapper}>
        <div className={s.progressWrapper}>
          <div className={s.progressBar} />
          <div
            className={s.progressBarFill}
            style={{
              width: (timer / waitingTime) * 100 > 100 ? '100%' : `${(timer / waitingTime) * 100}%`,
            }}
          />
        </div>
        <div className={s.timer}>
          <p className={s.timerText}>{secondsToMinutes(timer, 'letters')}</p>
        </div>
      </div>
      <Button
        title="Принять"
        onClick={() => {
          closeModal();
          dispatch(
            acceptCall(
              pushNotification?.data?.visitor_call_id,
              pushNotification?.data?.hash,
              () => {
                setWaitingTime(0);
              }
            )
          );
        }}
      />
    </div>
  );
};
