// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BookingFilters_bookingFilersHead__R8nTz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.BookingFilters_filterSection__IoAfm {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.BookingFilters_filterTitle__XF\\+0K {
    color: #94A3B8;
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
}
.BookingFilters_filterBtnBoxHor__87v04 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px
}
.BookingFilters_filterSectionsBox__0aFBK {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/BookingModals/BookingFilters/BookingFilters.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B;AACJ;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb","sourcesContent":[".bookingFilersHead {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 20px;\n}\n.filterSection {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-bottom: 20px;\n}\n.filterTitle {\n    color: #94A3B8;\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 14px;\n}\n.filterBtnBoxHor {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 20px\n}\n.filterSectionsBox {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookingFilersHead": `BookingFilters_bookingFilersHead__R8nTz`,
	"filterSection": `BookingFilters_filterSection__IoAfm`,
	"filterTitle": `BookingFilters_filterTitle__XF+0K`,
	"filterBtnBoxHor": `BookingFilters_filterBtnBoxHor__87v04`,
	"filterSectionsBox": `BookingFilters_filterSectionsBox__0aFBK`
};
export default ___CSS_LOADER_EXPORT___;
