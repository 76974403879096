import { createSlice } from '@reduxjs/toolkit';
import { qrApi } from '../../api/qrcodes';
import { setIsLoad } from './preloader';
import { addAlert } from './alerts';

export const qrcodeSlice = createSlice({
  name: 'qrcode',
  initialState: {
    data: {
      qrcodes: [],
      qrCodesForCreator: [],
      employeesForQrCode: [],
    },
    posmaterials: {
      pos: [],
    },
    integrationCode: 'null',
    qrPage: null,
    qrLastPage: 1,
    qrFilterType: 'all',
    isQrLoading: false,
  },
  reducers: {
    setDataQrcodes: (state, data) => {
      state.data.qrcodes = data.payload;
    },
    setQrCodesForCreator: (state, data) => {
      state.data.qrCodesForCreator = data.payload;
    },
    setPosmaterials: (state, data) => {
      state.posmaterials = data.payload;
    },
    setIntegrationCode: (state, data) => {
      state.integrationCode = data.payload;
    },
    setEmployeesForQrCode: (state, action) => {
      state.data.employeesForQrCode = action.payload;
    },
    setQrPage: (state, action) => {
      state.qrPage = action.payload;
    },
    setQrFilterType: (state, action) => {
      state.qrFilterType = action.payload;
    },
    setQrLastPage: (state, action) => {
      state.qrLastPage = action.payload;
    },
    setIsQrLoading: (state, action) => {
      state.isQrLoading = action.payload;
    },
  },
});

export const {
  setDataQrcodes,
  setQrCodesForCreator,
  setPosmaterials,
  setIntegrationCode,
  setEmployeesForQrCode,
  setQrFilterType,
  setQrLastPage,
  setQrPage,
  setIsQrLoading,
} = qrcodeSlice.actions;

export default qrcodeSlice.reducer;

export const getQrCodes = (type, isFirst, callback) => async (dispatch, getState) => {
  dispatch(setIsQrLoading(true));
  const { qrcode } = getState();
  try {
    if (qrcode.qrLastPage !== qrcode.qrPage || isFirst) {
      const response = await qrApi.getQrCodes(
        isFirst ? 1 : qrcode.qrPage + 1,
        type || qrcode.qrFilterType
      );
      const newQrData = response.data?.data || [];
      const qrCodesList = isFirst ? newQrData : [...qrcode.data.qrcodes, ...newQrData];
      callback && callback(qrCodesList);
      dispatch(setDataQrcodes(qrCodesList));
      dispatch(setQrLastPage(response.data?.last_page));
      dispatch(setQrPage(isFirst ? 1 : qrcode.qrPage + 1));
    }
  } catch (err) {
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
  } finally {
    dispatch(setIsQrLoading(false));
  }
};

export const getEmployeesForQrCode = () => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    await qrApi
      .getEmployeesForQrCode()
      .then((res) => {
        dispatch(setEmployeesForQrCode(res.data.data));
        dispatch(setIsLoad(false));
      })
      .catch((err) => new Error(err));
  } catch (err) {
    dispatch(setIsLoad(false));
    console.error(err);
    console.error(err.response);
  }
};

export const saveQR = (formData, qrType, pageType, qrFilterType) => async (dispatch) => {
  dispatch(setIsLoad(true));
  try {
    const res = await qrApi.saveQR(formData);
    console.log(qrFilterType, qrType);
    if (
      qrType === 'all' ||
      (qrFilterType === 'desk' && qrType === 'desks') ||
      (qrFilterType === 'user' && qrType === 'organisation_users')
    ) {
      dispatch(getQrCodes(qrType, true));
    }
    if (pageType !== 'register') {
      dispatch(addAlert('QR-код создан', 'success'));
    }
    return res;
  } catch (err) {
    dispatch(
      addAlert(
        err?.response?.data?.errors?.name ||
          err?.response?.data?.errors ||
          'При отправке данных произошла ошибка',
        'error'
      )
    );
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const editQr = (formData, qrType) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    const res = await qrApi.editQr(formData);
    const { qrcode } = getState();

    if (qrType) {
      dispatch(
        setDataQrcodes(
          qrcode.data.qrcodes.map((qr) => (qr.id === res?.data?.data?.id ? res.data.data : qr))
        )
      );
      dispatch(setIsLoad(false));
      dispatch(addAlert('QR-код изменен', 'success'));
      return res;
    }
  } catch (err) {
    dispatch(setIsLoad(false));
    dispatch(addAlert('Возникла ошибка'));
    console.log(err);
    console.log(err.response);
  }
};

export const deleteQr = (id) => async (dispatch, getState) => {
  dispatch(setIsLoad(true));
  try {
    await qrApi.deleteQr(id);

    const { qrcode } = getState();
    dispatch(getQrCodes(qrcode?.qrFilterType, true));
    dispatch(addAlert('QR-код удален', 'success'));
  } catch (err) {
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};
