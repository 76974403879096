import React, { useState, useMemo, useEffect } from 'react';
import Calendar from 'react-calendar';
import './CustomCalendar.css';
import ButtonCancel from '../../../features/atoms/ButtonCancel';
import Button from '../../../features/atoms/Button';

export function CustomCalendar({
  onCloseCalendar,
  onSelectRange,
  hideButtons = false,
  wantClose,
  maxDate = false,
  selectRange = true,
  tileDisabled = () => {},
  minDate,
}) {
  const [value, onChange] = useState();
  const maximum = useMemo(() => {
    return maxDate ? new Date() : undefined;
  }, [maxDate]);

  useEffect(() => {
    if (wantClose) {
      onSelectRange(value);
    }
  }, [value, wantClose]);

  return (
    <div className="reactCalendarWrapper">
      <Calendar
        onChange={onChange}
        value={value}
        selectRange={selectRange}
        allowPartialRange
        next2Label={null}
        prev2Label={null}
        view="month"
        maxDate={maximum}
        tileDisabled={tileDisabled}
        minDate={minDate}
      />
      {!hideButtons ? (
        <div className="reactCalendarButtonsBlock">
          <ButtonCancel onClick={onCloseCalendar} className="customCalendarButton" title="Назад" />
          <Button
            onClick={() => onSelectRange(value)}
            title="Выбрать"
            className="customCalendarButton"
          />
        </div>
      ) : null}
    </div>
  );
}
