import { createSelector } from 'reselect';

const ordersSelector = (state) => state.orders;

export const getOrdersSelector = createSelector(ordersSelector, (state) => state.orders);
export const getTakeAwayOrdersSelector = createSelector(
  ordersSelector,
  (state) => state.takeAwayOrders
);
export const getNotTakeAwayOrdersSelector = createSelector(
  ordersSelector,
  (state) => state.notTakeAwayOrders
);
export const getSelectedOrder = createSelector(ordersSelector, (state) => state.selectedOrder);
export const getOrderDetail = createSelector(ordersSelector, (state) => state.orderDetail);
export const getOrderFilter = createSelector(ordersSelector, (state) => state.dateFilter);
export const getOrderStatusFilter = createSelector(ordersSelector, (state) => state.statusFilter);
