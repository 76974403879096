import React from 'react';
import styles from './CircleProgressBar.module.css';

const CircleProgressBar = ({ progress }) => {
  const strokeDashoffset = ((100 - progress) / 100) * (2 * Math.PI * 10.5);

  return (
    <div className={styles.circle}>
      <svg className={styles.circle__svg}>
        <circle className={styles.circle__circleBg} />
        <circle
          className={styles.circle__circle}
          strokeDasharray={strokeDashoffset}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </div>
  );
};

export default React.memo(CircleProgressBar);
