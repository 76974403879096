import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  deleteClubcardForm,
  setPreviewData,
  setSelectedFormId,
} from '../../../../../redux/actions/clubcardForms';
import { setModal, setModalType } from '../../../../../redux/actions/modal';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import s from '../../../atoms/Confirm/Confirm.module.css';

export const ConfirmDeletingClubcardForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedFormId, clubcardForms } = useSelector((state) => state.clubcardForms);
  function onConfirm() {
    dispatch(
      deleteClubcardForm(selectedFormId, () => {
        const foundedForm = clubcardForms.find((c) => c.selected);
        if (foundedForm?.id === selectedFormId) {
          dispatch(setPreviewData(''));
        }
      })
    );
    dispatch(setModal(false));
    dispatch(setModalType(''));
    dispatch(setSelectedFormId(''));
  }
  return (
    <div>
      <div className={`${s.title} ${s.field}`}>Подтверждение</div>
      <div className={`${s.message} ${s.field}`}>Вы действительно хотите удалить анкету?</div>

      <div className={`${s.btns} ${s.confirm} `}>
        <Button className={s.confirmBtn} isDanger title="Удалить" onClick={onConfirm} />
        <ButtonCancel onClick={closeModal} />
      </div>
    </div>
  );
};
