import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteClubcard, setSelectedClubcardId } from '../../../../../redux/actions/clubcard';
import { setModal, setModalType } from '../../../../../redux/actions/modal';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import s from '../../../atoms/Confirm/Confirm.module.css';

export const ConfirmDeletingClubcard = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedClubcardId } = useSelector((state) => state.clubcard);

  function onConfirm() {
    dispatch(deleteClubcard(selectedClubcardId));
    dispatch(setSelectedClubcardId(''));
    dispatch(setModal(false));
    dispatch(setModalType(''));
  }
  return (
    <>
      <div className={`${s.title} ${s.field}`}>Подтверждение</div>
      <div className={`${s.message} ${s.field}`}>
        Вы действительно хотите удалить клубную карту?
      </div>

      <div className={`${s.btns} ${s.confirm} `}>
        <Button className={s.confirmBtn} isDanger title="Удалить" onClick={onConfirm} />
        <ButtonCancel onClick={closeModal} />
      </div>
    </>
  );
};
