import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './ConstructorEmulatorModal.module.css';
import Button from '../../features/atoms/Button';
import { getCurrentMode } from '../../../redux/selectors/constructor';
import { getPaySettings, setCurrentMode } from '../../../redux/actions/constructor';

const ConstructorEmulator = React.lazy(() =>
  import('../../features/molecules/ConstructoTabs/ConstructorEmulator/ConstructorEmulator')
);

const ConstructorEmulatorModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const currentMode = useSelector(getCurrentMode);

  const onSetCurrentMode = useCallback((mode) => {
    dispatch(setCurrentMode(mode));
    dispatch(getPaySettings(mode));
  }, []);
  return (
    <>
      <ConstructorEmulator />
      <div className={style.constructorEmulatorModalButtons}>
        <div className={style.constructorEmulatorModalButtonsRow}>
          <Button
            className={`${style.constructorEmulatorModalInBtn} ${
              currentMode === 'in' ? style.ActiveBtn : ''
            }`}
            onClick={() => onSetCurrentMode('in')}
            title="В заведении"
          />

          <Button
            className={`${style.constructorEmulatorModalInBtn} ${
              currentMode === 'out' ? style.ActiveBtn : ''
            }`}
            onClick={() => onSetCurrentMode('out')}
            title="Вне заведения"
          />
        </div>
        <Button
          className={style.constructorEmulatorModalBackBtn}
          onClick={closeModal}
          title="Назад"
        />
      </div>
    </>
  );
};

export default React.memo(ConstructorEmulatorModal);
