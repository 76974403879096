// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputTel_label__\\+YR6Q {
    font-weight: 550;
    font-size: 14px;
    line-height: 14px;
    color: #94A3B8;
    margin-bottom: 12px;
}
.InputTel_wrapper__Pirjp {
    display: grid;
    grid-template-columns: 55px 1fr 25px;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #F2F5FA;
    box-sizing: border-box;
    height: 40px;
}
.InputTel_wrapper__Pirjp > input  {
    outline: none;
    border: none;
    font-weight: 550;
    font-size: 14px;
}
.InputTel_code__WCvJf {
    text-align: center;
    background: #F2F5FA;
    align-self: stretch;
}
.InputTel_number__H9jxf {
    background: #fff;
    padding-left: 5px;
}
.InputTel_clear__FkEYr {
    cursor: pointer;
    margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/InputTel/InputTel.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,oCAAoC;IACpC,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,eAAe;AACnB","sourcesContent":[".label {\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 14px;\n    color: #94A3B8;\n    margin-bottom: 12px;\n}\n.wrapper {\n    display: grid;\n    grid-template-columns: 55px 1fr 25px;\n    align-items: center;\n    border-radius: 10px;\n    overflow: hidden;\n    border: 2px solid #F2F5FA;\n    box-sizing: border-box;\n    height: 40px;\n}\n.wrapper > input  {\n    outline: none;\n    border: none;\n    font-weight: 550;\n    font-size: 14px;\n}\n.code {\n    text-align: center;\n    background: #F2F5FA;\n    align-self: stretch;\n}\n.number {\n    background: #fff;\n    padding-left: 5px;\n}\n.clear {\n    cursor: pointer;\n    margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `InputTel_label__+YR6Q`,
	"wrapper": `InputTel_wrapper__Pirjp`,
	"code": `InputTel_code__WCvJf`,
	"number": `InputTel_number__H9jxf`,
	"clear": `InputTel_clear__FkEYr`
};
export default ___CSS_LOADER_EXPORT___;
