// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.FilterPositions_positionsWrapper__tDqic {
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 12px;
    font-size: 16px;
    color: #94A3B8;
}
.FilterPositions_positionsTitle__hoeg0 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.FilterPositions_selectedPositionsTitle__PFH5l {
    font-weight: 550;
    font-size: 14px;
    color: #1E293B;
}
.FilterPositions_upDown__0Az4n {
    transform: rotateZ(180deg);
}
.FilterPositions_clearBtn__KrKWK {
    width: 30px;
    height: 30px;
    margin: -15px -12px -15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 13px;
}
.FilterPositions_positions__h6pZk {
    display: grid;
    gap: 7px;
}
.FilterPositions_margin__NgpN7 {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/FilterPositions/FilterPositions.module.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,WAAW;IACX,YAAY;IACZ,2BAA2B;IAC3B,aAAa;IAEb,mBAAmB;IAEnB,uBAAuB;IACvB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,QAAQ;AACZ;AACA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;AACb","sourcesContent":["\n.positionsWrapper {\n    background: #FFFFFF;\n    box-sizing: border-box;\n    border-radius: 12px;\n    font-size: 16px;\n    color: #94A3B8;\n}\n.positionsTitle {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n}\n.selectedPositionsTitle {\n    font-weight: 550;\n    font-size: 14px;\n    color: #1E293B;\n}\n.upDown {\n    transform: rotateZ(180deg);\n}\n.clearBtn {\n    width: 30px;\n    height: 30px;\n    margin: -15px -12px -15px 0;\n    display: flex;\n    -webkit-align-items: center;\n    align-items: center;\n    -webkit-justify-content: center;\n    justify-content: center;\n    line-height: 13px;\n}\n.positions {\n    display: grid;\n    gap: 7px;\n}\n.margin {\n    margin-top: 10px;\n    display: flex;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"positionsWrapper": `FilterPositions_positionsWrapper__tDqic`,
	"positionsTitle": `FilterPositions_positionsTitle__hoeg0`,
	"selectedPositionsTitle": `FilterPositions_selectedPositionsTitle__PFH5l`,
	"upDown": `FilterPositions_upDown__0Az4n`,
	"clearBtn": `FilterPositions_clearBtn__KrKWK`,
	"positions": `FilterPositions_positions__h6pZk`,
	"margin": `FilterPositions_margin__NgpN7`
};
export default ___CSS_LOADER_EXPORT___;
