import { createSlice } from '@reduxjs/toolkit';
import { setIsLoad } from './preloader';
import { bookingApi } from '../../api/booking';
import { setModal, setModalType } from './modal';
import { MODAL_TYPES } from '../../App/constants/modals';
import { addAlert } from './alerts';

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    bookingList: {},
    bookedWorkPlace: [],
    selectedZone: null,
    selectedTable: null,
    selectedTimes: [],
    editingData: null,
    dateFilter: null,
    filteredSections: [],
    selectedBooking: null,
  },
  reducers: {
    setBookingList: (state, action) => {
      return {
        ...state,
        bookingList: action.payload,
      };
    },
    setSelectedBooking: (state, action) => {
      return {
        ...state,
        selectedBooking: action.payload,
      };
    },
    setFilteredSections: (state, action) => {
      return {
        ...state,
        filteredSections: action.payload,
      };
    },
    setBookedWorkPlace: (state, action) => {
      return {
        ...state,
        bookedWorkPlace: action.payload,
      };
    },
    setSelectedZone: (state, action) => {
      return {
        ...state,
        selectedZone: action.payload,
      };
    },
    setSelectedTable: (state, action) => {
      return {
        ...state,
        selectedTable: action.payload,
      };
    },
    setSelectedTimes: (state, action) => {
      return {
        ...state,
        selectedTimes: action.payload,
      };
    },
    setEditingData: (state, action) => {
      return {
        ...state,
        editingData: action.payload,
      };
    },
    setDateFilter: (state, action) => {
      return {
        ...state,
        dateFilter: action.payload,
      };
    },
  },
});

export const getBookingList = (data, callback) => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await bookingApi.getBookingData(data);
    dispatch(setBookingList(response?.data?.data));
    callback && callback();
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const bookingTable = (data) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoad(true));
    const response = await bookingApi.createBookingTable(data);
    if (response?.status === 201) {
      dispatch(addAlert('Бронь назначена', 'success'));
      const booking = getState().booking?.bookingList;
      if (Array.isArray(booking?.booking)) {
        const newBookingList = [...booking.booking, response.data.data];
        dispatch(
          setBookingList({
            booking: newBookingList,
            work_hours: booking.work_hours,
          })
        );
      } else {
        getBookingList(data?.date);
      }
    }
  } catch (err) {
    const errMsg = err?.response?.data?.error?.message;
    getBookingList(data?.date);
    dispatch(addAlert(errMsg, 'error'));
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const updateReservation = (data, id) => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await bookingApi.updateReservation(data, id);
    if (response?.status === 200) {
      dispatch(addAlert('Бронь изменена', 'success'));
      dispatch(getBookingList(data?.date));
    }
  } catch (err) {
    console.log(err);
    console.log(err.response);
    dispatch(setIsLoad(false));
  }
};
export const removeBooked = (data, callback) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoad(true));
    const response = await bookingApi.removeBooked(data);
    if (response?.status === 200) {
      const booking = getState().booking?.bookingList;
      const newBookingList = booking?.booking?.filter((b) => b.id !== data);
      dispatch(
        setBookingList({
          booking: newBookingList,
          work_hours: booking.work_hours,
        })
      );
    }
    callback && callback();
  } catch (err) {
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const getBookingById = (id, date) => async (dispatch) => {
  try {
    dispatch(setIsLoad(true));
    const response = await bookingApi.getBookingById(id);
    if (response?.status === 200) {
      dispatch(setEditingData(response?.data?.data));
      dispatch(setModalType(MODAL_TYPES.TableBookingEditor));
    }
  } catch (err) {
    dispatch(getBookingList(date));
    dispatch(setModalType(''));
    dispatch(setModal(false));
    dispatch(addAlert('Возникла ошибка', 'error'));
    console.log(err);
    console.log(err.response);
  } finally {
    dispatch(setIsLoad(false));
  }
};

export const {
  setBookingList,
  setSelectedZone,
  setSelectedTable,
  setSelectedBooking,
  setEditingData,
  setBookedWorkPlace,
  setDateFilter,
  setSelectedTimes,
  setFilteredSections,
} = bookingSlice.actions;

export default bookingSlice.reducer;
