/* eslint-disable no-restricted-globals */
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyCdoonkNpqMNL_dJEuTo7mFJzzseiI5A3Q',
  authDomain: 'q-service-421114.firebaseapp.com',
  projectId: 'q-service-421114',
  storageBucket: 'q-service-421114.appspot.com',
  messagingSenderId: '661920278846',
  appId: '1:661920278846:web:1cf5350bfbb31c78b2646f',
  measurementId: 'G-GXTMLF2Z5R',
};

export const firebaseApp = initializeApp(firebaseConfig);
const messaging = null;

const useFirebaseMessaging = () => {
  const [messaging, setMessaging] = useState(null);

  const checkMessagingSupport = async () => {
    try {
      const supported = await isSupported();
      if (supported) {
        const messagingInstance = getMessaging(firebaseApp);
        setMessaging(messagingInstance);
      }
      return supported;
    } catch (error) {
      console.error('Error checking messaging support:', error);
      return false;
    }
  };

  useEffect(() => {
    checkMessagingSupport();
  }, []);

  return messaging;
};

export { messaging, getToken, onMessage, useFirebaseMessaging };

export const fetchToken = (messaging) => {
  return getToken(messaging, {
    vapidKey:
      'BNF2EY2U3uoXXC01d69be3NV5qECnof4osfW16NyA3xDDXrQpSJpRgtYtkrME2-eTdZifccqbfYxnDD-s72aNKA',
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
