import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from '../../../../redux/actions/alerts';
import { Alert } from './Alert';
import s from './Alerts.module.css';

const Alerts = () => {
  const dispatch = useDispatch();
  const { alerts } = useSelector((state) => state.alerts);
  const deleteItem = (id) => {
    dispatch(removeAlert(id));
  };
  const renderAlerts = useCallback(() => {
    if (alerts?.length) {
      return alerts.map((item) => <Alert key={item.id} deleteItem={deleteItem} item={item} />);
    }
    return null;
  }, [alerts]);

  return <div className={`${s.container} ${s.positionRight}`}>{renderAlerts()}</div>;
};

export default React.memo(Alerts);
