import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash.get';
import { notificationsAPI } from '../../api/history';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: {},
    pagination: null,
    unread: 0,
    isFetching: false,
    pushNotification: '',
    pushNotificationType: '',
  },
  reducers: {
    setNotifications: (state, data) => {
      state.notifications = data.payload;
    },
    setPagination: (state, data) => {
      state.pagination = data.payload;
    },
    setNewPage: (state, data) => {
      for (const key in data.payload) {
        if (state.notifications.hasOwnProperty(key)) {
          state.notifications[key] = [...state.notifications[key], ...data.payload[key]];
        } else {
          state.notifications[key] = data.payload[key];
        }
      }
    },
    setUnread: (state, data) => {
      state.unread = data.payload;
    },
    setIsFetching: (state, data) => {
      state.isFetching = data.payload;
    },
    setPushNotification: (state, data) => {
      state.pushNotification = data.payload;
    },
    setPushNotificationType: (state, data) => {
      state.pushNotificationType = data.payload;
    },
  },
});

export const {
  setNotifications,
  setPagination,
  setUnread,
  setIsFetching,
  setNewPage,
  setPushNotification,
  setPushNotificationType,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const readNotifications = (readedNotifications) => async (dispatch) => {
  try {
    await notificationsAPI.readedNotifications(readedNotifications).then((response) => {
      const count = get(response, 'data.data.NoteCount.notiPage', 0);
      dispatch(setUnread(count));
    });
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};

export const getNotifications = (isFirst) => async (dispatch, getState) => {
  try {
    if (!getState().notifications.isFetching) {
      dispatch(setIsFetching(true));

      const {
        notifications: { pagination },
      } = getState();
      const page = isFirst || !pagination ? 1 : pagination?.current_page + 1;

      if (isFirst || !pagination || page <= pagination.total_pages) {
        const response = await notificationsAPI.getNotifications(page);

        const notifications = response.data.data.data;
        if (isFirst || !pagination) {
          dispatch(setNotifications(notifications));
        } else if (pagination.current_page < pagination.total_pages) {
          dispatch(setNewPage(notifications));
        }

        dispatch(getUnreadNotifications());
        dispatch(setIsFetching(false));
        dispatch(setPagination(response.data.data.pagination));
      }
      dispatch(setIsFetching(false));
    }
  } catch (err) {
    dispatch(setIsFetching(false));
    console.log(err);
    console.log(err.response);
  }
};

export const getUnreadNotifications = () => async (dispatch) => {
  try {
    const response = await notificationsAPI.getUnread();
    const count = get(response, 'data.data.NoteCount.notiPage', 0);
    dispatch(setUnread(count));
  } catch (err) {
    console.log(err);
    console.log(err.response);
  }
};
