import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
};
const getAuthHeadersMultipart = () => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
  };
};
const getOrgId = () => {
  const { profile } = store.getState();
  return profile.data.organisation.id;
};

export const clubcardFormsApi = {
  getClubcardForms: () =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/form`, getAuthHeaders()),
  createClubcardForm: (formId, params, type) => {
    return axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/form/${
        type === 'add' ? 'store' : `update/${formId}`
      }?organisation_id=${getOrgId()}`,
      params,
      getAuthHeadersMultipart()
    );
  },
  getEditClubcardFormInfo: (id) => {
    return axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/form/edit/${id}`,
      getAuthHeaders()
    );
  },
  deleteClubcardForm: (id) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/form/destroy/${id}`,
      getAuthHeaders()
    ),
  checkClubcardFormName: (name) =>
    axios.get(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/loyalty/form/checkNameExists`, {
      ...getAuthHeaders(),
      params: {
        name,
      },
    }),
};
