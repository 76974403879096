import axios from 'axios';
import store from '../redux/store';

const getAuthHeaders = (data) => {
  const { session } = store.getState();
  return {
    headers: {
      Authorization: `Bearer ${session.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    params: data,
  };
};

export const shiftsApi = {
  getСalendar: (data) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar`,
      getAuthHeaders(data)
    ),
  getSections: () =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar/sections`,
      getAuthHeaders()
    ),
  createShift: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar/store`,
      data,
      getAuthHeaders()
    ),
  edidShift: (id, data) =>
    axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar/${id}/update`,
      data,
      getAuthHeaders()
    ),
  deleteShift: (id) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar/${id}/destroy`,
      getAuthHeaders()
    ),
  getShiftInfo: (id) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar/${id}/edit`,
      getAuthHeaders()
    ),
  getShiftHistory: (id) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/personal/shift/calendar/${id}/history`,
      getAuthHeaders()
    ),
};
