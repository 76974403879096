// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingMenuView_menuViewHint__KGkyd {
    font-family: HelveticaNeueCyr;
    font-size: 14px;
    font-weight: 550;
    line-height: 14px;
    text-align: left;
    color: #94A3B8;
}
.SettingMenuView_btnBox__bgTrY {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}
.SettingMenuView_btnBox__bgTrY div {
   width: 100%;
}

.SettingMenuView_radioBox__knKlN {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/organisms/Modals/SettingMenuView/SettingMenuView.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;IACT,gBAAgB;AACpB;AACA;GACG,WAAW;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".menuViewHint {\n    font-family: HelveticaNeueCyr;\n    font-size: 14px;\n    font-weight: 550;\n    line-height: 14px;\n    text-align: left;\n    color: #94A3B8;\n}\n.btnBox {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 10px;\n    margin-top: 20px;\n}\n.btnBox div {\n   width: 100%;\n}\n\n.radioBox {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuViewHint": `SettingMenuView_menuViewHint__KGkyd`,
	"btnBox": `SettingMenuView_btnBox__bgTrY`,
	"radioBox": `SettingMenuView_radioBox__knKlN`
};
export default ___CSS_LOADER_EXPORT___;
