import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RefreshPurpleIcon from '../../../../../images/icons/refreshPurple';
import { setModal, setModalType } from '../../../../../redux/actions/modal';
import { getCaptcha, setCaptcha } from '../../../../../redux/actions/session';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import Input from '../../../atoms/Input';
import s from './CaptchaModal.module.css';

const CaptchaModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { captchaInfo } = useSelector((state) => state.session);
  const [captchaValue, setCaptchaValue] = useState('');

  const onSubmit = () => {
    dispatch(setCaptcha(captchaValue));
    dispatch(setModal(false));
    dispatch(setModalType(''));
  };

  useEffect(() => {
    dispatch(getCaptcha());
  }, []);
  return (
    <div>
      {(captchaInfo?.image && (
        <div className={s.captchaContainer}>
          <img
            className={`${s.captchaImg} ${s.noSelect}`}
            alt="captcha"
            src={`${process.env.REACT_APP_TENQ_API}${
              captchaInfo?.image
            }?${new Date().getMilliseconds()}`}
          />
          <div
            className={s.captchaRefresh}
            onClick={() => {
              dispatch(getCaptcha());
              setCaptchaValue('');
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            <RefreshPurpleIcon />
          </div>
        </div>
      )) ||
        null}
      <Input
        value={captchaValue}
        onChange={(e) => setCaptchaValue(e?.target?.value)}
        onClear={() => setCaptchaValue('')}
        clearIconTop="27%"
      />

      <div className={s.btns}>
        <Button title="Отправить" onClick={onSubmit} className={s.btn} />
        <ButtonCancel onClick={closeModal} className={s.btn} />
      </div>
    </div>
  );
};

export default React.memo(CaptchaModal);
