import React from 'react';
import s from './LoginHint.module.css';
import Button from '../../../atoms/Button';
import { actionKeyDown } from '../../../../../utils/actionKeyDown';

const LoginHint = ({ closeModal }) => {
  const onClose = () => {
    closeModal();
  };
  actionKeyDown(onClose);

  return (
    <div onClick={onClose}>
      <div className={s.messageBlock}>
        <p className={s.title}>Обнаружен аккаунт Сотрудника</p>
        <p className={s.text}>
          Пожалуйста, воспользуйтесь Личным кабинетом Сотрудника для входа в систему
        </p>
      </div>
      <Button title="Войти в другой аккаунт" className={s.button} />
    </div>
  );
};

export default React.memo(LoginHint);
