import axios from 'axios';
import { getAuthHeaders } from './auth';

export const managementAPI = {
  getUserInfo: (employeeId) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/${employeeId}`,
      getAuthHeaders()
    ),
  updateUserInfo: (employeeId, data) =>
    axios.post(`${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/${employeeId}`, data, {
      ...getAuthHeaders(),
      'Content-Type': 'multipart/form-data',
    }),
  fireUser: (employeeId) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/employee/${employeeId}/fire?app_type=desktop`,
      {},
      getAuthHeaders()
    ),
  getPositions: (available_custom_buttons) =>
    axios.get(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/position/get${
        available_custom_buttons ? '?available_custom_buttons=true' : ''
      }`,
      getAuthHeaders()
    ),
  createPositions: (data) =>
    axios.post(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/position/add`,
      data,
      getAuthHeaders()
    ),
  editPositions: (id, data) =>
    axios.put(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/position/edit/${id}`,
      data,
      getAuthHeaders()
    ),
  deletePositions: (id) =>
    axios.delete(
      `${process.env.REACT_APP_TENQ_API}/api/v0/owner/position/delete/${id}`,
      getAuthHeaders()
    ),
};
