import { createSlice } from '@reduxjs/toolkit';

export const preloaderSlice = createSlice({
  name: 'preloader',
  initialState: {
    isLoad: false,
  },
  reducers: {
    setIsLoad: (state, data) => {
      state.isLoad = data.payload;
    },
  },
});

export const { setIsLoad } = preloaderSlice.actions;

export default preloaderSlice.reducer;
