// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__K6x0o {
    background: #F2F5FA;
    width: 100%;
    color: #000;
    padding: 10px 35px 10px 14px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-weight: 550;
    font-size: 14px;
    line-height: 16px;
}
.Input_input__K6x0o.Input_white__wyHSi {
    background: #FFF;
}
.Input_input__K6x0o.Input_border__V3TnW {
    height: 40px;
    border: 2px solid #F2F5FA;
    background: #FFF;
    box-sizing: border-box;
}
.Input_input__K6x0o::placeholder {
    color: #94A3B8;
}
.Input_label__gjH0b {
    margin-bottom: 10px;
}
.Input_inputBox__BGE\\+\\+ {
    position: relative;
}
.Input_clear__TK35w{
    position: absolute;
    top: 41%;
    right: 20px;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/App/features/atoms/Input/Input.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,4BAA4B;IAC5B,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;IAChB,sBAAsB;AAC1B;AACA;IACI,cAAc;AAClB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,eAAe;AACnB","sourcesContent":[".input {\n    background: #F2F5FA;\n    width: 100%;\n    color: #000;\n    padding: 10px 35px 10px 14px;\n    border-radius: 10px;\n    border: none;\n    outline: none;\n    font-weight: 550;\n    font-size: 14px;\n    line-height: 16px;\n}\n.input.white {\n    background: #FFF;\n}\n.input.border {\n    height: 40px;\n    border: 2px solid #F2F5FA;\n    background: #FFF;\n    box-sizing: border-box;\n}\n.input::placeholder {\n    color: #94A3B8;\n}\n.label {\n    margin-bottom: 10px;\n}\n.inputBox {\n    position: relative;\n}\n.clear{\n    position: absolute;\n    top: 41%;\n    right: 20px;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__K6x0o`,
	"white": `Input_white__wyHSi`,
	"border": `Input_border__V3TnW`,
	"label": `Input_label__gjH0b`,
	"inputBox": `Input_inputBox__BGE++`,
	"clear": `Input_clear__TK35w`
};
export default ___CSS_LOADER_EXPORT___;
