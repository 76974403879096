import { createSelector } from 'reselect';

const tablesSelector = (state) => state.tables;

export const getTableList = createSelector(tablesSelector, (state) => state.tables);
export const getSections = createSelector(tablesSelector, (state) => state.sections);
export const getCurrentSelectedTable = createSelector(
  tablesSelector,
  (state) => state.currentSelectedTable
);
export const getListLoading = createSelector(tablesSelector, (state) => state.listLoading);
export const getSameNaming = createSelector(tablesSelector, (state) => state.sameNameing);
