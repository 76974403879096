import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert } from '../../../../../redux/actions/alerts';
import { addSumClientClubcardGroup } from '../../../../../redux/actions/clubcard';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import Input from '../../../atoms/Input';
import s from './AddSumClientGroup.module.css';

const AddSumClientGroup = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { selectedClientId } = useSelector((state) => state.clubcard);
  const [sum, setSum] = useState('');
  const onSave = () => {
    if (sum) {
      const sumWithoutSpaces = Number(sum.replace(/\s/g, ''));
      dispatch(
        addSumClientClubcardGroup({
          loyal_user_card_id: selectedClientId,
          amount: sumWithoutSpaces * 100,
        })
      );
    } else {
      dispatch(addAlert('Введите сумму'));
    }
  };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    // Удаляем все символы, кроме цифр и точки
    inputValue = inputValue.replace(/[^\d.]/g, '');
    // Удаляем ведущие нули перед точкой или цифрами
    inputValue = inputValue.replace(/^0+(?=\d)|^0+(?=\.)/, '');
    // Делим число на триады и разделяем их пробелами
    const parts = inputValue.split('.');
    // Проверяем и обрезаем количество цифр после точки до двух
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }
    // Добавляем пробелы обратно перед точкой (если они были)
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    // Собираем число обратно с точкой и пробелами
    inputValue = parts.join('.');
    // Удаляем пробелы перед точкой (если есть)
    inputValue = inputValue.replace(/(\.\s*)/g, '.');
    setSum(inputValue);
  };

  return (
    <div>
      <p className={s.description}>
        Вы можете добавить сумму заказа, которую клиент оплатил наличными средствами
      </p>
      <Input
        isBorder
        placeholder="0"
        className={s.input}
        value={sum}
        onClear={() => setSum('')}
        onChange={handleInputChange}
        clearIconTop={27}
      />
      <div className={s.btns}>
        <Button title="Сохранить" className={s.btn} onClick={onSave} />
        <ButtonCancel onClick={closeModal} className={s.btn} />
      </div>
    </div>
  );
};

export default React.memo(AddSumClientGroup);
