import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ManagementFilter.module.css';
import Button from '../../atoms/Button';
import ButtonCancel from '../../atoms/ButtonCancel';
import FilterPositions from '../../atoms/FilterPositions';

const ManagementFilter = ({ onCancel, onSave, defaultPositionsFilterData }) => {
  const [showPositions, setShowPositions] = useState(true);
  const [positions, setPositions] = useState(defaultPositionsFilterData);
  const checkedPositions = useMemo(
    () =>
      positions
        .filter((item) => item.checked && item.position !== 'Все' && !positions[0].checked)
        .slice(0, 3)
        .map((item, i) => {
          if (i === 0) return item.position;
          if (i === 1) return `, ${item.position}`;
          if (i === 2) return ` + 1`;
        }),
    [positions]
  );

  const handlePositionChange = ({ position, checked }) => {
    let newPosition;

    if (position === 'Все') {
      newPosition = positions.map((item) => ({
        ...item,
        checked: !checked,
      }));
    } else {
      newPosition = positions.map((item) => {
        if (item.position === 'Все') {
          return {
            ...item,
            checked: false,
          };
        }
        if (item.position === position) {
          return {
            ...item,
            checked: !checked,
          };
        }
        return { ...item };
      });
    }
    if (newPosition.filter((item) => item.checked).length === newPosition.length - 1) {
      newPosition[0].checked = true;
    }
    setPositions(newPosition);
  };

  const clearPositions = (e) => {
    e.stopPropagation();
    handlePositionChange({ position: 'Все', checked: false });
  };

  const onShowPositions = () => {
    setShowPositions((v) => !v);
    if (!positions.filter((item) => item.checked).length) {
      handlePositionChange({ position: 'Все', checked: false });
    }
  };

  return (
    <div className={s.wraper}>
      <div className={s.header}>
        <div>Фильтры</div>
      </div>
      <div className={s.label}>Выберите позиции</div>
      <FilterPositions
        onShowPositions={onShowPositions}
        showPositions={showPositions}
        checkedPositions={checkedPositions}
        clearPositions={clearPositions}
        positions={positions}
        setPositions={setPositions}
        handlePositionChange={handlePositionChange}
      />
      <div className={s.container}>
        <div className={s.btns}>
          <Button title="Сохранить" onClick={() => onSave(positions)} />
          <ButtonCancel onClick={onCancel} />
        </div>
      </div>
    </div>
  );
};

ManagementFilter.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  defaultDateFilterData: PropTypes.any,
  defaultPositionsFilterData: PropTypes.any,
};

export default React.memo(ManagementFilter);
