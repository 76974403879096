import React from 'react';

const ArrowDown = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 9L10 14L5 9"
        stroke={color || '#94A3B8'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />{' '}
    </svg>
  );
};

export default React.memo(ArrowDown);
