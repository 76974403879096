import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import style from './BookingFilters.module.css';
import stl from '../TableBookingEditor/TableBookingEditor.module.css';
import Button from '../../../../atoms/Button/Button';
import SvgFilterIcon from '../../../../../../assets/Icons/SvgFilterIcon';
import CheckBox from '../../../../atoms/CheckBox';
import { setBookedWorkPlace, setFilteredSections } from '../../../../../../redux/actions/booking';

const BookingFilters = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { bookedWorkPlace } = useSelector((state) => state.booking);
  const [bookingTableSections, setBookingTableSections] = useState(bookedWorkPlace);
  const [allSections, setAllSections] = useState(true);

  const onClose = () => {
    closeModal(null, true);
  };

  const onClickSection = (id) => {
    const newArr = bookingTableSections.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: !item?.checked,
        };
      }
      return item;
    });
    setAllSections(newArr.every((item) => item.checked));
    setBookingTableSections(newArr);
  };
  const checkedAllTrue = useMemo(() => {
    return bookingTableSections.map((item) => {
      return {
        ...item,
        checked: true,
      };
    });
  }, [bookingTableSections]);
  const checkedAllFalse = useMemo(() => {
    return bookingTableSections.map((item) => {
      return {
        ...item,
        checked: false,
      };
    });
  }, [bookingTableSections]);
  const onClickAllSection = useCallback(() => {
    setAllSections((prevState) => {
      if (!prevState) {
        setBookingTableSections(checkedAllTrue);
      } else {
        setBookingTableSections(checkedAllFalse);
      }
      return !prevState;
    });
  }, [allSections]);

  const onSaveFilters = () => {
    const filteredSec = bookingTableSections.filter((item) => item.checked);

    dispatch(
      setBookedWorkPlace(
        bookingTableSections.every((el) => !el.checked) ? checkedAllTrue : bookingTableSections
      )
    );
    dispatch(
      setFilteredSections(filteredSec.length < bookingTableSections.length ? filteredSec : [])
    );
    onClose();
  };

  useEffect(() => {
    setAllSections(bookedWorkPlace.every((item) => item.checked));
  }, [bookedWorkPlace]);

  return (
    <div>
      <div className={style.bookingFilersHead}>
        <span>Фильтры</span>
        <SvgFilterIcon />
      </div>
      <div className={style.filterSection}>
        <p className={style.filterTitle}>Секции</p>
        <div className={style.filterSectionsBox}>
          <CheckBox title="Все" value={allSections} onChange={onClickAllSection} />
          {bookingTableSections.map((section) => {
            if (section?.desks.length) {
              return (
                <CheckBox
                  title={section?.name}
                  value={section?.checked}
                  onChange={() => onClickSection(section?.id)}
                  key={section?.id}
                />
              );
            }
          })}
        </div>
      </div>
      <div className={style.filterBtnBoxHor}>
        <Button onClick={onSaveFilters} title="Сохранить" className={stl.bookingBtn} />
        <Button onClick={onClose} title="Отмена" className={stl.bookingEditBtn} />
      </div>
    </div>
  );
};

BookingFilters.propTypes = {
  closeModal: PropTypes.func,
};

export default React.memo(BookingFilters);
