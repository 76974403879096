// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Preloader_popup__Dxv7i {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(37, 32, 61, 0.4);
    text-align: center;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1099;
}

.Preloader_loader__4t9UN {
    width: 320px;
    height: 320px;
  }
`, "",{"version":3,"sources":["webpack://./src/App/components/Preloader/Preloader.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;EACf","sourcesContent":[".popup {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(37, 32, 61, 0.4);\n    text-align: center;\n    overflow: auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1099;\n}\n\n.loader {\n    width: 320px;\n    height: 320px;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `Preloader_popup__Dxv7i`,
	"loader": `Preloader_loader__4t9UN`
};
export default ___CSS_LOADER_EXPORT___;
