import React from 'react';
import PropTypes from 'prop-types';
import s from './FilterPositions.module.css';
import CheckBox from '../CheckBox';

const FilterPositions = ({
  onShowPositions,
  showPositions,
  checkedPositions,
  positions,
  handlePositionChange,
}) => {
  return (
    <div className={s.positionsWrapper}>
      <div className={s.positionsTitle} onClick={onShowPositions}>
        <div>
          {showPositions ? (
            ''
          ) : (
            <div className={s.selectedPositionsTitle}>
              {checkedPositions.length ? checkedPositions : 'Все позиции'}
            </div>
          )}
        </div>
      </div>
      {showPositions && (
        <>
          <div className={s.positions}>
            {positions.map((item, i) => (
              <CheckBox
                title={item.position}
                value={item.checked}
                onChange={() => handlePositionChange(item)}
                key={i}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

FilterPositions.propTypes = {
  showPositions: PropTypes.bool.isRequired,
  checkedPositions: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  onShowPositions: PropTypes.func,
  clearPositions: PropTypes.func,
};

export default React.memo(FilterPositions);
