import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import style from './orderDetail.module.css';
import { SvgLoyaltyIcon } from '../../../../../assets/Icons/SvgLoyaltyIcon';
import OrderDetailDishes from '../../../molecules/OrderDetailDishes/OrderDetailDishes';
import { useOrderDetail } from './useOrderDetail';
import OrderDetailActionBox from '../../../molecules/OrderDetailActionBox/OrderDetailActionBox';
import CloseIcon from '../../../../../assets/Icons/close';
import CircleProgressBar from '../../../atoms/CircleProgressBar/CircleProgressBar';
import Button from '../../../atoms/Button';
import ButtonCancel from '../../../atoms/ButtonCancel';
import CameraScanner from '../../../molecules/CameraScanner/CameraScanner';

const OrderDetail = () => {
  const { settings } = useSelector((state) => state.organisation.data.info);

  const {
    orderStatusBackColor,
    isAllowTimeLabel,
    isEnabledDeleteDish,
    onclickCancel,
    onCancelOrder,
    onCloseTakeawayOrder,
    onPaidInCashOrder,
    onOrderHandler,
    onOrderAdditionHandler,
    orderDetail,
    paidProgress,
    partPay,
    setPartPay,
    handleClick,
    activeIndex,
    onPartPaidInCashOrder,
    partPaidSum,
    scanType,
    setScanType,
    onScanQr,
    isMobile,
  } = useOrderDetail();

  return scanType ? (
    <CameraScanner scanType={scanType} setScanType={setScanType} onScanQr={onScanQr} />
  ) : (
    <div className={style.orderDetailModal}>
      <div className={style.orderDetails}>
        <span className={style.close} onClick={onclickCancel}>
          <CloseIcon width={14} height={14} />
        </span>
      </div>

      <div className={style.orderDetailBoxHeader}>
        <div className={style.orderDetailBoxTitle}>
          <span className={style.orderDetailBoxText}>Заказ #{orderDetail.number}</span>
          <span className={style.orderDetailTime}>
            {settings?.org_type === 1 ? 'Стол' : 'Номер'} #{orderDetail.desk_num}
          </span>
        </div>

        <div className={style.orderDetailLabels}>
          {orderDetail.is_takeaway_order && (
            <div className={`${style.orderDetailStatus} ${style.orderDetailStatusTakeaway}`}>
              <div className={style.orderDetailLabelText}>Навынос</div>
            </div>
          )}

          <div className={style.orderDetailStatus} style={orderStatusBackColor}>
            <div className={style.orderDetailLabelText}>{orderDetail?.status}</div>
          </div>
          {orderDetail?.payment_type !== 'single' && (
            <div style={{ display: 'flex' }}>
              <div
                className={style.orderDetailStatus}
                style={{ backgroundColor: 'rgba(134, 114, 232, 0.5)', marginRight: 3 }}
              >
                <div className={style.orderDetailLabelText}>
                  {orderDetail?.payment_type === 'single' ? orderDetail?.status : 'Деление счета'}
                </div>
              </div>
              {paidProgress < 100 && <CircleProgressBar progress={paidProgress} />}
            </div>
          )}

          {(orderDetail.discount_percent && (
            <div className={style.orderDetailDiscount}>
              <div className={style.orderDetailLabelText}>
                Скидка {orderDetail.discount_percent}%
              </div>
              <span className={style.discountIcon}>
                <SvgLoyaltyIcon color="#1e293b" />
              </span>
            </div>
          )) ||
            null}
        </div>
        {isAllowTimeLabel && (
          <div className={`${style.orderDetailStatus} ${style.orderDetailTimeLabel}`}>
            <div className={style.orderDetailLabelText}>
              Приготовить к {orderDetail?.complete_order_time}
            </div>
          </div>
        )}
      </div>

      {(orderDetail?.status === 'Принято' && orderDetail?.payment_type === 'single' && isMobile && (
        <Button
          className={style.applyDiscountBtn}
          title="Применить скидку"
          onClick={() => setScanType(!scanType ? 'qr' : '')}
          icon={<SvgLoyaltyIcon color="#FFFFFF" />}
        />
      )) ||
        null}

      <OrderDetailDishes
        scanType={scanType}
        setScanType={setScanType}
        orderArray={orderDetail?.order?.basic_dishes || []}
        orderDetail={orderDetail}
        isEnabledDeleteDish={isEnabledDeleteDish}
      />

      {(Array.isArray(orderDetail?.order?.new_dishes) && orderDetail?.order?.new_dishes?.length && (
        <OrderDetailDishes
          scanType={scanType}
          setScanType={setScanType}
          orderArray={orderDetail?.order?.new_dishes || []}
          orderDetail={orderDetail}
          isAdditionalOrder
          isEnabledDeleteDish={isEnabledDeleteDish}
        />
      )) ||
        null}
      {!orderDetail?.is_order_can_pay &&
        (orderDetail?.status === 'Принято' || orderDetail?.status === 'Принято и оплачено') && (
          <div className={style.hintBox}>
            <p className={style.scanHint}>
              Требуется сканирование штрих-кода выделенных позиций заказа на кассе
            </p>
          </div>
        )}
      <OrderDetailActionBox
        orderDetail={orderDetail}
        onOrderHandler={onOrderHandler}
        onOrderAdditionHandler={onOrderAdditionHandler}
        onCancelOrder={onCancelOrder}
        onPaidInCashOrder={onPaidInCashOrder}
        onCloseTakeawayOrder={onCloseTakeawayOrder}
        partPay={partPay}
      />
      {orderDetail?.payment_type === 'equal_parts' && partPay && (
        <div className={style.partPayContainer}>
          <p className={style.partPayHint}>
            Укажите количество частей заказа, которые оплачиваются наличными средствами{' '}
          </p>
          <div className={style.partPayBox}>
            {Array.from({ length: orderDetail?.parts_total }).map((_, index) => (
              <div
                key={index}
                className={`${style.partPay} ${
                  index < orderDetail?.parts_paid ? style.partPayHalfActive : ''
                }
                             ${
                               index >= orderDetail?.parts_paid && index <= activeIndex
                                 ? style.partPayActive
                                 : ''
                             }`}
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
          <p className={style.partPayPrice}>
            Оплата: {partPaidSum} {(!!partPaidSum && 'руб.') || ''}
          </p>
          <div className={style.orderDetailBtnBox}>
            <Button isDisabled={!activeIndex} title="Сохранить" onClick={onPartPaidInCashOrder} />
            <ButtonCancel title="Отмена" onClick={() => setPartPay(false)} />
          </div>
        </div>
      )}
      {orderDetail?.payment_type === 'dishes' && partPay && (
        <div className={style.partPayContainer}>
          <p className={style.partPayHint}>
            Наличная оплата при делении заказа по блюдам невозможна{' '}
          </p>
          <div className={style.orderDetailBtnBox}>
            <Button title="OK" onClick={() => setPartPay(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(OrderDetail);
