import * as React from 'react';

const RefreshPurpleIcon = (props) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 .8a7.146 7.146 0 0 0-4.933 1.974L2.043 1.749a.4.4 0 0 0-.68.225L.84 5.601a.4.4 0 0 0 .447.453l3.674-.477a.4.4 0 0 0 .231-.68l-1.02-1.02A5.596 5.596 0 0 1 8 2.368 5.64 5.64 0 0 1 13.633 8 5.64 5.64 0 0 1 8 13.633a5.632 5.632 0 0 1-4.91-2.883.406.406 0 0 0-.563-.15l-.658.4a.396.396 0 0 0-.14.532A7.193 7.193 0 0 0 8 15.2 7.2 7.2 0 0 0 8 .8Z"
      fill="#8672E8"
      stroke="#8672E8"
      strokeWidth={0.4}
    />
  </svg>
);

export default React.memo(RefreshPurpleIcon);
